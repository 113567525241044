export const Week = {
  sat: 0,
  fri: 1,
  thu: 2,
  wed: 3,
  tue: 4,
  mon: 5,
  sun: 6,
} as const;

export type WeekKeys = keyof typeof Week;
export type WeekValues = typeof Week[WeekKeys];

export const convertDayOff = (value) => {
  // @ts-ignore
  const base2Value = value.toString(2);
  const len = `${base2Value}`.length;
  return new Array(7 - len).fill(0).join('') + base2Value;
};
