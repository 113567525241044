import { makeCreateAction } from '../../helpers/actions';
import {
  SettingsRecommendations,
  SettingsRecommendationsReducerState,
  PermissionStatus,
} from '../interfaces';

const createAction = makeCreateAction('SETTINGS_RECOMMENDATIONS');
export const getRecommendations = createAction('GET_RECOMMENDATIONS');

export const setModalsMap = createAction(
  'TOGGLE_POPUPS',
  ({
    data,
    setToInitial = false,
  }: {
    data: Partial<SettingsRecommendations>;
    setToInitial: boolean;
  }) => ({
    payload: {
      data,
      setToInitial,
    },
  })
);

export const setPermissionStatus = createAction(
  'SET_PERMISSION_STATUS',
  ({
    permission,
    status,
  }: {
    permission: keyof SettingsRecommendationsReducerState['permissions'];
    status: PermissionStatus;
  }) => ({
    payload: {
      permission,
      status,
    },
  })
);

export const setInitialState = createAction('SET_INITIAL_STATE');
export const actionCreators = {
  getRecommendations,
  setModalsMap,
  setInitialState,
  setPermissionStatus,
} as const;
