import React, { Suspense } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Route, Routes, Navigate } from 'react-router-dom';

import urls from '../../config/urls';
import { AppRouteProps, driverRoutes, guestRoutes } from '../../routes';
import Loader from '../../common/Loader';
import { AppState } from '../../store/interfaces';
import UpdatePage from '../../core/pages/update';
import BatteryPermission from '../../core/pages/permissions/Battery';

import NoneLayout from './NoneLayout';
import DriverLayout from './DriverLayout';

const DriverRoutes = driverRoutes.map((route: AppRouteProps) => {
  const { component: Component, path } = route;

  const element = (
    <Suspense fallback={<Loader />}>
      <Component />
    </Suspense>
  );

  return <Route key={`driver-driver-${path}`} path={path} element={element} />;
});

const GuestRoutes = guestRoutes.map((route: AppRouteProps) => {
  const { component: Component, path } = route;
  const element = (
    <Suspense fallback={<Loader />}>
      <Component />
    </Suspense>
  );

  return <Route key={`driver-guest-${path}`} path={path} element={element} />;
});

export const Layout: React.FC = function () {
  const user = useSelector((state: AppState) => state.auth.user, shallowEqual);
  const LayoutComponent = user ? DriverLayout : NoneLayout;

  return (
    <LayoutComponent>
      <UpdatePage />
      <BatteryPermission />
      <Routes>
        {user ? DriverRoutes : GuestRoutes}
        <Route path="*" element={<Navigate to={user ? urls.rides : urls.login} />} />
      </Routes>
    </LayoutComponent>
  );
};

export default Layout;
