import { createReducer } from '@reduxjs/toolkit';

import { actionCreators } from 'store/creditsHistory/actions';
import { CreditsHistoryReducerState } from 'store/interfaces';

const initialState: Readonly<CreditsHistoryReducerState> = {
  isLoading: false,
  credits: [],
  isFailedToGet: false,
};

const creditsHistoryReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(actionCreators.getCreditsHistory.REQUEST, (state) => {
      state.isLoading = true;
    })

    .addCase(actionCreators.getCreditsHistory.SUCCESS, (state, action) => {
      const { credits } = action.payload;

      state.isLoading = false;
      state.isFailedToGet = false;
      state.credits = credits;
    })

    .addCase(actionCreators.getCreditsHistory.FAILURE, (state) => {
      state.isLoading = false;
      state.isFailedToGet = true;
      state.credits = [];
    })

    .addCase(actionCreators.exitCreditsHistory, () => ({ ...initialState }))
);

export default creditsHistoryReducer;
