import styled from 'styled-components';

import { vh100 } from '../../../config/css';

export const LayoutWrapper = styled.div`
  @media (max-height: 568px) {
    div {
      background: ${({ theme }) => theme.background};
    }
  }
`;

export const MainContainer = styled.div`
  height: calc(${vh100});
  position: relative;
`;

export const MainText = styled.div`
  text-align: center;
  margin-top: 25px;
  margin-bottom: 25px;
  line-height: 22px;
  font-size: 20px;
`;

export const Title = styled.div`
  font-weight: bold;
  font-size: 30px;
  line-height: 32px;
  text-align: center;
  margin: 25px 0;
`;

export const AppLinks = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 45px;

  img {
    max-height: 45px;
  }

  button {
    font-size: 20px !important;
    margin-bottom: 20px;
  }
`;
