import { createGlobalStyle, css } from 'styled-components';
import { normalize } from 'styled-normalize';

import { vh100 } from '../../config/css';

export const GlobalStyle = createGlobalStyle`
  ${normalize}
  
  html, body {
    height:100%;
    font-family: Roboto,serif;
    color: ${({ theme }: { theme: any }) => theme.textColor};
    
    transition: color 0.5s ease, background-color 0.5s ease, background 0.5s ease;
  }
  
  #root {
    height: calc(${vh100});
    background-color: ${({ theme }: { theme: any }) => theme.bodyBackgroundAlt};
  }
  
  a {
    text-decoration: none;
  }
  
  .leaflet-popup-content-wrapper, .leaflet-popup-tip {
    color: ${(props) => props.theme.map.pin.color};
    background: ${(props) => props.theme.map.pin.background};
  }
  
  ${(props) => {
    if (props.theme.name === 'dark') {
      return css`
        .leaflet-pane .leaflet-tile-pane {
          filter: brightness(0.6) invert(1) contrast(3) hue-rotate(200deg) saturate(0.3)
            brightness(0.7);
        }
      `;
    }
  }}
`;
