import React, { useCallback, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

import { useTranslation } from 'helpers/hooks';
import { LoadingProp } from 'core/settings/styled';
import { AppState } from 'store/interfaces';
import { toggleSidebar } from 'store/app/acions';
import urls, { fbLink, guidanceLink, helpVideoLink, faqLink } from 'config/urls';
import { Link } from 'common/Link';
import { eventsService } from 'services/EventsService';
import {
  registerShowInAppReviewEvent,
  registerSidebarContactSupportClickEvent,
  registerSidebarFaqClickEvent,
  registerSidebarFBClickEvent,
  registerSidebarGeneralGuidanceClickEvent,
  registerSidebarStepByStepClickEvent,
  registerSidebarTutorialClickEvent,
} from 'services/reactGa';

import OnBoarding from '../../../../core/pages/onboarding';
import { EnvParam, envService } from '../../../../services/EnvService';

import { Logout } from './Logout';
import * as S from './styled';

const animationTime = '0.3s';

interface MenuItemProps {
  href?: string;
  onClick(e: React.MouseEvent<HTMLElement>): void;
  title: string;
  icon: string;
}

const Sidebar: React.FC = function () {
  const { t } = useTranslation();
  const [isHelpSlides, setIsHelpSlides] = useState<boolean>(false);
  const { showSidebar, user, rideCredits } = useSelector(
    (app: AppState) => ({
      showSidebar: app.app.showSidebar,
      user: app.auth.user,
      rideCredits: app.app.rideCredits,
      t,
    }),
    shallowEqual
  );

  const [isClosing, setIsClosing] = useState<boolean>(false);
  const dispatch = useDispatch();

  const toggleHelpSlides = () => setIsHelpSlides((state) => !state);

  // eslint-disable-next-line consistent-return
  const closeSidebar = useCallback(() => {
    if (isClosing) {
      return null;
    }

    setIsClosing(true);

    setTimeout(() => {
      setIsClosing(false);
      dispatch(toggleSidebar());
    }, parseFloat(animationTime) * 1000);
  }, []);

  const showInAppReview = (e) => {
    e.preventDefault();
    closeSidebar();
    registerShowInAppReviewEvent();
    eventsService.showInAppReviewModal(true);
  };

  const menuItems: MenuItemProps[] = useMemo(() => {
    const items: MenuItemProps[] = [
      {
        title: t('sidebar.generalGuidance'),
        icon: '/assets/sidebar/guidance.svg',
        onClick: () => {
          registerSidebarGeneralGuidanceClickEvent();
          closeSidebar();
        },
        href: guidanceLink,
      },
      {
        title: t('sidebar.tutorial'),
        icon: '/assets/sidebar/tutorial.svg',
        onClick: () => {
          registerSidebarTutorialClickEvent();
          closeSidebar();
        },
        href: helpVideoLink,
      },
      {
        title: t('sidebar.stepByStepGuide'),
        icon: '/assets/sidebar/step-by-step.svg',
        onClick: () => {
          registerSidebarStepByStepClickEvent();
          toggleHelpSlides();
          closeSidebar();
        },
      },
      {
        title: t('sidebar.faq'),
        icon: '/assets/sidebar/faq.svg',
        href: faqLink,
        onClick: () => {
          registerSidebarFaqClickEvent();
          closeSidebar();
        },
      },
      {
        title: t('sidebar.fb'),
        icon: '/assets/sidebar/facebook.svg',
        onClick: () => {
          registerSidebarFBClickEvent();
          closeSidebar();
        },
        href: fbLink,
      },
      {
        title: t('sidebar.contactSupport'),
        icon: '/assets/sidebar/support.svg',
        onClick: () => {
          eventsService.showSupportModal();
          registerSidebarContactSupportClickEvent();
          closeSidebar();
        },
      },
    ];

    return items;
  }, []);

  if (isHelpSlides) {
    return <OnBoarding skipAtAnyTime onDoneClick={toggleHelpSlides} />;
  }

  if (!showSidebar) {
    return null;
  }

  return (
    <S.Container>
      <S.Mask animationTime={animationTime} showSidebar={!isClosing} onClick={closeSidebar} />
      <S.Body animationTime={animationTime} showSidebar={!isClosing}>
        <S.Menu>
          <S.UserPhotoWrap>
            <S.UserPhoto photo={user?.user_photo} />
            <S.UserId>
              {t('common.skeddyId')}: <b>{user?.user_id}</b>
            </S.UserId>
            <S.RideCredits>
              {t('sidebar.availableCredits')}:{' '}
              <span>
                {rideCredits === null || Number.isNaN(rideCredits) ? (
                  <LoadingProp />
                ) : (
                  <b>{rideCredits}</b>
                )}
              </span>
            </S.RideCredits>
          </S.UserPhotoWrap>
          <S.MainItem>
            <NavLink onClick={closeSidebar} to={urls.credits}>
              <ReactSVG wrapper="span" src="/assets/navigation/icon_credits_1.svg" />{' '}
              {t('sidebar.credits')}
            </NavLink>
          </S.MainItem>
          {envService.getParam(EnvParam.SHOW_SIDEBAR_HELP_LINKS, { asNumber: true }) && (
            <>
              <S.MenuItem>
                <S.MenuItemsHeader>{t('sidebar.getHelp')}:</S.MenuItemsHeader>
              </S.MenuItem>

              {menuItems.map((menuItem) => (
                <S.MenuItem key={menuItem.title}>
                  <Link onClick={menuItem.onClick} href={menuItem.href}>
                    <ReactSVG wrapper="span" src={menuItem.icon} />
                    <span>{menuItem.title}</span>
                  </Link>
                </S.MenuItem>
              ))}
            </>
          )}

          {envService.getParam(EnvParam.SHOW_INAPP_REVIEW, { asNumber: true }) && (
            <S.RateItem onClick={showInAppReview}>
              <Link>
                <div>
                  <span>
                    <ReactSVG wrapper="span" src="/assets/sidebar/rate.svg" />
                  </span>
                  <span>
                    {t('sidebar.rate1')}
                    <br />
                    {t('sidebar.rate2')}
                  </span>
                </div>
              </Link>
            </S.RateItem>
          )}
          <S.MenuItem
            style={{
              marginBottom: 10,
              marginTop: envService.getParam(EnvParam.SHOW_INAPP_REVIEW, { asNumber: true })
                ? 0
                : 'auto',
            }}
          >
            <Logout />
          </S.MenuItem>
        </S.Menu>
      </S.Body>
    </S.Container>
  );
};

export default React.memo(Sidebar);
