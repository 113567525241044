import React, { ReactNode } from 'react';

import { PickerColumn } from './PickerColumn';
import { PickerProps } from './interfaces';
import './style.css';

class Picker extends React.PureComponent<PickerProps> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    itemHeight: 36,
    height: 216,
  };

  renderInner() {
    const { optionGroups, valueGroups, itemHeight, height, onChange } = this.props;
    const highlightStyle = {
      height: itemHeight,
      marginTop: -(itemHeight / 2),
    };
    const columnNodes: Array<ReactNode> = [];
    for (const name in optionGroups) {
      columnNodes.push(
        <PickerColumn
          key={name}
          name={name}
          options={optionGroups[name]}
          value={valueGroups[name]}
          itemHeight={itemHeight}
          columnHeight={height}
          onChange={onChange}
        />
      );
    }
    return (
      <div className="picker-inner">
        {columnNodes}
        <div className="picker-highlight" style={highlightStyle} />
      </div>
    );
  }

  render() {
    const style = {
      height: this.props.height,
    };

    return (
      <div className="picker-container" style={style}>
        {this.renderInner()}
      </div>
    );
  }
}

export default Picker;
