import i18n from '../../../i18n';

interface Hours {
  [hour: string]: number;
}

const getSecondsInHour = (hour: number): number => hour * 3600;

const hoursMap: Hours = {};
const secondsHoursMap = {};

for (let hours = 1; hours <= 24; hours++) {
  const seconds = getSecondsInHour(hours);
  const index =
    hours === 1
      ? i18n.t('settings.filters.hour')
      : i18n.t('settings.filters.hours', { time: hours });

  hoursMap[index] = seconds;
  secondsHoursMap[seconds] = index;
}

export const hours = hoursMap;

export const getSelectedByValue = (value) => {
  if (!value) {
    return null;
  }

  return secondsHoursMap[value] || null;
};
