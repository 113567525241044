import { useDispatch } from 'react-redux';

import { SettingsRecommendations } from '../../store/interfaces';
import { setModalsMap } from '../../store/settingsRecommendations/acions';

export const useToggleModalMap = () => {
  const dispatch = useDispatch();
  return (data: Partial<SettingsRecommendations>) => {
    dispatch(setModalsMap({ data }));
  };
};
