import { createDebug } from './debug';

const debug = createDebug.extend('timer');

const toHHMMSS = (secs) => {
  const sec_num = parseInt(secs, 10);
  const hours = Math.floor(sec_num / 3600);
  const minutes = Math.floor(sec_num / 60) % 60;
  const seconds = sec_num % 60;

  return [hours, minutes, seconds]
    .map((v) => (v < 10 ? `0${v}` : v))
    .filter((v, i) => i >= 0)
    .join(':');
};

type Props = {
  key: string;
  inTime: number;
};

export default class Timer {
  static now = () => Math.floor(Date.now() / 1000);

  static isTimeLeft({ key, inTime }: Props) {
    const lastTime = localStorage.getItem(key);

    if (!lastTime) {
      Timer.setTime(key);
      debug(key, 'isTimeLeft =', false);
      return false;
    }

    const isTimeLeft = Timer.now() > +lastTime + inTime;

    debug(key, 'isTimeLeft =', isTimeLeft);

    return isTimeLeft;
  }

  static isAllowed({ key, inTime }: Props) {
    const lastTime = localStorage.getItem(key);

    if (!lastTime) {
      debug(key, 'isAllowed = true');
      Timer.setTime(key);
      return true;
    }

    const isAllowed = Timer.now() > +lastTime + inTime;
    debug(
      key,
      `isAllowed = ${isAllowed}${
        // eslint-disable-next-line sonarjs/no-nested-template-literals
        isAllowed ? '' : `, will be allowed in ${toHHMMSS(+lastTime + inTime - Timer.now())}`
      }`
    );

    return isAllowed;
  }

  static updateIfAllowed({ key, inTime }: Props) {
    const isEnabled = Timer.isAllowed({ key, inTime });

    if (isEnabled) {
      Timer.setTime(key);
    }

    return isEnabled;
  }

  static clear(key: string) {
    debug(key, 'clear');
    localStorage.removeItem(key);
  }

  static getTimeAllowed({ key, inTime }) {
    const lastTime = localStorage.getItem(key);

    if (!lastTime || Timer.now() > +lastTime + inTime) {
      return `${key} is allowed`;
    }

    return `${key} will be allowed in ${toHHMMSS(+lastTime + inTime - Timer.now())}`;
  }

  private static setTime(key: string) {
    const hasKey = localStorage.getItem(key);
    debug(key, `${hasKey === null ? 'Created and set' : 'Updated'} time to now()`);
    localStorage.setItem(key, `${Timer.now()}`);
  }
}
