import { makeCreateAction, makeCreateRoutine } from '../../helpers/actions';

const createAction = makeCreateAction('SETTINGS');
const createRoutine = makeCreateRoutine('SETTINGS');

export const getSettings = createRoutine('GET_SETTINGS', {
  request: ({ isSilent = false } = {}) => ({ isSilent }),
  success: (settings) => ({ ...settings }),
  failure: (errors) => ({ ...errors }),
});

export const setSettings = createRoutine('SET_SETTINGS', {
  request: ({ value, property, startSearch = false }) => ({ value, property, startSearch }),
  success: ({ settings }) => ({ settings }),
  failure: ({ errors }) => ({ errors }),
});

export const exitSettings = createAction('EXIT_SETTINGS');

export const actionCreators = {
  getSettings,
  setSettings,
  exitSettings,
} as const;
