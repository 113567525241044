import { useTranslation as useTranslationBase } from 'react-i18next';
import { useCallback } from 'react';

import { EnvParam, envService } from '../services/EnvService';

type Params = {
  appDep?: boolean;
} & {
  [key: string]: string | boolean;
};

export const useTranslation = () => {
  const { t, i18n } = useTranslationBase();

  const tWithApp = useCallback(function (message, params: Params = { appDep: false }) {
    const { appDep, ...rest } = params;
    return t(
      `${message}${appDep ? 'IOS' : ''}`,
      Object.assign(rest, { appName: envService.getParam(EnvParam.APP_NAME) })
    );
  }, []);

  return { t: tWithApp, i18n };
};
