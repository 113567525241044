import React from 'react';

import { vh100 } from 'config/css';

interface NoneLayoutLayoutProps {
  children: any;
  style?: object;
}

const NoneLayout: React.FC<NoneLayoutLayoutProps> = function ({
  children,
  style,
}: NoneLayoutLayoutProps) {
  return <div style={{ height: `calc(${vh100})`, ...style }}>{children}</div>;
};

export default NoneLayout;
