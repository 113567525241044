import React, { useCallback, useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';
import { I18nextProvider } from 'react-i18next';
import { Provider as RollbarProvider } from '@rollbar/react';
import { BrowserRouter } from 'react-router-dom';

import { persistor, store } from '../store';
import { GlobalStyle } from '../common/styled/global';
import 'react-toastify/dist/ReactToastify.css';
import i18n from '../i18n';
import { fixBrowserVH } from '../common/fixBrowserVH';
import { rollbar, rollbarConfig } from '../config/rollbar';
import { config } from '../services/Config';

import ErrorBoundary from './ErrorBoundary';
import Layout from './Layout';
import { NoInternetConnection } from './styled';
import { ThemeProvider } from './ThemeProvider';
import { AppModals } from './AppModals';
import { AppEvents } from './AppEvents';

interface AppState {
  isOnline: boolean;
  key: number;
}

const App: React.FC = function () {
  const [state, setState] = useState<AppState>({
    isOnline: true,
    key: 1,
  });

  const setIsOnline = useCallback(
    () => setState((state) => ({ ...state, isOnline: true, key: state.key + 1 })),
    [state, setState]
  );

  const setIsOffline = useCallback(
    () => setState((state) => ({ ...state, isOnline: false })),
    [state, setState]
  );

  useEffect(() => {
    window.addEventListener('online', setIsOnline);
    window.addEventListener('offline', setIsOffline);

    config.load();
    fixBrowserVH();

    return () => {
      window.removeEventListener('online', setIsOnline);
      window.removeEventListener('offline', setIsOffline);
    };
  }, []);

  return (
    <RollbarProvider config={rollbarConfig} instance={rollbar}>
      <Provider key={state.key} store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ErrorBoundary>
            <ThemeProvider>
              <I18nextProvider i18n={i18n}>
                <GlobalStyle />
                <BrowserRouter>
                  {!state.isOnline && (
                    <NoInternetConnection>
                      {i18n.t('common.noInternetConnection')}
                    </NoInternetConnection>
                  )}
                  <Layout />
                  <AppEvents />
                  <AppModals />
                  <ToastContainer hideProgressBar />
                </BrowserRouter>
              </I18nextProvider>
            </ThemeProvider>
          </ErrorBoundary>
        </PersistGate>
      </Provider>
    </RollbarProvider>
  );
};

export default App;
