import { AnyAction, createAction } from '@reduxjs/toolkit';
import { PrepareAction } from '@reduxjs/toolkit/src/createAction';
import { createRoutine } from 'redux-saga-routines';

export const makeCreateAction =
  (root: string) =>
  <T>(type: string, prepareAction?: PrepareAction<T>) =>
    // @ts-ignore
    createAction(`${root}/${type}`, prepareAction);

export const makeCreateRoutine =
  (root: string) =>
  (type: string, ...rest) =>
    createRoutine(`${root}/${type}`, ...rest);

export const isOneOf =
  (actions: string[]) =>
  (action: AnyAction): boolean =>
    actions.includes(action.type);
