import axios, { AxiosResponse } from 'axios';

import Timer from 'helpers/Timer';

import { createDebug } from '../../helpers/debug';

import defaultConfig from './defaultConfig.json';

const debug = createDebug.extend('config');

type ConfigData = typeof defaultConfig;

class Config {
  private config: ConfigData | undefined;

  getFullConfig() {
    return this.config;
  }

  load = () => {
    if (
      !Timer.updateIfAllowed({
        key: 'configUpdateTime',
        inTime: this.getItem('configExpiredIn'),
      }) &&
      this.config
    ) {
      debug("Got config from memory, won't load. Current config: ", this.config);
      return;
    }

    debug('Updating config...');

    axios.get('/config.json').then((data: AxiosResponse<ConfigData>) => {
      this.config = data.data;
      debug('New config: ', this.config);
    });
  };

  getItem<K extends keyof ConfigData>(key: K): ConfigData[K] {
    if (!this.config || this.config[key] === undefined) {
      return defaultConfig[key];
    }

    return this.config[key];
  }

  isFeatureEnabled<T extends keyof ConfigData['features']>(name: T) {
    if (this.config?.features[name] === undefined) {
      debug(name, `got from default config`, 'isEnabled =', defaultConfig?.features[name] === true);
      return defaultConfig?.features[name] === true;
    }

    debug(name, 'got from config', 'isEnabled =', this.config?.features[name] === true);
    return this.config?.features[name] === true;
  }
}

export const config = new Config();
