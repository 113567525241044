import React from 'react';

const Loader: React.FC = function () {
  return (
    <div className="lds-roller-container">
      <div className="lds-roller">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

export default Loader;
