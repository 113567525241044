import { makeCreateAction, makeCreateRoutine } from '../../helpers/actions';
import { VerificationStep } from '../interfaces';

const createAction = makeCreateAction('SETTINGS');
const createRoutine = makeCreateRoutine('SETTINGS');

export const verifyPhoneNumber = createRoutine('VERIFY_PHONE_NUMBER', {
  request: (value) => ({ value }),
  fulfill: (response) => ({ response }),
  failure: (error) => ({ error }),
});

export const verifyVerificationCode = createRoutine('VERIFY_VERIFICATION_CODE', {
  request: (value) => ({ value }),
  fulfill: (response) => ({ response }),
  failure: (error) => ({ error }),
});

export const verifyLicenceNumber = createRoutine('VERIFY_LICENCE_NUMBER', {
  request: (value) => ({ value }),
  fulfill: (response) => ({ response }),
  failure: (error) => ({ error }),
});

export const setUser = createAction('SET_USER', (user) => ({ payload: { user } }));
export const logout = createAction('LOGOUT');
export const backToStep = createAction('BACK_TO_STEP');
export const setStep = createAction('SET_STEP', (step: VerificationStep) => ({
  payload: { step },
}));
export const cleanVerificationCode = createAction('CLEAN_VERIFICATION_CODE');

export const getAuthParams = createRoutine('GET_AUTH_PARAMS', {
  success: ({
    accessTokenLicense,
    accessTokenParams,
    accessTokenPhone,
    accountIdentifiers,
    phoneAuth,
  }) => ({
    accessTokenLicense,
    accessTokenParams,
    accessTokenPhone,
    accountIdentifiers,
    phoneAuth,
  }),
  failure: (error) => ({ error }),
});

export const getLyftParams = createRoutine('GET_LYFT_PARAMS', {
  fulfill: (response) => ({ response }),
  success: ({ token_type, access_token }) => ({ token_type, access_token }),
});

export const actionCreators = {
  setUser,
  logout,
  verifyPhoneNumber,
  verifyVerificationCode,
  verifyLicenceNumber,
  backToStep,
  setStep,
  getAuthParams,
  getLyftParams,
} as const;
