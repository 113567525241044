import { call, delay, fork, put, select, throttle } from 'redux-saga/effects';

import Timer from 'helpers/Timer';

import { AppState, SettingsRecommendations } from '../interfaces';
import { settingsRecommendationsService } from '../../services/SettingsRecommendationsService';
import { config } from '../../services/Config';
import { StorageKeys } from '../../services/StorageService';

import { actionCreators } from './acions';

function* getRecommendations() {
  if (!config.isFeatureEnabled('settingsRecommendations')) {
    return;
  }

  const user = yield select((app: AppState) => app.auth.user);

  if (!user) {
    return;
  }

  const modalsMap = yield select((app: AppState) => app.settingsRecommendations.recommendations);

  const firstTrueKey = (Object.keys(modalsMap) as Array<keyof SettingsRecommendations>).find(
    (key) => modalsMap[key]
  );

  if (firstTrueKey) {
    return;
  }

  if (
    !Timer.updateIfAllowed({
      key: StorageKeys.serviceRecommendationTime,
      inTime: config.getItem('recommendationsTime').service * 86400,
    })
  ) {
    return;
  }

  while (true) {
    yield delay(500);

    const isSearchLoadingInBackground = yield select(
      (app: AppState) => app.app.isSearchLoadingInBackground
    );

    if (!isSearchLoadingInBackground) {
      break;
    }
  }

  const isSearchOn = yield select((app: AppState) => app.app.isSearchOn);

  const permissions = yield select((app: AppState) => app.settingsRecommendations.permissions);
  const data: Partial<SettingsRecommendations> = yield call(settingsRecommendationsService.run, {
    isSearchOn,
    permissions,
  });

  if (Object.keys(data).length === 0) {
    Timer.clear(StorageKeys.serviceRecommendationTime);
    return;
  }

  yield put(
    actionCreators.setModalsMap({
      data: {
        ...data,
        intro: true,
        allDone: true,
      },
      setToInitial: true,
    })
  );
}

export function* watchGetRecommendations() {
  const hour = 1000 * 60 * 60;
  yield throttle(hour, actionCreators.getRecommendations, getRecommendations);
}

export default function* flow() {
  yield fork(watchGetRecommendations);
}
