import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import urls from 'config/urls';
import { AppState } from 'store/interfaces';

import { Container } from './styled';

interface GuestLayoutProps {
  showLogo?: boolean;
  children: React.ReactNode;
  style?: object;
  allowToDrivers?: boolean;
  hide?: boolean;
}

const GuestLayout: React.FC<GuestLayoutProps> = function ({
  showLogo = true,
  children,
  style,
  hide = false,
  allowToDrivers = false,
}: GuestLayoutProps) {
  // @ts-ignore
  const user = useSelector((state: AppState) => state.auth.user, shallowEqual);

  if (user && allowToDrivers === false) {
    return <Navigate to={urls.rides} />;
  }

  return (
    <Container hide={hide} style={style} showLogo={showLogo}>
      {children}
    </Container>
  );
};

export default GuestLayout;
