import styled from 'styled-components';

import { vh100 } from '../config/css';

export const NoInternetConnection = styled.div`
  position: absolute;
  height: calc(${vh100});
  backdrop-filter: blur(3px);
  background-color: rgba(181, 172, 172, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  width: 100%;
  z-index: 1000;
`;
