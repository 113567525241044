import { Ride } from '../../types/ride';
import { makeCreateAction, makeCreateRoutine } from '../../helpers/actions';

const createAction = makeCreateAction('RIDES');
const createRoutine = makeCreateRoutine('RIDES');

export const getRides = createRoutine('GET_RIDES', {
  request: (isSilent = false, timestamp = +new Date()) => ({ isSilent, timestamp }),
  success: (allRides: Ride[], rides: Ride[]) => ({ allRides, rides }),
  fulfill: (response: any, timestamp: number) => ({ response, timestamp }),
});

export const getRide = createRoutine('GET_RIDE', {
  request: (id: number) => ({ id }),
  success: (ride: Ride) => ({ ride }),
});

export const getRideDestinationTime = createRoutine('GET_RIDE_DESTINATION_TIME', {
  request: (prevRide: Ride, ride: Ride) => ({ prevRide, ride }),
  success: (prevRideId: number, rideId: number, time: number) => ({ prevRideId, rideId, time }),
  failure: (prevRideId: number, rideId: number) => ({ prevRideId, rideId }),
});

export const getDriverDestinationTime = createRoutine('GET_DRIVER_DESTINATION_TIME', {
  request: (ride: Ride) => ({ ride }),
  success: (ride: Ride, time: number) => ({ ride, time }),
  failure: (ride: Ride) => ({ ride }),
});

export const dropRide = createRoutine('DROP_RIDE', {
  request: (id: number, providerRideId: string) => ({ id, providerRideId }),
  success: (id, rides: Array<Ride>) => ({ id, rides }),
  failure: (id: number, code: number) => ({ id, code }),
  fulfill: (response: any, id: number, timestamp: number) => ({ response, id, timestamp }),
});

export const addRide = createRoutine('ADD_RIDE', {
  request: (data) => ({ ...data }),
  failure: (error) => ({ error }),
});

export const exitRides = createAction('EXIT_RIDES');
export const calculateBadges = createAction('CALCULATE_BADGES');
export const addMoreRide = createAction('ADD_MORE_RIDE');

export const actionCreators = {
  getRides,
  exitRides,
  dropRide,
  getRide,
  getRideDestinationTime,
  calculateBadges,
  getDriverDestinationTime,
  addRide,
  addMoreRide,
} as const;
