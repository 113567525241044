import React from 'react';

import { EnvParam, envService } from '../../services/EnvService';
import { AcknowledgeModal } from '../../common/AcknowledgeModal';
import { Link } from '../../common/Link';
import { supportLink } from '../../config/urls';
import { Events } from '../../services/EventsService';
import { LanguageText } from '../../common/LanguageText';
import { isIos } from '../../helpers/os';
import { appDep } from '../../config/settings';
import { useTranslation } from '../../helpers/hooks';

export const ContactSupportModal = React.memo(() => {
  const hideLyftWord = isIos && appDep;
  const { t } = useTranslation();

  if (!envService.getParam(EnvParam.SHOW_INAPP_REVIEW, { asNumber: true })) {
    return null;
  }

  return (
    <AcknowledgeModal
      applyText={
        <Link target="_blank" href={supportLink}>
          {t('modals.supportModal.applyButton')}
        </Link>
      }
      cancelText={t('modals.supportModal.cancelButton')}
      showCancel
      showEventName={Events.ShowSupportModal}
      defaultShow={false}
      reverseButtons
      title={t('modals.supportModal.title')}
    >
      <p>
        <LanguageText language="en">
          {hideLyftWord ? (
            <>
              {t('common.appName')} <strong>IS NOT</strong> associated with <strong>ANY</strong> of
              the rideshare providers in <strong>ANY</strong> way, and <strong>CAN NOT</strong> be
              of help with any issues related to rides you receive from your respective rideshare
              providers.
            </>
          ) : (
            <>
              {t('common.appName')} <strong>IS NOT</strong> associated with Lyft in{' '}
              <strong>ANY</strong> way, and <strong>CAN NOT</strong> be of help with issues related
              to Lyft rides.
            </>
          )}
        </LanguageText>
        <LanguageText language="es">
          {hideLyftWord ? (
            <>
              {t('common.appName')} <strong>NO ESTÁ</strong> asociado con <strong>NINGUNO</strong>{' '}
              de los proveedores de viajes compartidos de <strong>NINGUNA</strong> manera, y{' '}
              <strong>NO PUEDE</strong> ser de ayuda con ningún problema relacionado con los viajes
              que recibe de sus respectivos proveedores de viajes compartidos.
            </>
          ) : (
            <>
              <strong>NO ESTÁ</strong> asociado con Lyft de <strong>NINGUNA</strong> manera y{' '}
              <strong>NO PUEDE</strong> ser de ayuda con problemas relacionados con los viajes de
              Lyft.
            </>
          )}
        </LanguageText>
      </p>
      <p>
        <LanguageText language="en">
          {hideLyftWord ? (
            <>
              If your ride was canceled, abolished, underpaid, or otherwise improperly conducted,
              please press the "Cancel Request" button below and contact your rideshare provider{' '}
              <strong>DIRECTLY</strong>.
            </>
          ) : (
            <>
              If your ride was canceled, abolished, underpaid, or otherwise not properly conducted,
              please press the "Cancel Request" button below and get in touch with Lyft{' '}
              <strong>DIRECTLY</strong>.
            </>
          )}
        </LanguageText>
        <LanguageText language="es">
          {hideLyftWord ? (
            <>
              Si su viaje fue cancelado, abolido, mal pagado o realizado incorrectamente, presione
              el botón "Cancelar solicitud" a continuación y comuníquese{' '}
              <strong>DIRECTAMENTE</strong>
              con su proveedor de viajes compartidos.
            </>
          ) : (
            <>
              Si su viaje se canceló, canceló, pagó mal o no se realizó de manera adecuada, presione
              el botón "Cancelar" a continuación y comuníquese <strong>DIRECTAMENTE</strong> con
              Lyft.
            </>
          )}
        </LanguageText>
      </p>
    </AcknowledgeModal>
  );
});
