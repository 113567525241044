import styled from 'styled-components';

import { useTranslation } from 'helpers/hooks';

const CreditsInfoContainer = styled.div`
  font-size: 15.5px;
  letter-spacing: -0.4px;

  @media (max-width: 375px) {
    font-size: 14.5px;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    margin-bottom: 4px;
    display: block;
  }

  li:last-child {
    margin-bottom: 0;
  }
`;

const credits = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const CreditsInfo = function () {
  const { t } = useTranslation();

  return (
    <CreditsInfoContainer>
      <ul>
        {credits.map((credit) => (
          <li key={credit} dangerouslySetInnerHTML={{ __html: t(`newCredits.price.${credit}`) }} />
        ))}
      </ul>
    </CreditsInfoContainer>
  );
};
