import styled from 'styled-components';

export const ImageWrap = styled.div`
  width: 100vw;
  max-width: 500px;
  text-align: center;

  h2 {
    color: ${(props) => props.theme.altText};
  }

  .fb-image {
    max-width: 280px;

    @media (max-width: 400px) {
      max-width: 180px;
    }
  }
`;

export const Image = styled.img`
  width: 100%;
`;

export const NavigationContainer = styled.div`
  flex: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.8);
`;

export const Navigation = styled.div`
  height: 50px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  img {
    max-height: 32px;
  }
`;
