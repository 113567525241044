import { createReducer } from '@reduxjs/toolkit';

import { isOneOf } from '../../helpers/actions';
import { dropRide } from '../rides/actions';
import { isRide } from '../../types/ride';
import { MapReducerState } from '../interfaces';
import { MarkerStatus } from '../../types/marker';

import { actionCreators } from './actions';

const initialState: Readonly<MapReducerState> = {
  markers: {
    isLoading: false,
    isFailedToGet: false,
    list: [],
  },
  showTopTip: true,
  radius: null,
  touchingMarkers: {},
  isCreatingMarker: false,
  isLoading: true,
  newPin: null,
  markerForReplace: null,
  selectedObject: null,
  driverPin: null,
  clusters: {
    isLoading: false,
    isFailedToGet: false,
    list: [],
  },
  resetPosition: 0,
  showLayers: false,
  bestRides: {
    isLoading: false,
    isFailedToGet: false,
    list: [],
  },
  layers: {
    bestRides: false,
    drivers: false,
    inactivePins: false,
    bigClusters: true,
  },
  routes: {},
  places: {
    isLoading: false,
    isFailedToGet: false,
    list: [],
  },
};

const mapReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(actionCreators.getMarkers.REQUEST, (state) => {
      state.markers.isLoading = true;
    })

    .addCase(actionCreators.getMarkers.SUCCESS, (state, action) => {
      const { markers } = action.payload;
      state.markers.list = markers;
      state.markers.isFailedToGet = false;
      state.markers.isLoading = false;
    })

    .addCase(actionCreators.setRoutes, (state, action) => {
      const { routes } = action.payload;
      state.routes = routes;
    })

    .addCase(actionCreators.getMarkers.FAILURE, (state) => {
      state.markers.isFailedToGet = true;
      state.markers.isLoading = false;
    })

    .addCase(actionCreators.getRadius.SUCCESS, (state, action) => {
      const { radius } = action.payload;

      state.radius = radius;
    })

    .addCase(actionCreators.centerMap, (state) => {
      state.resetPosition += 1;
    })

    .addCase(actionCreators.toggleShowLayers, (state) => {
      state.showLayers = !state.showLayers;
    })

    .addCase(actionCreators.toggleLayer, (state, action) => {
      const { layer } = action.payload;

      state.layers[layer] = !state.layers[layer];
    })
    .addCase(actionCreators.toggleTopTip, (state) => {
      state.showTopTip = !state.showTopTip;
    })

    .addCase(actionCreators.exitMap, (state) => {
      return { ...initialState, layers: { ...state.layers } };
    })

    .addCase(actionCreators.setSelectedObject, (state, action) => {
      const { selectedObject } = action.payload;

      if (state.selectedObject !== null && selectedObject === null) {
        state.resetPosition += 1;
      }

      state.showLayers = false;

      state.selectedObject = selectedObject;
      state.clusters.list = [];
      state.newPin = null;
    })

    .addCase(actionCreators.setNewPin, (state, action) => {
      const { newPin } = action.payload;
      state.selectedObject = newPin;
      state.newPin = newPin;
      state.showLayers = false;
    })

    .addCase(actionCreators.setDriverPin, (state, action) => {
      const { driverPin } = action.payload;
      state.driverPin = driverPin;
    })

    .addCase(actionCreators.setIsLoading, (state, action) => {
      const { isLoading } = action.payload;
      state.isLoading = isLoading;
    })

    .addCase(actionCreators.createMarker.REQUEST, (state) => {
      state.isCreatingMarker = true;
    })

    .addCase(actionCreators.createMarker.SUCCESS, (state, action) => {
      const { markers } = action.payload;
      state.markers.list = markers;
      // TODO: set selected object to created marker
      state.selectedObject = markers.find((marker) => marker.status === MarkerStatus.active);
      state.newPin = null;
      state.clusters.list = [];
      state.isCreatingMarker = false;
      state.resetPosition += 1;
      state.markerForReplace = null;
    })

    .addCase(actionCreators.createMarker.FAILURE, (state) => {
      state.isCreatingMarker = false;
      state.markerForReplace = null;
    })

    .addCase(actionCreators.getClusters.REQUEST, (state) => {
      state.clusters.isLoading = true;
      state.clusters.list = [];
      state.clusters.isFailedToGet = false;
    })

    .addCase(actionCreators.getClusters.SUCCESS, (state, action) => {
      const { clusters } = action.payload;

      state.clusters.list = clusters;
      state.clusters.isLoading = false;
    })

    .addCase(actionCreators.getClusters.FAILURE, (state) => {
      state.clusters.isLoading = false;
      state.clusters.isFailedToGet = true;
      state.clusters.list = [];
    })

    .addCase(actionCreators.getBestRides.REQUEST, (state) => {
      state.bestRides.isLoading = true;
      state.bestRides.isFailedToGet = false;
      state.bestRides.list = [];
    })

    .addCase(actionCreators.setMarkerForReplace, (state, action) => {
      const { marker } = action.payload;

      state.markerForReplace = marker;
    })

    .addCase(actionCreators.getBestRides.SUCCESS, (state, action) => {
      const { bestRides } = action.payload;

      state.bestRides.list = bestRides;
      state.bestRides.isLoading = false;
    })

    .addCase(actionCreators.getBestRides.FAILURE, (state) => {
      state.bestRides.isLoading = false;
      state.bestRides.isFailedToGet = true;
    })

    .addCase(actionCreators.getPlacesBySearch.REQUEST, (state) => {
      state.places.isLoading = true;
      state.places.isFailedToGet = false;
      state.places.list = [];
    })

    .addCase(actionCreators.getPlacesBySearch.SUCCESS, (state, action) => {
      const { places } = action.payload;

      state.places.list = places;
      state.places.isLoading = false;
    })

    .addCase(actionCreators.getPlacesBySearch.FAILURE, (state) => {
      state.places.isLoading = false;
      state.places.isFailedToGet = true;
    })

    .addCase(actionCreators.clearPlaces, (state) => {
      state.places.isLoading = false;
      state.places.list = [];
      state.places.isFailedToGet = false;
    })

    .addMatcher(isOneOf([dropRide.SUCCESS, dropRide.FAILURE]), (state, action) => {
      const { id } = action.payload;

      if (isRide(state.selectedObject) && state.selectedObject.id === id) {
        state.selectedObject = null;
      }
    })

    .addMatcher(
      isOneOf([actionCreators.updateMarker.REQUEST, actionCreators.deleteMarker.REQUEST]),
      (state, action) => {
        const { id } = action.payload;
        const { touchingMarkers } = state;

        state.touchingMarkers = {
          ...touchingMarkers,
          [id]: id,
        };
      }
    )

    .addMatcher(
      isOneOf([actionCreators.deleteMarker.SUCCESS, actionCreators.updateMarker.SUCCESS]),
      (state, action) => {
        const { id, markers } = action.payload;
        const { touchingMarkers } = state;
        const touchingMarkersCopy = { ...touchingMarkers };
        delete touchingMarkersCopy[id];
        state.markerForReplace = null;
        state.markers.list = markers;

        // TODO: select updated marker if it was inactive -> active
        state.selectedObject = markers.find((marker) => marker.status === MarkerStatus.active);
        state.touchingMarkers = touchingMarkersCopy;
      }
    )

    .addMatcher(
      isOneOf([actionCreators.updateMarker.FAILURE, actionCreators.deleteMarker.FAILURE]),
      (state, action) => {
        const { id, status } = action.payload;
        const { touchingMarkers, markers } = state;
        const touchingMarkersCopy = { ...touchingMarkers };
        delete touchingMarkersCopy[id];
        state.markerForReplace = null;
        state.touchingMarkers = touchingMarkersCopy;
        state.markers.list =
          status === 404 ? markers.list.filter((marker) => marker.id !== id) : markers.list;
      }
    )
);

export default mapReducer;
