import styled from 'styled-components';

export const H2Text = styled.h2`
  color: ${(props) => props.theme.pink} !important;
`;

export const MainText = styled.div`
  text-align: center;
  margin-bottom: 15px;
  line-height: 24px;
  font-size: 16px;
`;

export const AltText = styled.div`
  color: ${(props) => props.theme.pink};
  font-size: 20px;
  margin-bottom: 8px;
`;

export const ColorContainer = styled.div`
  position: absolute;
  z-index: 5000;
  background-color: ${(props) => props.theme.bodyBackground};
  width: 100%;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: inherit;
  overflow: hidden;
  padding: 0 16px;

  h1 {
    font-size: 30px;
    margin-top: 16px;
    text-align: center;
    color: ${(props) => props.theme.altText};
  }

  h2 {
    font-size: 20px;
    text-align: center;
  }

  .back-header {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    h1 {
      flex: 1;
    }
  }
`;

export const MainContent = styled.div`
  flex: 1;
  overflow: auto;
`;

export const FooterContent = styled.div`
  flex: 0;
  margin-top: auto;
`;
