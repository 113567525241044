export enum SearchFilter {
  startWork = 'startWork',
  workingTime = 'workingTime',
  minRidePayout = 'minRidePayout',
  radius = 'radius',
  replaceWithBetter = 'replaceWithBetter',
  search = 'search',
  dropoffState = 'dropoffState',
  pickupState = 'pickupState',
  dayOff = 'workingDays',
  shareRides = 'shareRides',
}
