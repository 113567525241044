import storageService, { StorageKeys } from '../services/StorageService';
import API from '../api';
import { Ride } from '../types/ride';

interface BestRides {
  timestamp: number;
  rides: Ride[];
}

const cacheBestRides = (sortedRides) => {
  if (sortedRides.length > 0) {
    const bestRides: BestRides = {
      timestamp: +new Date(),
      rides: sortedRides,
    };
    storageService.setItem(StorageKeys.bestRides, JSON.stringify(bestRides));
  }
};

export const getBestRides = async () => {
  try {
    const cachedRidesJSON = storageService.getItem(StorageKeys.bestRides);

    if (cachedRidesJSON) {
      const cachedRides: BestRides = JSON.parse(cachedRidesJSON);

      if (+new Date() < cachedRides.timestamp + 1000 * 60 * 60 * 24) {
        return {
          rides: cachedRides.rides,
          code: 200,
        };
      }
    }
  } catch (e) {
    console.error(e);
  }

  const response = await API.rides.top();

  // @ts-ignore
  if (response.code !== 200) {
    return response;
  }

  // @ts-ignore
  cacheBestRides(response.rides);

  return response;
};
