import React, { useCallback } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { shallowEqual, useSelector } from 'react-redux';

import urls from 'config/urls';
import { AppState } from 'store/interfaces';
import { isTestUser } from 'common/isTestUser';

import { EnvParam, envService } from '../../../../services/EnvService';
import { isIos } from '../../../../helpers/os';

import { BottomNav } from './styled';

interface NavigationProps {
  onPressLink(e): void;
  onHelpClick(): void;
}

const getLinkStyle = ({ isActive }) => (isActive ? 'active bottom-nav__link' : 'bottom-nav__link');

const Navigation: React.FC<NavigationProps> = function ({
  onPressLink,
  onHelpClick,
}: NavigationProps) {
  const handleHelpClick = useCallback(
    (e) => {
      e.preventDefault();
      onHelpClick();
    },
    [onHelpClick]
  );

  const location = useLocation();
  const { pathname } = location;
  const { user, showRideShare } = useSelector(
    (app: AppState) => ({ user: app.auth.user, showRideShare: app.app.showRideShare }),
    shallowEqual
  );

  const creditsClass = `bottom-nav__link ${
    // @ts-ignore
    [urls.credits, urls.creditsHistory, urls.creditsRules].includes(pathname) ? 'active' : ''
  }`;

  return (
    <BottomNav>
      <NavLink to={urls.rides} onClick={onPressLink} className={getLinkStyle}>
        <ReactSVG src="/assets/navigation/icon_rides.svg" />
      </NavLink>
      {showRideShare &&
        isIos &&
        envService.getParam(EnvParam.SHOW_SHARE_RIDES, { asNumber: true }) && (
          <NavLink to={urls.addRide} onClick={onPressLink} className={getLinkStyle}>
            <ReactSVG src="/assets/navigation/share.svg" />
          </NavLink>
        )}
      <NavLink to={urls.map} onClick={onPressLink} className={getLinkStyle}>
        <ReactSVG src="/assets/navigation/icon_map.svg" />
      </NavLink>
      <NavLink to={urls.settings} onClick={onPressLink} className={getLinkStyle}>
        <ReactSVG src="/assets/navigation/icon_settings.svg" />
      </NavLink>
      <NavLink
        to={urls.creditsHistory}
        className={({ isActive }) => (isActive ? `active ${creditsClass}` : creditsClass)}
      >
        <ReactSVG
          wrapper="span"
          beforeInjection={(svg) => (svg.style.fillRule = 'inherit')}
          src="/assets/navigation/icon_credits_1.svg"
        />
      </NavLink>
      {envService.getParam(EnvParam.SHOW_HELP_MENU, { asNumber: true }) && (
        <NavLink to="" onClick={handleHelpClick} className="bottom-nav__link">
          <ReactSVG wrapper="span" src="/assets/navigation/icon_help.svg" />
        </NavLink>
      )}
      {isTestUser(user) && (
        <NavLink to={urls.debug} onClick={onPressLink} className={getLinkStyle}>
          <ReactSVG src="/assets/navigation/app-settings.svg" />
        </NavLink>
      )}
    </BottomNav>
  );
};

export default React.memo(Navigation);
