import { createReducer } from '@reduxjs/toolkit';

import { AuthReducerState } from '../interfaces';

import { actionCreators } from './actions';

const initialState: Readonly<AuthReducerState> = {
  user: null,
};

const authReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(actionCreators.setUser, (state, action) => {
      const { user } = action.payload;

      state.user = user || null;
    })

    .addCase(actionCreators.logout, (state) => {
      state.user = null;
    })
);

export default authReducer;
