import React, { useCallback } from 'react';

import Select from 'common/Select';

import { LoadingProp } from '../styled';

import { hoursOptions, minutesOptions, getHourBySeconds, transformToSeconds } from './helper';
import { TimeSelectProps, TimeSelectValues } from './interfaces';

const TimeSelect: React.FC<TimeSelectProps> = function ({
  title,
  value,
  handleChange,
  isChanging,
  filterName,
}) {
  const decorateHandleChange = useCallback(
    (values) => {
      handleChange(transformToSeconds(values));
    },
    [handleChange]
  );

  if (isChanging) {
    return <LoadingProp />;
  }

  let valueGroups: TimeSelectValues = {
    hoursOptions: '',
    minutesOptions: '',
    dayOptions: '>',
  };

  if (value) {
    valueGroups = getHourBySeconds(parseInt(value, 10));
  }

  return (
    <Select
      title={title}
      handleChange={decorateHandleChange}
      valueGroups={valueGroups}
      filterName={filterName}
      optionGroups={{
        hoursOptions,
        minutesOptions,
        dayOptions: ['AM', 'PM'],
      }}
    />
  );
};

export default React.memo(TimeSelect);
