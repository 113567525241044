import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { sendEvent } from '../../../services/reactGa';
import urls from '../../../config/urls';
import { setInitialState } from '../../../store/settingsRecommendations/acions';
import { useTranslation } from '../../../helpers/hooks';
import { CarouselItem } from '../../../common/Carousel';
import { Text } from '../styled';
import { useToggleModalMap } from '../hooks';

export const NoCredits = React.memo(() => {
  const dispatch = useDispatch();
  const toggleModalsMap = useToggleModalMap();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <CarouselItem
      onClose={() => {
        sendEvent('DriverRecommendations_NoCredits_RemindLater');
        toggleModalsMap({
          noCredits: false,
        });
      }}
      onApply={() => {
        navigate(urls.credits);
        sendEvent('DriverRecommendations_NoCredits_Apply');
        dispatch(setInitialState());
      }}
      cancelText={t('search.cannotStartSearch.remindMeLater')}
      applyText={t('search.cannotStartSearch.buy')}
    >
      <Text>{t('search.cannotStartSearch.body1')}</Text>
      <p dangerouslySetInnerHTML={{ __html: t('search.cannotStartSearch.body2') }} />
    </CarouselItem>
  );
});
