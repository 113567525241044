import { call, fork, put, select, takeEvery, takeLatest, debounce } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { logout } from '../user/actions';
import API from '../../api';
import { messages } from '../../i18n';
import { Ride, RideStatusType } from '../../types/ride';
import { getRideDestinationTime } from '../../common/getRideDestinationTime';
import { getMinutes, getRideDestinationTimeIndex, sortRides } from '../../core/rides/helpers';
import { AppState } from '../interfaces';
import { getSearchStatus } from '../app/acions';
import { nativeMessageService } from '../../services/NativeMessageService';
import { isAndroid } from '../../helpers/os';

import { actionCreators } from './actions';

function* getRide({ payload }) {
  const { id } = payload;
  const response = yield call(API.rides.get, id);

  switch (response.code) {
    case 200: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { code, message, ...ride } = response;
      yield put(actionCreators.getRide.success(ride));
      yield put(actionCreators.calculateBadges());
      break;
    }
    case 401: {
      toast.error(messages.sessionTimeout);
      yield put(logout());
      break;
    }
    case 422: {
      yield put(actionCreators.getRide.failure());
      yield put(actionCreators.calculateBadges());
      break;
    }
    default: {
      yield put(actionCreators.getRide.failure());
      break;
    }
  }
}

function* getRides({ payload }) {
  const { timestamp } = payload;

  if (isAndroid) {
    nativeMessageService.sendGetRidesMessage(timestamp);
    return;
  }

  const response = yield call(API.rides.list);
  yield put(actionCreators.getRides.fulfill(response, timestamp));
}

function* getRidesSuccess({ payload }) {
  const { rides }: { rides: Array<Ride> } = payload;
  if (rides.length === 0) {
    return;
  }

  for (let i = 0; i < rides.length; i++) {
    yield put(actionCreators.getRideDestinationTime.request(rides[i - 1], rides[i]));
  }
}

function* getRidesFulfill({ payload }) {
  const { response, timestamp } = payload;

  const ridesRequestTimestamp = yield select(
    (appState: AppState) => appState.rides.ridesRequestTimestamp
  );

  if (ridesRequestTimestamp !== timestamp) {
    return;
  }

  if (response === null) {
    // toast.error(messages.serverError, { type: 'default' });
    // yield put(actionCreators.getRides.failure());
    // #TODO remove
    yield put(actionCreators.getRides.success([], []));
    yield put(actionCreators.calculateBadges());
    return;
  }

  switch (response.code) {
    case 200: {
      const allRides = sortRides(response.rides);
      /*
      #TODO Вернуть назад
      const state: AppState = yield select();
      const { showAllRides } = state.app;
      const rides = allRides.filter((ride) => {
        if (showAllRides) {
          return true;
        }

        return ride.is_booked;
      });

      yield put(actionCreators.getRides.success(allRides, rides));
      */
      yield put(actionCreators.getRides.success(allRides, allRides));
      yield put(actionCreators.calculateBadges());
      break;
    }
    case 401: {
      toast.error(messages.sessionTimeout);
      yield put(logout());
      break;
    }
    default: {
      // toast.error(messages.serverError, { type: 'default' });
      // yield put(actionCreators.getRides.failure());
      // #TODO remove
      yield put(actionCreators.getRides.success([], []));
      yield put(actionCreators.calculateBadges());
      break;
    }
  }
}

function* handleGetRideDestinationTime({ payload }) {
  const { prevRide, ride }: { prevRide?: Ride; ride: Ride } = payload;
  const state: AppState = yield select();

  if (prevRide === undefined) {
    const time = yield call(getRideDestinationTime, prevRide, ride);

    yield put(
      time
        ? actionCreators.getRideDestinationTime.success(0, ride.id, time)
        : actionCreators.getRideDestinationTime.failure(0, ride.id)
    );
    return;
  }

  const destinationTimeIndex = getRideDestinationTimeIndex(prevRide.id, ride.id);

  if (state.rides.ridesDestinationTime[destinationTimeIndex] !== undefined) {
    return;
  }

  const diff = Math.abs(getMinutes(ride.pickup_time - prevRide.end_time));
  const time = diff < 60 ? yield call(getRideDestinationTime, prevRide, ride) : 0;

  yield put(
    time
      ? actionCreators.getRideDestinationTime.success(prevRide.id, ride.id, time)
      : actionCreators.getRideDestinationTime.failure(prevRide.id, ride.id)
  );
}

function* calculateBadges() {
  const state: AppState = yield select();
  const { rides } = state.rides;

  const newRidesCount = rides.reduce((acc, ride: Ride) => {
    if (ride.ride_status === RideStatusType.New) {
      return acc + 1;
    }

    return acc;
  }, 0);

  nativeMessageService.sendBadgeMessage(newRidesCount);
}

function* dropRide({ payload }) {
  const { id, providerRideId } = payload;

  if (isAndroid) {
    nativeMessageService.sendDropRideMessage(id, providerRideId);
    return;
  }

  const response = yield call(API.rides.delete, id);
  yield put(actionCreators.dropRide.fulfill(response, id));
}

function* dropRideFulfill({ payload }) {
  const { response, id, timestamp } = payload;

  if (response === null) {
    yield put(actionCreators.dropRide.failure(id, 500));
    yield put(actionCreators.calculateBadges());
    return;
  }

  switch (response.code) {
    case 200: {
      yield put(actionCreators.dropRide.success(id, response.rides));
      yield put(getSearchStatus.request());
      const allRides = sortRides(response.rides);

      /*
      const state: AppState = yield select();
      const { showAllRides } = state.app;

      const rides = allRides.filter((ride) => {
        if (showAllRides) {
          return true;
        }

        return ride.is_booked;
      });
      */

      yield put(actionCreators.getRides.success(allRides, allRides));
      yield put(actionCreators.calculateBadges());

      break;
    }
    case 401: {
      toast.error(messages.sessionTimeout);
      yield put(logout());
      break;
    }
    case 404:
    case 422: {
      yield put(actionCreators.dropRide.failure(id, response.code));
      yield put(actionCreators.calculateBadges());
      break;
    }
    default: {
      // toast.error(messages.serverError, { type: 'default' });
      yield put(actionCreators.dropRide.failure(id, response.code));
      break;
    }
  }
}

function* addRide({ payload }) {
  const { ...data } = payload;
  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    formData.append(key, `${data[key]}`);
  });

  const response = yield call(API.rides.add, formData);

  switch (response.code) {
    case 200: {
      yield put(actionCreators.addRide.success());
      break;
    }
    case 401: {
      toast.error(messages.sessionTimeout);
      yield put(logout());
      break;
    }
    case 404:
    case 422: {
      yield put(actionCreators.addRide.failure(response.message));

      break;
    }
    default: {
      yield put(actionCreators.addRide.failure(response.message));
      break;
    }
  }
}

function* getDriverDestinationTime({ payload }) {
  const { ride } = payload;

  try {
    const { coords } = yield call(async () => {
      return await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject, {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 5000,
        });
      });
    });

    const { latitude, longitude } = coords;

    /*
    const latitude = '37.23818011979866';
    const longitude = '-40.34375000000001';
    */

    const formData = new FormData();

    formData.append('data[service]', 'route');
    formData.append('data[data][sLat]', `${latitude}`);
    formData.append('data[data][sLng]', `${longitude}`);
    formData.append('data[data][fLat]', `${ride.start_lat}`);
    formData.append('data[data][fLng]', `${ride.start_lng}`);

    const response = yield call(API.getInformation, formData);

    if (response.status !== 200) {
      yield put(actionCreators.getDriverDestinationTime.failure(ride));
      return;
    }

    const time = response.data.data.raw.routes[0].duration;
    if (time) {
      yield put(actionCreators.getDriverDestinationTime.success(ride, time));
      return;
    }
  } catch (e) {}

  yield put(actionCreators.getDriverDestinationTime.failure(ride));
}

export function* watchGetRides() {
  yield debounce(500, actionCreators.getRides.REQUEST, getRides);
}

export function* watchGetRidesSuccess() {
  yield takeLatest(actionCreators.getRides.SUCCESS, getRidesSuccess);
}

export function* watchGetRidesFulfill() {
  yield takeLatest(actionCreators.getRides.FULFILL, getRidesFulfill);
}

export function* watchDropRideFulfill() {
  yield takeLatest(actionCreators.dropRide.FULFILL, dropRideFulfill);
}

export function* watchGetRide() {
  yield takeLatest(actionCreators.getRide.REQUEST, getRide);
}

export function* watchGetRideDestinationTime() {
  yield takeEvery(actionCreators.getRideDestinationTime.REQUEST, handleGetRideDestinationTime);
}

export function* watchDropRide() {
  yield takeEvery(actionCreators.dropRide.REQUEST, dropRide);
}
export function* watchAddRide() {
  yield takeEvery(actionCreators.addRide.REQUEST, addRide);
}

export function* watchCalculateBadges() {
  yield takeEvery(actionCreators.calculateBadges.type, calculateBadges);
}

export function* watchGetDriverDestinationTime() {
  yield takeEvery(actionCreators.getDriverDestinationTime.REQUEST, getDriverDestinationTime);
}

export default function* flow() {
  yield fork(watchGetRides);
  yield fork(watchGetRide);
  yield fork(watchDropRide);
  yield fork(watchGetRidesSuccess);
  yield fork(watchGetRidesFulfill);
  yield fork(watchDropRideFulfill);
  yield fork(watchGetRideDestinationTime);
  yield fork(watchCalculateBadges);
  yield fork(watchGetDriverDestinationTime);
  yield fork(watchAddRide);
}
