import React from 'react';

import { useTranslation } from '../../../helpers/hooks';
import { CarouselItem } from '../../../common/Carousel';
import { SearchFilter } from '../../../core/settings/interfaces';
import { useSettings } from '../../../core/settings/hooks';
import WorkHoursSelect from '../../../core/settings/WorkHoursSelect';
import { Body, Hint, StepBody, Value } from '../styled';
import { StepProps } from '../interfaces';
import { eventsService } from '../../../services/EventsService';

export const WorkingTime: React.FC<StepProps> = function ({ setNextWizardStep }) {
  const { t } = useTranslation();

  const { setWorkHours, isChanging, daySettings } = useSettings();
  return (
    <CarouselItem
      bodyWrapper={StepBody}
      onApply={setNextWizardStep}
      applyText={t('settings.wizard.workingTime.button')}
    >
      <Body dangerouslySetInnerHTML={{ __html: t('settings.wizard.workingTime.body') }} />
      <Value onClick={() => eventsService.toggleSettingsFilter(SearchFilter.workingTime)}>
        <WorkHoursSelect
          isChanging={isChanging.end_work}
          handleChange={setWorkHours}
          value={daySettings.end_work}
          title={t('settings.filters.workingTime')}
          filterName={SearchFilter.workingTime}
        />
      </Value>
      <Hint
        dangerouslySetInnerHTML={{
          __html: t('settings.wizard.workingTime.hint', { hours: '12' }),
        }}
      />
    </CarouselItem>
  );
};
