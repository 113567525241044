import { Middleware, Action } from 'redux';

import api from '../api';

import { AppState } from './interfaces';

export const tokenMiddleware: Middleware =
  (state) =>
  (next) =>
  (action: Action): void => {
    const appState: AppState = state.getState();
    const { auth } = appState;

    if (auth.user) {
      // @ts-ignore
      api.api.defaults.headers.Authorization = `Bearer ${auth.user.auth_key}`;
      // @ts-ignore
    } else if (api.api.defaults.headers.Authorization) {
      // @ts-ignore
      delete api.api.defaults.headers.Authorization;
    }

    next(action);
  };
