import React from 'react';

import { useTranslation } from '../../../helpers/hooks';
import { CarouselItem } from '../../../common/Carousel';
import { SearchFilter } from '../../../core/settings/interfaces';
import TimeSelect from '../../../core/settings/TimeSelect';
import { useSettings } from '../../../core/settings/hooks';
import { StepProps } from '../interfaces';
import { Value, Body, StepBody } from '../styled';
import { eventsService } from '../../../services/EventsService';

export const BeginningOfWork: React.FC<StepProps> = function ({ setNextWizardStep }) {
  const { t } = useTranslation();
  const { setStartWorkTime, isChanging, daySettings } = useSettings();

  return (
    <CarouselItem
      onApply={setNextWizardStep}
      applyText={t('settings.wizard.beginningOfWork.button')}
      bodyWrapper={StepBody}
    >
      <Body dangerouslySetInnerHTML={{ __html: t('settings.wizard.beginningOfWork.body') }} />
      <Value onClick={() => eventsService.toggleSettingsFilter(SearchFilter.startWork)}>
        <TimeSelect
          isChanging={isChanging.begin_work}
          handleChange={setStartWorkTime}
          value={daySettings.begin_work}
          filterName={SearchFilter.startWork}
          title={t('settings.filters.startWork')}
        />
      </Value>
    </CarouselItem>
  );
};
