import React from 'react';
import { useTheme } from 'styled-components';

import { useTranslation } from 'helpers/hooks';
import { Link } from 'common/Link';
import { fbLink } from 'config/urls';
import { registerStepByStepFbGroupClickEvent } from 'services/reactGa';

import { MainText } from '../styled';

export const FbText: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <MainText style={{ margin: '10px 0px 12px', maxWidth: 290 }}>
      {t('onBoarding.fb.text')}{' '}
      <Link color={theme.pink} onClick={registerStepByStepFbGroupClickEvent} href={fbLink}>
        {t('onBoarding.fb.url')}
      </Link>
      :
    </MainText>
  );
});
