import { Coords } from 'leaflet';

import { getPlaceName } from './getPlaceName';

export interface Place {
  key: string;
  bounds: null | Coords[];
  coords: Coords;
  name: string;
}

export const getPlaces = (response, withType = true): Place[] => {
  return response.data.data.raw.features.reduce((acc, place) => {
    const [lng, lat] = place.geometry.coordinates;
    const { coordinates } = place.geometry;
    const { osm_value, extent, type = coordinates[0], osm_key = coordinates[1] } = place.properties;

    const key = `${osm_value}-${type}-${osm_key}`;

    if (acc[key]) {
      return acc;
    }

    const name = getPlaceName(place, withType);

    acc[key] = {
      key,
      bounds:
        extent?.length > 3
          ? [
              { lng: extent[0], lat: extent[1] },
              { lng: extent[2], lat: extent[3] },
            ]
          : null,
      coords: {
        lng,
        lat,
      },
      name,
    };
    return acc;
  }, {});
};
