import API from '../api';
import { Ride } from '../types/ride';
import { getRideDestinationTimeIndex } from '../core/rides/helpers';
import storageService, { StorageKeys } from '../services/StorageService';

export interface DestinationTimes {
  [key: string]: DestinationTime;
}

interface DestinationTime {
  time: number | null;
  pickupTime: number;
}

const getTime = (index: string): number | null => {
  const destinationTimesStr = storageService.getItem(StorageKeys.destinationTimes);

  if (!destinationTimesStr) {
    return null;
  }

  const destinationTimes: DestinationTimes = JSON.parse(destinationTimesStr);
  const destinationTime = destinationTimes[index];

  if (destinationTime !== undefined) {
    return destinationTime.time;
  }

  return null;
};

const setTime = (index: string, destinationTime: DestinationTime) => {
  const destinationTimesStr = storageService.getItem(StorageKeys.destinationTimes);
  let destinationTimes: DestinationTimes;

  if (destinationTimesStr) {
    destinationTimes = JSON.parse(destinationTimesStr);
    destinationTimes[index] = destinationTime;
  } else {
    destinationTimes = {
      [`${index}`]: destinationTime,
    };
  }

  storageService.setItem(StorageKeys.destinationTimes, JSON.stringify(destinationTimes));
};

export const getRideDestinationTime = async (
  prevRide: Ride | undefined,
  ride: Ride
): Promise<number | null> => {
  try {
    const index = getRideDestinationTimeIndex(prevRide ? prevRide.id : 0, ride.id);
    let time = getTime(index);

    if (time !== null && prevRide) {
      return time;
    }

    const formData = new FormData();

    formData.append('data[service]', 'route');

    if (prevRide === undefined) {
      try {
        // @ts-ignore
        const { coords } = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject, {
            enableHighAccuracy: true,
            timeout: 10000,
            maximumAge: 5000,
          });
        });
        // 38.23818011979866&lng=-122.34375000000001
        // 37.4590753068184843
        // -88.3747345581651
        /* const latitude = '37.23818011979866';
        const longitude = '-122.34375000000001';
        console.log({
          coords,
          latitude,
          longitude,
        }); */
        const { latitude, longitude } = coords;
        formData.append('data[data][sLat]', `${latitude}`);
        formData.append('data[data][sLng]', `${longitude}`);
      } catch (e) {
        console.log(e);
        return 0;
      }
    } else {
      formData.append('data[data][sLat]', `${prevRide.end_lat}`);
      formData.append('data[data][sLng]', `${prevRide.end_lng}`);
    }

    formData.append('data[data][fLat]', `${ride.start_lat}`);
    formData.append('data[data][fLng]', `${ride.start_lng}`);

    const response = await API.getInformation(formData);

    if (response.status !== 200) {
      return null;
    }

    time = response.data.data.raw.routes[0].duration;
    setTime(index, { pickupTime: ride.pickup_time, time });

    return time;
  } catch (e) {
    return null;
  }
};
