import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Modal from '../../common/Modal';
import { setRideCreditsAlert } from '../../store/app/acions';
import urls from '../../config/urls';
import { useTranslation } from '../../helpers/hooks';
import { AppState } from '../../store/interfaces';
import { sendEvent } from '../../services/reactGa';

export const BuyCreditsModal = React.memo(() => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const rideCreditsAlert = useSelector(
    (state: AppState) => state.app.rideCreditsAlert,
    shallowEqual
  );

  const isSearchAlert = rideCreditsAlert === 'search';

  const handleClose = () => dispatch(setRideCreditsAlert(null));
  const handleApply = () => {
    sendEvent(`${isSearchAlert ? 'Search' : 'Settings'}_ShowCreditsAlert`);
    handleClose();
    navigate(urls.credits);
  };

  if (!rideCreditsAlert) {
    return null;
  }

  return (
    <Modal
      showCancel
      onClose={handleClose}
      closeOnMask={false}
      onApply={handleApply}
      applyText={t('credits.applyButton')}
    >
      {t(isSearchAlert ? 'credits.needToBuy' : 'credits.settingsNeedToBuyCredits')}
    </Modal>
  );
});
