import React, { useState } from 'react';
import Done from '@material-ui/icons/Done';
import styled, { css } from 'styled-components';

interface CheckboxProps {
  value?: boolean;
  defaultValue?: boolean;
  onChange?(value: boolean): void;
}

interface ContainerProps {
  isChecked: boolean;
}

const Container = styled.span<ContainerProps>`
  background: #fff;
  border-radius: 8px;
  display: inline-block;
  height: 26px;
  width: 26px;
  position: relative;

  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    path {
      fill: #b9bcbd;
    }
    margin-left: 0;
  }

  ${(props) => {
    if (props.isChecked) {
      // @ts-ignore
      return css`
        border: 2px solid #000;
        svg path {
          fill: #06af50;
        }
      `;
    }

    // @ts-ignore
    return css`
      border: 2px solid #a8a8a8;
      svg {
        visibility: hidden;
      }
    `;
  }}
`;

export const Checkbox: React.FC<CheckboxProps> = React.memo(function ({
  defaultValue = false,
  onChange,
  value,
}) {
  const [currentValue, setCurrentValue] = useState<boolean>(
    value !== undefined ? value : defaultValue
  );

  const toggle = () => {
    setCurrentValue((value) => !value);

    if (onChange) {
      onChange(!currentValue);
    }
  };

  return (
    <Container
      className="checkbox"
      isChecked={value !== undefined ? value : currentValue}
      onClick={toggle}
    >
      <Done fontSize="small" />
    </Container>
  );
});
