import { AnotherDriver } from './anotherDriver';
import { Cluster } from './cluster';

export enum MarkerStatus {
  inactive = 0,
  active = 1,
}

export interface Marker {
  id: number;
  lat: string;
  lng: string;
  address: string;
  status: MarkerStatus;
  drivers: AnotherDriver[];
  clusters: Cluster[];
}

export function isMarker(arg: any): arg is Marker {
  return (
    arg &&
    arg.id !== undefined &&
    arg.lat !== undefined &&
    arg.lng !== undefined &&
    arg.address !== undefined &&
    arg.status !== undefined &&
    arg.drivers !== undefined &&
    arg.clusters !== undefined
  );
}
