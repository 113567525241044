import styled from 'styled-components';

import { isIos } from '../../../helpers/os';

interface ContainerStyledProps {
  isHided: boolean;
}

export const ContainerStyled = styled.div<ContainerStyledProps>`
  display: ${({ isHided }) => (isHided ? 'none' : 'flex')};
  flex-direction: column;
  height: 100%;
  margin: 0 auto;
`;

export const HeaderStyled = styled.div`
  background: ${({ theme }) => theme.background};
  padding: ${isIos ? '8px' : 0} 20px 0;
  flex: 0 0 60px;
`;

export const ContentStyled = styled.div`
  flex-grow: 3;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const FooterStyled = styled.div`
  width: 100%;
  flex: 0 0 60px;
  background: ${({ theme }) => theme.background};
`;

export const BackButton = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: -10px;
  padding: 10px 15px 10px 10px;
  cursor: pointer;
`;

export const HeaderContainerStyled = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0;
  align-items: center;
  height: 100%;
  justify-content: space-between;
`;

export const TitleStyled = styled.div`
  font-size: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: center;
  position: relative;
  font-weight: bold;
  margin-left: 15px;
`;
