import { createReducer } from '@reduxjs/toolkit';

import { SettingsReducerState } from '../interfaces';

import { actionCreators } from './actions';

const initialState: Readonly<SettingsReducerState> = {
  isSettingsLoading: true,
  isChanging: {
    begin_work: false,
    end_work: false,
    radius: false,
    min_dist: false,
    ride_replacement: false,
    dropoff_state: false,
    pickup_state: false,
    day_off: false,
    pin: false,
  },
  isFailedToGetSettings: false,
  daySettings: {
    begin_work: null,
    end_work: null,
    radius: null,
    min_dist: null,
    dropoff_state: null,
    pickup_state: null,
    day_off: null,
    ride_replacement: null,
    ride_types: null,
  },
};

const settingsReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(actionCreators.getSettings.REQUEST, (state, action) => {
      const { isSilent } = action.payload;

      if (isSilent) {
        return;
      }

      (Object.keys(state.isChanging) as Array<keyof SettingsReducerState['isChanging']>).forEach(
        (key) => {
          state.isChanging[key] = true;
        }
      );

      state.isSettingsLoading = true;
    })

    .addCase(actionCreators.getSettings.SUCCESS, (state, action) => {
      state.daySettings = {
        ...action.payload,
      };
      state.isSettingsLoading = false;
      state.isFailedToGetSettings = false;
      state.isChanging = {
        ...initialState.isChanging,
      };
    })

    .addCase(actionCreators.getSettings.FAILURE, (state) => {
      state.isSettingsLoading = false;
      state.isFailedToGetSettings = true;
      state.isChanging = {
        ...initialState.isChanging,
      };
    })

    .addCase(actionCreators.setSettings.REQUEST, (state, action) => {
      const { property } = action.payload;
      state.isChanging[property] = true;
    })

    .addCase(actionCreators.setSettings.SUCCESS, (state, action) => {
      const { settings } = action.payload;

      state.daySettings = settings;
      state.isChanging = {
        ...initialState.isChanging,
      };
      state.isSettingsLoading = false;
    })

    .addCase(actionCreators.setSettings.FAILURE, (state) => {
      state.isChanging = {
        ...initialState.isChanging,
      };
    })

    .addCase(actionCreators.exitSettings, (state) => {
      state.isSettingsLoading = true;
    })
);

export default settingsReducer;
