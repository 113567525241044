import React from 'react';

import { CarouselItem } from '../../../common/Carousel';
import { useTranslation } from '../../../helpers/hooks';
import { StepProps } from '../interfaces';
import { SearchFilter } from '../../../core/settings/interfaces';
import FilterSelect from '../../../core/settings/FilterSelect';
import { config } from '../../../services/Config';
import { useSettings } from '../../../core/settings/hooks';
import { Body, StepBody, Value } from '../styled';
import { eventsService } from '../../../services/EventsService';

export const MinimumRidePayout: React.FC<StepProps> = function ({ setNextWizardStep }) {
  const { t } = useTranslation();
  const { moneyFilter } = config.getItem('settings');
  const { setFilterValue, isChanging, daySettings } = useSettings();
  return (
    <CarouselItem
      onApply={setNextWizardStep}
      applyText={t('settings.wizard.minimumRidePayout.button')}
      bodyWrapper={StepBody}
    >
      <Body dangerouslySetInnerHTML={{ __html: t('settings.wizard.minimumRidePayout.body') }} />
      <Value onClick={() => eventsService.toggleSettingsFilter(SearchFilter.minRidePayout)}>
        <FilterSelect
          isChanging={isChanging.min_dist}
          title={t('settings.filters.minRidePayout')}
          // @ts-ignore
          handleChange={(value) => setFilterValue(value, 'min_dist')}
          value={daySettings.min_dist}
          prefix="$"
          min={moneyFilter.min}
          max={moneyFilter.max}
          step={moneyFilter.step}
          filterName={SearchFilter.minRidePayout}
        />
      </Value>
    </CarouselItem>
  );
};
