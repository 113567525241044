import React, { useEffect, useState } from 'react';
import { compareVersions } from 'compare-versions';
import axios from 'axios';
import { useDispatch } from 'react-redux';

import { useTranslation } from 'helpers/hooks';

import { Button, Container } from '../../../common/styled/form';
import GuestLayout from '../../../app/Layout/GuestLayout';
import { getAppStoreUrl } from '../../../config/urls';
import { Link } from '../../../common/Link';
import storageService, { StorageKeys } from '../../../services/StorageService';
import { isAndroid, isIos } from '../../../helpers/os';
import { Events, eventsService } from '../../../services/EventsService';
import { rollbar } from '../../../config/rollbar';
import { SkeddyImage } from '../../../common/Carousel';
import { sendEvent } from '../../../services/reactGa';
import { iosVersion } from '../../../config/appVersions';
import { logout } from '../../../store/user/actions';

import { MainContainer, MainText, Title, AppLinks, LayoutWrapper } from './styled';

const url = getAppStoreUrl();

const getInitialShowUpdatePage = async () => {
  try {
    const appVersion = storageService.getAppVersion();

    if (!appVersion) {
      return false;
    }

    const androidConfig = await axios.get('/android.json');

    const show = isAndroid
      ? // @ts-ignore
        compareVersions(androidConfig.data.version, appVersion) > 0
      : compareVersions(iosVersion, appVersion) > 0;

    if (show) {
      sendEvent('UpdatePage_Show');
    }

    return show;
  } catch (e) {
    rollbar.error(e);
    return false;
  }
};

const UpdatePage: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const [showUpdatePage, setShowUpdatePage] = useState(false);
  const dispatch = useDispatch();

  const checkUpdate = async () => {
    const show = await getInitialShowUpdatePage();
    setShowUpdatePage(show);
  };

  useEffect(() => {
    checkUpdate().then();
  }, []);

  useEffect(() => {
    const handleStorageUpdate = () => {
      setTimeout(checkUpdate, 500);
    };

    const key = 'isLogoutCompleted';

    const isLogoutCompleted = localStorage.getItem(key);

    if (!isLogoutCompleted) {
      localStorage.setItem(key, '1');
      dispatch(logout());
    }

    eventsService.on(Events.SetClientHeaders, handleStorageUpdate);

    return () => {
      eventsService.off(Events.SetClientHeaders, handleStorageUpdate);
    };
  }, []);

  const handleGoToShore = () => {
    sendEvent('UpdatePage_ApplyClick');
  };

  const handleClose = () => {
    sendEvent('UpdatePage_CloseClick');
    setShowUpdatePage(false);
  };

  if (isIos) {
    return null;
  }

  if (!showUpdatePage) {
    return null;
  }

  return (
    <LayoutWrapper style={{ position: 'absolute', top: 0, zIndex: 100 }}>
      <GuestLayout style={{ backgroundSize: '0' }} allowToDrivers>
        <MainContainer>
          <Container>
            <Title>{t('update.title')}</Title>
            <MainText>{t('update.body')}</MainText>
            <AppLinks>
              <Link onClick={handleGoToShore} target="_blank" href={url}>
                <Button>{t('update.button')}</Button>
              </Link>
              <Link onClick={handleClose} color="#FF00ED">
                {t('update.remindMeLater')}
              </Link>
            </AppLinks>
            <div style={{ marginTop: 'auto', textAlign: 'center' }}>
              <SkeddyImage />
            </div>
          </Container>
        </MainContainer>
      </GuestLayout>
    </LayoutWrapper>
  );
});

export default UpdatePage;
