import { shallowEqual } from 'react-redux';

import { Purchase } from '../store/interfaces';

import storageService, { StorageKeys, StorageService } from './StorageService';

export interface ValidationItem {
  purchase: Purchase;
  retryCount: number;
  retryIn: number;
}

const retryIn = (minute: number) => {
  const now = +new Date();
  return now + 1000 * 60 * minute;
};

export class ValidationRetryService {
  private queue: ValidationItem[];

  private storageService: StorageService;

  static readonly BASE_RETRY_TIME: number = 1;

  static readonly BASE_RETRY_COUNT: number = 60;

  constructor({ storageService }) {
    this.storageService = storageService;
    const queue = this.storageService.getItem(StorageKeys.validationItems);

    try {
      this.queue = queue ? JSON.parse(queue) : [];
    } catch (e) {
      this.queue = [];
    }
  }

  private getItem = (purchase: Purchase): ValidationItem | undefined => {
    return this.queue.find((item: ValidationItem) => shallowEqual(item.purchase, purchase));
  };

  addItem = (purchase: Purchase) => {
    const item = this.getItem(purchase);

    if (!item) {
      this.createItem(purchase);
      return;
    }

    this.consumeRetry(item);

    if (item.retryCount === 0) {
      this.removeItem(item);
    }
  };

  private createItem = (purchase: Purchase) => {
    this.queue.push({
      purchase,
      retryCount: ValidationRetryService.BASE_RETRY_COUNT,
      retryIn: retryIn(ValidationRetryService.BASE_RETRY_TIME),
    });

    this.saveQueue();
  };

  private consumeRetry = (item: ValidationItem) => {
    item.retryCount -= 1;
    item.retryIn = retryIn(ValidationRetryService.BASE_RETRY_TIME);

    this.saveQueue();
  };

  removePurchase = (purchase: Purchase) => {
    this.queue = this.queue.filter(
      (item: ValidationItem) => !shallowEqual(item.purchase, purchase)
    );

    this.saveQueue();
  };

  private removeItem = (item: ValidationItem) => {
    this.queue = this.queue.filter((i) => !shallowEqual(i, item));
    this.saveQueue();
  };

  private saveQueue = () => {
    const queue = JSON.stringify(this.queue);
    this.storageService.setItem(StorageKeys.validationItems, queue);
  };

  getPurchases = (): Purchase[] => {
    const storageQueue = this.storageService.getItem(StorageKeys.validationItems);

    if (!storageQueue) {
      return [];
    }

    try {
      const now = +new Date();
      const queue: ValidationItem[] = JSON.parse(storageQueue);

      return Object.values(queue)
        .filter((item: ValidationItem) => item.retryIn < now)
        .map((item: ValidationItem) => item.purchase);
    } catch (e) {
      return [];
    }
  };
}

export const validationRetryService = new ValidationRetryService({ storageService });
