import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'helpers/hooks';

import { InfoModal } from '../InfoModal';
import { AcknowledgeModal } from '../../common/AcknowledgeModal';
import { SettingsRecommendationsCarousel } from '../SettingsRecommendations';
import InAppReviewModal from '../InAppReviewModal';
import { AppState } from '../../store/interfaces';
import { Events } from '../../services/EventsService';
import { agreedNewRides } from '../../store/app/acions';
import {
  registerNewCreditsAcceptEvent,
  registerNewCreditsRemindLaterEvent,
  registerNewCreditsShowAcceptModalEvent,
} from '../../services/reactGa';
import ClientMessageModal from '../ClientMessageModal';
import { BuyCreditsModal } from '../BuyCreditsModal';
import { ContactSupportModal } from '../ContactSupportModal';

import { CreditsInfo } from './styled';

export const AppModals = React.memo(() => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user, isAgreed } = useSelector(
    (state: AppState) => ({
      user: state.auth.user,
      isAgreed: state.app.isAgreed,
    }),
    shallowEqual
  );

  if (!user) {
    return null;
  }

  return (
    <>
      <BuyCreditsModal />
      <InfoModal />
      <InAppReviewModal />
      <ClientMessageModal />
      <SettingsRecommendationsCarousel />
      <ContactSupportModal />
      {isAgreed !== null && isAgreed === false && (
        <AcknowledgeModal
          title={t('newCredits.changed.title')}
          applyText={t('newCredits.changed.applyButton')}
          cancelText={t('newCredits.changed.cancelButton')}
          showCancel
          handleOnApply={() => {
            registerNewCreditsAcceptEvent();
            dispatch(agreedNewRides.request());
          }}
          handleOnCancel={registerNewCreditsRemindLaterEvent}
          showEventName={Events.ShowCreditsChangedModal}
          onShow={registerNewCreditsShowAcceptModalEvent}
        >
          {t('newCredits.changed.bodyStart')}
          <CreditsInfo />
          <p>{t('newCredits.changed.bodyEnd1')}</p>
          <p>{t('newCredits.changed.bodyEnd2')}</p>
          <p>
            {t('newCredits.changed.acceptDescription')}
            <br />
            {t('newCredits.changed.remindDescription')}
          </p>
        </AcknowledgeModal>
      )}
    </>
  );
});
