import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { useTranslation } from 'helpers/hooks';
import { ReactComponent as InfoIcon } from 'core/settings/Info/info.svg';

import { CloseButton } from '../CloseButton';

import {
  ModalHeader,
  ModalMask,
  ModalTitle,
  ApplyButton,
  ModalContainer,
  ModalBody,
  ModalDescription,
  CancelButton,
  ModalFooter,
} from './styled';

interface ModalProps {
  onClose?(): void;
  title?: any;
  children?: any;
  footer?: any;
  onApply?(): void;
  applyText?: any;
  cancelText?: string;
  showCancel?: boolean;
  closeOnMask?: boolean;
  position?: 'center' | 'bottom';
  onUnmount?(): void;
  reverseButtons?: boolean;
}

const animationTime = '0.3s';

const modalRoot = document.getElementById('modal-root');

const voidFunc = () => {};

export const Modal: React.FC<ModalProps> = ({
  title,
  children,
  showCancel = true,
  onClose = voidFunc,
  onApply = voidFunc,
  applyText,
  cancelText,
  footer,
  closeOnMask = true,
  position = 'center',
  onUnmount,
  reverseButtons = false,
}: ModalProps) => {
  const { t } = useTranslation();
  const [isClosing, setIsClosing] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      if (onUnmount) {
        onUnmount();
      }
    };
  }, []);

  // eslint-disable-next-line consistent-return
  const closeModal = useCallback(() => {
    if (isClosing) {
      return null;
    }

    setIsClosing(true);

    setTimeout(() => {
      onClose();
    }, parseFloat(animationTime) * 1000);
  }, [isClosing, onClose]);

  const handleMaskClick = useCallback(() => {
    return closeOnMask ? closeModal() : () => {};
  }, [closeOnMask, closeModal]);

  return ReactDOM.createPortal(
    <ModalContainer>
      <ModalMask animationTime={animationTime} showModal={!isClosing} onClick={handleMaskClick} />
      <ModalBody position={position} animationTime={animationTime} showModal={!isClosing}>
        {title && (
          <ModalHeader>
            {position === 'bottom' && <InfoIcon />}
            <ModalTitle>{title}</ModalTitle>
            {position === 'bottom' && (
              <CloseButton top={0} right={0} size="small" position="relative" onClick={onClose} />
            )}
          </ModalHeader>
        )}
        {children && (
          <ModalDescription position={position} style={title ? { paddingTop: 0 } : {}}>
            {children}
          </ModalDescription>
        )}
        {position === 'center' && (
          <ModalFooter>
            {footer ||
              (reverseButtons ? (
                <>
                  <ApplyButton onClick={closeModal}>
                    {cancelText || t('components.modal.cancel')}
                  </ApplyButton>
                  <CancelButton onClick={onApply}>
                    {applyText || t('components.modal.apply')}
                  </CancelButton>
                </>
              ) : (
                <>
                  <ApplyButton onClick={onApply}>
                    {applyText || t('components.modal.apply')}
                  </ApplyButton>
                  {showCancel && (
                    <CancelButton onClick={closeModal}>
                      {cancelText || t('components.modal.cancel')}
                    </CancelButton>
                  )}
                </>
              ))}
          </ModalFooter>
        )}
      </ModalBody>
    </ModalContainer>,
    modalRoot!
  );
};

export default Modal;
