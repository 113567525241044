import { isIos } from '../helpers/os';

interface Coords {
  lat: string;
  lng: string;
}

export const defaultPin: Readonly<Coords> = {
  lat: '40.705287916912724',
  lng: '-74.01399014489684',
};

export const appDep = isIos;
