import React from 'react';
import { useDispatch } from 'react-redux';

import { sendEvent } from 'services/reactGa';
import { setSettings } from 'store/settings/actions';
import { settingsRecommendationsService } from 'services/SettingsRecommendationsService';
import { useTranslation } from 'helpers/hooks';
import { CarouselItem } from 'common/Carousel';

import { useToggleModalMap } from '../hooks';
import { Text } from '../styled';

export const MinimumRidePayout = React.memo(() => {
  const toggleModalsMap = useToggleModalMap();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <CarouselItem
      onMount={() => sendEvent('DriverRecommendations_MinimumRidePayout_Show')}
      onClose={() => {
        sendEvent('DriverRecommendations_MinimumRidePayout_RemindLater');
        toggleModalsMap({ minimumRidePayout: false });
      }}
      onApply={() => {
        sendEvent('DriverRecommendations_MinimumRidePayout_Apply');
        dispatch(
          setSettings.request({
            value: settingsRecommendationsService.getRecommendedMinPayout(),
            property: 'min_dist',
          })
        );
        toggleModalsMap({
          minimumRidePayout: false,
          minimumRidePayoutSuccess: true,
        });
      }}
      applyText={t('modals.recommendations.minimumRidePayout.applyButton')}
      cancelText={t('modals.recommendations.minimumRidePayout.cancelButton')}
    >
      <Text>{t('modals.recommendations.minimumRidePayout.body1')}</Text>
      <p
        dangerouslySetInnerHTML={{
          __html: t('modals.recommendations.minimumRidePayout.body2', {
            payout: `${settingsRecommendationsService.getRecommendedMinPayout()}`,
          }),
        }}
      />
    </CarouselItem>
  );
});
