import React from 'react';
import styled from 'styled-components';

import GuestLayout from '../../../../app/Layout/GuestLayout';

import { ImageWrap, Image } from './styled';

const Container = styled.div`
  max-width: 340px;
  margin-top: 20px;
`;

interface SlideProps {
  children: any;
  beforeImage?: any;
  img?: string;
  imgComponent?: any;
  imgStyle?: object;
  imgWrapStyle?: object;
}

const Slide: React.FC<SlideProps> = function ({
  children,
  img,
  imgComponent,
  imgStyle,
  imgWrapStyle,
  beforeImage,
}: SlideProps) {
  return (
    <GuestLayout
      showLogo={false}
      style={{
        height: `100%`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1,
        overflow: 'auto',
      }}
      allowToDrivers
    >
      {beforeImage}
      <ImageWrap style={imgWrapStyle}>
        {imgComponent || <Image style={imgStyle} alt="" src={img} />}
      </ImageWrap>
      <Container>{children}</Container>
    </GuestLayout>
  );
};

export default Slide;
