export interface Ride {
  id: number;
  provider_ride_id: string;
  start_lat: number;
  start_lng: number;
  end_lat: number;
  end_lng: number;
  online_time: number;
  pickup_time: number;
  end_time: number;
  min_estimated_earnings?: string;
  max_estimated_earnings?: string;
  estimated_earnings?: string;
  currency: string;
  start_address: string;
  end_address: string;
  distance: number;
  ride_status: RideStatusType;
  cost: number;
  is_booked: number;
  type: string | null;
}

export function formatEarnings(
  earnings: string | number,
  func: (n: number) => number = Math.round
) {
  if (typeof earnings === 'string') {
    return func(+earnings);
  }

  return func(earnings);
}
export function renderRidePrice(ride: Ride) {
  const { estimated_earnings, min_estimated_earnings, max_estimated_earnings } = ride;

  if (typeof estimated_earnings === 'string') {
    return `$${formatEarnings(estimated_earnings)}`;
  }

  let price = '';

  if (typeof min_estimated_earnings === 'string') {
    price += `$${formatEarnings(min_estimated_earnings)}`;
  }

  if (
    typeof max_estimated_earnings === 'string' &&
    max_estimated_earnings !== min_estimated_earnings
  ) {
    price += `-${formatEarnings(max_estimated_earnings)}`;
  }

  return price;
}

export const getRidePriceForDaySum = (ride: Ride): number => {
  const { estimated_earnings, min_estimated_earnings, max_estimated_earnings } = ride;

  if (typeof estimated_earnings === 'string' && parseInt(estimated_earnings, 10)) {
    return formatEarnings(estimated_earnings);
  }

  if (typeof max_estimated_earnings === 'string' && parseInt(max_estimated_earnings, 10)) {
    return formatEarnings(max_estimated_earnings);
  }

  if (typeof min_estimated_earnings === 'string' && parseInt(min_estimated_earnings, 10)) {
    return formatEarnings(min_estimated_earnings);
  }

  return 0;
};

export function isRide(arg: any): arg is Ride {
  return (
    arg &&
    arg.id !== undefined &&
    arg.start_lng !== undefined &&
    arg.end_lat !== undefined &&
    arg.end_lng !== undefined &&
    arg.online_time !== undefined &&
    arg.pickup_time !== undefined &&
    arg.end_time !== undefined &&
    arg.min_estimated_earnings !== undefined &&
    arg.max_estimated_earnings !== undefined &&
    arg.currency !== undefined &&
    arg.start_address !== undefined &&
    arg.distance !== undefined &&
    arg.cost !== undefined &&
    arg.type !== undefined
  );
}

export enum RideStatusType {
  New = 'new',
  Foreign = 'foreign',
  Waiting = 'waiting',
}
