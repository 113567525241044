import styled from 'styled-components';

export const TopHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;

export const HeaderTitle = styled.div`
  font-weight: bold;
  font-size: 19px;

  @media (max-width: 375px) {
    font-size: 17px;
  }

  flex: 4;
  text-align: center;
`;

interface DriverPhotoWrapProps {
  userPhoto: string;
}

export const DriverContainer = styled.div`
  flex: 0 0 49px;
  height: 46px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  right: 2px;
`;

export const DriverCredits = styled.div`
  padding: 0;
  text-align: center;
  position: absolute;
  top: 28px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100px;
`;

export const DriverPhotoWrap = styled.div<DriverPhotoWrapProps>`
  padding-right: 5px;
  padding-left: 5px;
  position: relative;

  .menu-icon {
    top: -5px;
    left: 20px;
    width: 22px;
    overflow: hidden;
    z-index: 5;
    position: absolute;
  }

  .driver-icon {
    height: 22px;
    cursor: pointer;
    background: url('${({ userPhoto }) => userPhoto}') no-repeat center center;
    background-size: cover;
    width: 22px;
    border-radius: 50%;
    z-index: 10;
    position: relative;
    box-shadow: 6px 0 5px -2px ${({ theme }) => theme.background};
    border: 1px solid ${({ theme }) => theme.background};
  }
`;
