import React, { useCallback, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

import { useTranslation } from 'helpers/hooks';
import { Help } from 'core/pages/help';
import urls from 'config/urls';
import { setHeaderTitle, toggleHelpScreen } from 'store/app/acions';
import { AppState } from 'store/interfaces';

import { Wizard } from '../../Wizard';

import { ContainerStyled, ContentStyled, FooterStyled, HeaderStyled } from './styled';
import BottomNavigation from './BottomNavigation';
import Sidebar from './Sidebar';
import Header from './Header';

const titlesMap: Map<keyof typeof urls | string, string> = new Map([
  [urls.rides, 'rides.title'],
  [urls.map, 'map.title'],
  [urls.settings, 'settings.title'],
  [urls.credits, 'credits.title'],
  [urls.creditsHistory, 'creditsHistory.title'],
  [urls.creditsRules, 'creditsRules.title'],
]);

const DriverLayout = function ({ children }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();

  const { user, headerTitle, isHelpScreen } = useSelector(
    (state: AppState) => ({
      user: state.auth.user,
      headerTitle: state.app.headerTitle,
      isHelpScreen: state.app.isHelpScreen,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(setHeaderTitle());
  }, []);

  const handlePressLink = useCallback(() => {
    if (headerTitle) {
      dispatch(setHeaderTitle());
    }
  }, [headerTitle, dispatch, setHeaderTitle]);

  const closeHelpScreen = useCallback(() => {
    dispatch(toggleHelpScreen());
  }, [dispatch, toggleHelpScreen]);

  if (!user) {
    return <Navigate to={urls.start} />;
  }

  const wizardStep = useSelector((app: AppState) => app.app.wizardStep, shallowEqual);

  if (wizardStep) {
    return <Wizard />;
  }

  return (
    <>
      <Sidebar />
      {isHelpScreen && <Help onClose={closeHelpScreen} />}
      <ContainerStyled isHided={isHelpScreen}>
        <HeaderStyled>
          <Header
            title={titlesMap.has(location.pathname) ? t(titlesMap.get(location.pathname)) : ''}
            subTitle={headerTitle}
            onPressBack={handlePressLink}
          />
        </HeaderStyled>
        <ContentStyled>{children}</ContentStyled>
        <FooterStyled>
          <BottomNavigation onHelpClick={closeHelpScreen} onPressLink={handlePressLink} />
        </FooterStyled>
      </ContainerStyled>
    </>
  );
};

export default DriverLayout;
