import React from 'react';

import { CarouselItem } from 'common/Carousel';
import { useTranslation } from 'helpers/hooks';

import { useToggleModalMap } from '../hooks';

export const AllDone = React.memo(function () {
  const { t } = useTranslation();
  const toggleModalsMap = useToggleModalMap();

  return (
    <CarouselItem
      onApply={() => {
        toggleModalsMap({ allDone: false });
      }}
      title={t('modals.recommendations.allDone.body')}
      applyText={t('modals.recommendations.allDone.applyButton')}
    />
  );
});
