import Rollbar from 'rollbar';

import storageService, { StorageKeys } from '../services/StorageService';

const getParams = () => {
  try {
    const params = storageService.getItem(StorageKeys.clientHeaders);

    if (!params) {
      return {};
    }

    return JSON.parse(params);
  } catch (e) {
    return {};
  }
};

// eslint-disable-next-line @typescript-eslint/ban-types
const transformer = (payload: object) => {
  Object.entries(getParams()).forEach(([key, value]) => {
    payload[key] = value;
  });
};

export const rollbarConfig: Rollbar.Configuration = {
  accessToken: '88b7b29add27413d8c576634e4104b7a',
  environment: window.location.origin.includes('localhost') ? 'development' : 'production',
  transform: transformer,
};

export const rollbar = new Rollbar(rollbarConfig);
