import React from 'react';

import Select from '../../../common/Select';
import { LoadingProp } from '../styled';

import { getSelectedByValue, hours } from './helper';
import { WorkHoursSelectProps } from './interfaces';

const timeOptions = Object.keys(hours).filter((hour) => parseInt(hour, 10) <= 24);

const WorkHoursSelect: React.FC<WorkHoursSelectProps> = function ({
  title,
  value,
  handleChange,
  isChanging,
  filterName,
}) {
  if (isChanging) {
    return <LoadingProp />;
  }

  let valueGroups = {
    hours: '>',
  };

  const selected = getSelectedByValue(value);

  if (selected) {
    valueGroups = {
      hours: selected,
    };
  }

  const options = timeOptions;
  const isNeedToAddValue = value && !timeOptions.find((v) => v === selected);

  return (
    <Select
      title={title}
      handleChange={handleChange}
      valueGroups={valueGroups}
      filterName={filterName}
      optionGroups={{
        hours: isNeedToAddValue ? [...options, selected] : options,
      }}
    />
  );
};

export default React.memo(WorkHoursSelect);
