import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { useTranslation } from 'helpers/hooks';
import { BackButton } from 'app/Layout/DriverLayout/styled';
import { CloseButton } from 'common/CloseButton';
import { helpVideoLink, fbLink, guidanceLink, faqLink } from 'config/urls';
import { appDep } from 'config/settings';

import { Button } from '../../../common/styled/form';
import GuestLayout from '../../../app/Layout/GuestLayout';
import OnBoarding from '../onboarding';
import { AppState } from '../../../store/interfaces';
import { Link } from '../../../common/Link';
import {
  registerHelpCreateRequestClickEvent,
  registerHelpFBClickEvent,
  registerGuidanceClickEvent,
  registerHelpStepByStepGuideClickEvent,
  registerHelpTutorialClickEvent,
  registerSidebarHelpClickEvent,
} from '../../../services/reactGa';
import { eventsService } from '../../../services/EventsService';

import {
  MainText,
  H2Text,
  ColorContainer,
  ContentContainer,
  MainContent,
  FooterContent,
  AltText,
} from './styled';

interface HelpProps {
  onClose(): void;
}

const buttonStyle = {
  width: 320,
  display: 'inline-block',
  padding: '10px 20px',
};

export const Help: React.FC<HelpProps> = function ({ onClose }: HelpProps) {
  const { t } = useTranslation();
  const { user } = useSelector((state: AppState) => state.auth, shallowEqual);

  const [isHelpSlides, setIsHelpSlides] = useState(false);
  const toggleHelpSlides = () => {
    if (!isHelpSlides) {
      registerHelpStepByStepGuideClickEvent();
    }

    setIsHelpSlides(!isHelpSlides);
  };

  const handleShowSupportModal = () => {
    registerHelpCreateRequestClickEvent();
    eventsService.showSupportModal();
  };

  if (isHelpSlides) {
    return <OnBoarding skipAtAnyTime onDoneClick={toggleHelpSlides} />;
  }

  return (
    <ColorContainer>
      <GuestLayout allowToDrivers showLogo={false}>
        <ContentContainer>
          <MainContent>
            {user?.user_id ? (
              <h1>{t('common.appName')}</h1>
            ) : (
              <div className="back-header">
                <BackButton onClick={onClose}>
                  <img style={{ height: 24 }} alt="left" src="/assets/images/left.png" />
                </BackButton>
                <h1>{t('common.appName')}</h1>
              </div>
            )}
            <H2Text>{t('help.title')}</H2Text>
            <MainText dangerouslySetInnerHTML={{ __html: t('help.content', { appDep }) }} />
            <MainText>{t('help.listOfResources')}</MainText>
            <MainText>
              <Link onClick={registerGuidanceClickEvent} href={guidanceLink}>
                <Button style={buttonStyle}>{t('help.guidance')}</Button>
              </Link>
            </MainText>
            <MainText>
              <Link onClick={registerHelpTutorialClickEvent} href={helpVideoLink}>
                <Button style={buttonStyle}>{t('help.videoTutorial')}</Button>
              </Link>
            </MainText>
            <MainText>
              <Button style={buttonStyle} onClick={toggleHelpSlides}>
                {t('help.helpSlider')}
              </Button>
            </MainText>
            <MainText>
              <Link onClick={registerSidebarHelpClickEvent} href={faqLink}>
                <Button style={buttonStyle}>{t('help.faq')}</Button>
              </Link>
            </MainText>
            <MainText>
              <Link onClick={registerHelpFBClickEvent} href={fbLink}>
                <Button style={buttonStyle}>{t('help.fb')}</Button>
              </Link>
              <div style={{ marginTop: 12 }}>{t('help.fbDescription')}</div>
            </MainText>
          </MainContent>
          <FooterContent>
            <MainText>
              <AltText>{t('help.needHelp')}</AltText>
              <Link onClick={handleShowSupportModal}>
                <Button
                  style={{
                    ...buttonStyle,
                    width: 180,
                  }}
                >
                  {t('help.helpDesk')}
                </Button>
              </Link>
            </MainText>
            {user?.user_id && (
              <MainText style={{ marginTop: 10 }}>
                {t('common.skeddyId')}: {user?.user_id}
              </MainText>
            )}
          </FooterContent>
        </ContentContainer>
        {user?.user_id && <CloseButton onClick={onClose} />}
      </GuestLayout>
    </ColorContainer>
  );
};
