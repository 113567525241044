import React, { useCallback } from 'react';
import { ReactSVG } from 'react-svg';
import styled, { css } from 'styled-components';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'helpers/hooks';

import { AppState } from '../../../../store/interfaces';
import { toggleTheme } from '../../../../store/app/acions';
import { registerThemeEvent } from '../../../../services/reactGa';

interface ContainerInterface {
  showBlack: boolean;
}

const Container = styled.div<ContainerInterface>`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  letter-spacing: -0.3px;
  line-height: 130%;

  ${({ showBlack }) => {
    if (showBlack) {
      return css`
        path {
          fill: white;
          fill-rule: nonzero;
        }
      `;
    }

    return css`
      path {
        fill: black;
        fill-rule: nonzero;
      }
    `;
  }}

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const ThemeSwitcher = function () {
  const { theme } = useSelector((app: AppState) => app.app, shallowEqual);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const toggle = useCallback(() => {
    dispatch(toggleTheme());
    registerThemeEvent(!theme);
  }, [theme, dispatch, toggleTheme]);

  return (
    <Container showBlack={theme} onClick={toggle}>
      <ReactSVG wrapper="span" src="/assets/sidebar/day_night.svg" />
      <span>
        {t('theme.dark')}/{t('theme.light')}
      </span>
    </Container>
  );
};
