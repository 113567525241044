import moment from 'moment';

import { TimeSelectValues } from './interfaces';

export const hoursOptions: string[] = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
];

export const minutesOptions: string[] = [];

for (let i = 0; i < 6; i++) {
  for (let j = 0; j < 10; j++) {
    minutesOptions.push(`${i}${j}`);
  }
}

export const getHourBySeconds = (seconds: number): TimeSelectValues => {
  const time = moment.utc(seconds * 1000);

  return {
    hoursOptions: time.format('hh'),
    minutesOptions: time.format('mm'),
    dayOptions: time.format('A'),
  };
};

export const transformToSeconds = (values: TimeSelectValues): number => {
  return moment(
    `${values.hoursOptions} ${values.minutesOptions} ${values.dayOptions}`,
    'hh mm A'
  ).diff(moment().startOf('day'), 'seconds');
};
