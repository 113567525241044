import styled, { css } from 'styled-components';

import { ButtonStyled } from '../../../common/styled/form';

interface RideContainerProps {
  isNew: boolean;
}
export const RideContainer = styled.div<RideContainerProps>`
  display: flex;
  flex-direction: row;

  ${({ isNew, theme }) => {
    if (isNew) {
      return css`
        color: ${theme.rides.new};
        font-weight: 900 !important;
      `;
    }
    return css`
      color: ${theme.rides.text};
      font-weight: 200;
    `;
  }}

  &:nth-child(even) {
    background: ${({ theme }) => theme.grid.evenRow};
  }

  &:nth-child(odd) {
    background: ${({ theme }) => theme.grid.oddRow};
  }

  :last-child {
    border: none;
  }
`;

export const DANGER = 'danger';
export const WARNING = 'warning';
export const SUCCESS = 'success';
export const UNKNOWN = undefined;

interface RideTimeProps {
  status?: string;
  isEven: boolean;
}

export const RideTime = styled.div<RideTimeProps>`
  padding: 5px;
  font-size: 14px;
  flex: 0 0 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background: ${({ status, isEven, theme }) =>
    status ? theme.status[status] : isEven ? theme.grid.evenRow : theme.grid.oddRow};
  text-align: center;
}
`;

export const RideDetails = styled.div`
  flex: 1;
  padding: 6px 10px 6px 0;
  font-size: 14px;
  overflow: hidden;

  div:first-child {
    margin-bottom: 8px;
  }

  cursor: pointer;
`;

export const DropContainer = styled.div`
  flex: 0 0 62px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  ${ButtonStyled} {
    padding: 6px 10px;
    color: ${({ theme }) => theme.rides.drop.color};
    background: ${({ theme }) => theme.rides.drop.background};
  }
`;

export const RideSummary = styled.div`
  padding: 6px 0 6px 10px;
  flex: 0 0 68px;
  display: flex;
  font-size: 13px;
  letter-spacing: -0.4px;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;

  @media (max-width: 340px) {
    flex: 0 0 40px;
    font-size: 13px;
  }
`;

export const SummaryPrice = styled.span`
  font-weight: bold;
  font-size: 15px;
  color: ${({ theme }) => theme.rides.money};
  flex: 0 0 78px;
  padding-left: 10px;
  display: flex;
  align-items: center;

  @media (max-width: 340px) {
    font-size: 15px;
    flex: 0 0 40px;
  }
`;

export const TotalSummaryPrice = styled.span`
  font-weight: bold;
  font-size: 17px;
  color: ${({ theme }) => theme.rides.money};
  padding-left: 10px;
  display: flex;
  align-items: center;
`;

export const RideType = styled.div``;

export const SummaryLength = styled.div`
  font-size: 15px;

  .mi {
    margin-left: 2px;
    font-size: 13px;
  }

  .unknown {
    color: #ff0000;
  }

  @media (max-width: 375px) {
    font-size: 13px;
  }
`;

export const RideAddress = styled.div`
  font-size: 14px;

  display: flex;
  align-items: center;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

interface RideTopRowProps {
  status?: string;
  isNew: boolean;
}

export const RideTopRow = styled.div<RideTopRowProps>`
  height: 30px;
  display: flex;
  align-items: stretch;
  font-size: 15px;

  ${({ isNew, theme }) => {
    if (isNew) {
      return css`
        color: ${theme.rides.new};
        font-weight: 900 !important;
      `;
    }
    return css`
      color: ${theme.rides.text};
      font-weight: 200;
    `;
  }}

  &:nth-child(even) {
    background: ${({ status, theme }) => (status ? theme.status[status] : theme.grid.evenRow)};
  }

  &:nth-child(odd) {
    background: ${({ status, theme }) => (status ? theme.status[status] : theme.grid.oddRow)};
  }

  @media (max-width: 375px) {
    font-size: 14px;
  }
`;

export const TimeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  padding-right: 10px;
  letter-spacing: -0.3px;

  @media (max-width: 360px) {
    font-size: 12px;
  }
`;

export const RideCredits = styled.div`
  flex: 0 0 70px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 13px;

  @media (max-width: 360px) {
    font-size: 12px;
  }

  .free {
    color: ${(props) => props.theme.rides.free};
  }
`;
