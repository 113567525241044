import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import styled from 'styled-components';

import { useTranslation } from '../../../helpers/hooks';
import GuestLayout from '../../../app/Layout/GuestLayout';
import { Link } from '../../../common/Link';
import { AppState, PermissionStatus } from '../../../store/interfaces';
import { CarouselItem, SkeddyImage } from '../../../common/Carousel';
import { Hint } from '../../../app/Wizard/styled';
import { nativeMessageService } from '../../../services/NativeMessageService';
import { ColorContainer } from '../help/styled';
import { sendEvent } from '../../../services/reactGa';
import { isIos } from '../../../helpers/os';

const BodyWrapper = styled.div`
  color: ${(props) => props.theme.textColor};
`;

export const MainContainer = styled.div`
  > div {
    padding: 20px;
    font-size: 22px !important;

    > button {
      font-size: 20px !important;
    }
  }
`;

const styles = {
  title: {
    marginBottom: 20,
  },
  body: {
    marginBottom: 30,
  },
  button: {
    marginBottom: 50,
  },
};

const BatteryPermission: React.FC = React.memo(() => {
  const { t } = useTranslation();

  const { permissions } = useSelector((app: AppState) => app.settingsRecommendations, shallowEqual);

  const [showPage, setShowPage] = useState(() => {
    if (permissions.battery === PermissionStatus.Undefined) {
      return false;
    }

    return permissions.battery !== PermissionStatus.Always;
  });

  useEffect(() => {
    if (permissions.battery === PermissionStatus.Undefined) {
      return;
    }

    setShowPage(permissions.battery !== PermissionStatus.Always);
  }, [permissions.battery]);

  if (isIos) {
    return null;
  }

  if (permissions.battery === PermissionStatus.Undefined) {
    return null;
  }

  if (!showPage) {
    return null;
  }

  return (
    <ColorContainer style={{ zIndex: 1000, top: 0 }}>
      <GuestLayout style={{ backgroundSize: '25vh auto' }} showLogo={false} allowToDrivers>
        <MainContainer>
          <CarouselItem
            title={t('batteryPermission.title')}
            onApply={() => {
              sendEvent('BatteryPage_GoToSettingsClick');
              nativeMessageService.sendRequestPermissionMessage('battery');
            }}
            applyText={t('batteryPermission.goToSettings')}
            showImage={false}
            bodyWrapper={BodyWrapper}
            styles={styles}
            hint={
              <>
                <SkeddyImage />
                <Hint>
                  {t('batteryPermission.hint')}{' '}
                  <Link
                    target="_blank"
                    onClick={() => sendEvent('BatteryPage_ZendeskClick')}
                    href="https://skeddy.zendesk.com/hc/en-us/articles/20552666852503"
                    color="#FF00ED"
                  >
                    {t('batteryPermission.here')}
                  </Link>
                  .
                </Hint>
              </>
            }
          >
            {t('batteryPermission.body')}
          </CarouselItem>
        </MainContainer>
      </GuestLayout>
    </ColorContainer>
  );
});

export default BatteryPermission;
