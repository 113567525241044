import React from 'react';

import { CarouselItem } from '../../../common/Carousel';
import { useTranslation } from '../../../helpers/hooks';
import { StepProps } from '../interfaces';
import { AltBody, Body, StepBody } from '../styled';

export const AllDone: React.FC<StepProps> = function ({ setNextWizardStep }) {
  const { t } = useTranslation();

  return (
    <CarouselItem
      bodyWrapper={StepBody}
      onApply={setNextWizardStep}
      applyText={t('settings.wizard.allDone.button')}
    >
      <Body dangerouslySetInnerHTML={{ __html: t('settings.wizard.allDone.body') }} />
      <AltBody dangerouslySetInnerHTML={{ __html: t('settings.wizard.allDone.body1') }} />
    </CarouselItem>
  );
};
