function wait(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function importRetry(importFn, retries = 2, interval = 1000) {
  try {
    return await importFn();
  } catch (error) {
    if (retries) {
      await wait(interval);
      return importRetry(importFn, retries - 1, interval);
    }
    throw new Error(error);
  }
}
