import React, { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { AppState, SettingsRecommendations } from 'store/interfaces';
import { Carousel } from 'common/Carousel';

import { Intro } from './steps/Intro';
import { Search } from './steps/Search';
import { NoCredits } from './steps/NoCredits';
import { LocationPermission } from './steps/LocationPermission';
import { PushPermission } from './steps/PushPermission';
import { MinimumRidePayout } from './steps/MinimumRidePayout';
import { MaxMilesToPickup } from './steps/MaxMilesToPickup';
import { AllDone } from './steps/AllDone';
import { Done } from './steps/Done';

const StepsMap: Map<keyof SettingsRecommendations, React.FC> = new Map([
  ['intro', Intro],
  ['search', Search],
  ['searchSuccess', Done],
  ['noCredits', NoCredits],
  // ['autoAdjustSearchPins'],
  // ['autoAdjustSearchPinsSuccess'],
  ['minimumRidePayout', MinimumRidePayout],
  ['minimumRidePayoutSuccess', Done],
  ['maxMilesToPickup', MaxMilesToPickup],
  ['maxMilesToPickupSuccess', Done],
  ['locationPermission', LocationPermission],
  ['pushPermission', PushPermission],
  ['allDone', AllDone],
]);

export const SettingsRecommendationsCarousel = React.memo(() => {
  const recommendations = useSelector(
    (app: AppState) => app.settingsRecommendations.recommendations,
    shallowEqual
  );

  const { StepComponent, stepKey } = useMemo(() => {
    const stepKey = (Object.keys(recommendations) as Array<keyof SettingsRecommendations>).find(
      (key) => recommendations[key]
    );

    return {
      StepComponent: stepKey && StepsMap.has(stepKey) ? StepsMap.get(stepKey) : null,
      stepKey,
    };
  }, [recommendations]);

  if (StepComponent) {
    return (
      <Carousel zIndex={100000} isBigLogo={stepKey === 'intro'}>
        <StepComponent />
      </Carousel>
    );
  }

  return null;
});
