import React from 'react';

import { sendEvent } from '../../../services/reactGa';
import { settingsRecommendationsService } from '../../../services/SettingsRecommendationsService';
import { useTranslation } from '../../../helpers/hooks';
import { CarouselItem } from '../../../common/Carousel';
import { appDep } from '../../../config/settings';
import { useToggleModalMap } from '../hooks';

export const LocationPermission = React.memo(() => {
  const toggleModalsMap = useToggleModalMap();
  const { t } = useTranslation();

  return (
    <CarouselItem
      onMount={() => sendEvent('DriverRecommendations_LocationPermissionRequest_Show')}
      onClose={() => {
        toggleModalsMap({ locationPermission: false });
        sendEvent('DriverRecommendations_LocationPermissionRequest_RemindLater');
      }}
      onApply={() => {
        sendEvent('DriverRecommendations_LocationPermissionRequest_Apply');
        settingsRecommendationsService.askForPermission('location');
        toggleModalsMap({
          locationPermission: false,
        });
      }}
      applyText={t('modals.recommendations.locationPermission.applyButton')}
      cancelText={t('modals.recommendations.locationPermission.cancelButton')}
    >
      <div
        dangerouslySetInnerHTML={{
          __html: t('modals.recommendations.locationPermission.body', { appDep }),
        }}
      />
    </CarouselItem>
  );
});
