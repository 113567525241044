import { User } from '../types/user';

import { isDebugEnabled } from './isDebugEnables';

export const isTestUser = (user: User | null): boolean => {
  if (isDebugEnabled()) {
    return true;
  }

  if (!user) {
    return false;
  }

  return user.user_id === 1548;
};
