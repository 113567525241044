import React from 'react';

import { CarouselItem } from '../../../common/Carousel';
import { useTranslation } from '../../../helpers/hooks';
import { StepProps } from '../interfaces';
import { Body, StepBody } from '../styled';

export const Intro: React.FC<StepProps> = function ({ setNextWizardStep }) {
  const { t } = useTranslation();

  return (
    <CarouselItem
      onApply={setNextWizardStep}
      title={t('settings.wizard.intro.title')}
      applyText={t('settings.wizard.intro.button')}
      bodyWrapper={StepBody}
    >
      <Body dangerouslySetInnerHTML={{ __html: t('settings.wizard.intro.body') }} />
    </CarouselItem>
  );
};
