import React, { useEffect, useState } from 'react';

import Modal from 'common/Modal';
import { Events, eventsService, ShowInfoModalEvent } from 'services/EventsService';

type ModalData = {
  title: string;
  body: string;
} | null;

export const InfoModal: React.FC = React.memo(() => {
  const [modalData, setModalData] = useState<ModalData>(null);

  useEffect(() => {
    const handleShowInfoModal = (event: ShowInfoModalEvent) => {
      const { title, body } = event.detail;

      setModalData({
        title,
        body,
      });
    };

    eventsService.on(Events.ShowInfoModal, handleShowInfoModal);

    return () => {
      eventsService.off(Events.ShowInfoModal, handleShowInfoModal);
    };
  }, []);

  const handleClose = () => setModalData(null);

  if (!modalData) {
    return null;
  }

  return (
    <Modal title={modalData.title} position="bottom" onClose={handleClose}>
      <div dangerouslySetInnerHTML={{ __html: modalData.body }} />
    </Modal>
  );
});
