import React from 'react';

import { useTranslation } from 'helpers/hooks';

interface LanguageTextProps {
  children: any;
  language: string;
}

export const LanguageText: React.FC<LanguageTextProps> = function ({ language, children }) {
  const { i18n } = useTranslation();
  const { language: currentLanguage } = i18n;

  if (currentLanguage !== language) {
    return null;
  }

  return children;
};
