import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react';
import { ThemeContext } from 'styled-components';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { nativeMessageService } from '../services/NativeMessageService';
import {
  actionCreators,
  getSearchStatus,
  setBackgroundLocation,
  setPurchaseProducts,
  validatePurchaseProduct,
} from '../store/app/acions';
import {
  Events,
  SetPurchaseProductsEvent,
  SetSearchStatusEvent,
  SetUserEvent,
  ValidatePurchaseEvent,
  eventsService,
  SetBackgroundLocationEvent,
  SetPermissionStatusEvent,
} from '../services/EventsService';
import { setUser } from '../store/user/actions';
import { AppState } from '../store/interfaces';
import storageService, { StorageKeys } from '../services/StorageService';
import { sendPageview } from '../services/reactGa';
import { singularClient } from '../services/SingularClient';
import i18n, { allowedLanguages } from '../i18n';
import { getRecommendations, setPermissionStatus } from '../store/settingsRecommendations/acions';
import { config } from '../services/Config';

const handleChangeBrowserLanguage = () => {
  const language = window.navigator.language.slice(0, 2);

  if (allowedLanguages.indexOf(language) !== -1 && language !== i18n.language) {
    i18n.changeLanguage(language).then();
  }
};

export const AppEvents = React.memo(() => {
  const user = useSelector((state: AppState) => state.auth.user, shallowEqual);

  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const isAllowedSingular = useRef(false);

  const location = useLocation();

  useEffect(() => {
    if (isAllowedSingular.current) {
      singularClient.pageVisit();
    } else {
      isAllowedSingular.current = true;
    }
    sendPageview(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    nativeMessageService.sendThemeMessage(themeContext.background);
  }, [themeContext.background]);

  useEffect(() => {
    const handleUpdateCreditsMessage = () => {
      dispatch(getSearchStatus.request());
    };

    const setSearchStatus = (event: SetSearchStatusEvent) => {
      const { status } = event.detail;
      dispatch(actionCreators.setSearchStatus.request(status));
    };

    const handleValidatePurchase = (event: ValidatePurchaseEvent) => {
      const { purchase } = event.detail;
      dispatch(validatePurchaseProduct(purchase));
    };

    const handleSetProducts = (event: SetPurchaseProductsEvent) => {
      dispatch(setPurchaseProducts(event.detail.products));
    };

    const handleSetUser = (event: SetUserEvent) => {
      const { user } = event.detail;
      dispatch(setUser(user));
    };

    const handleSetBackgroundLocation = (event: SetBackgroundLocationEvent) => {
      const { lat, lng } = event.detail;
      dispatch(setBackgroundLocation({ lat, lng }));
    };

    const handleSetPermissionStatus = (event: SetPermissionStatusEvent) => {
      const { permission, status } = event.detail;
      dispatch(
        setPermissionStatus({
          permission,
          status,
        })
      );
    };

    const handleVisibilityChanged = () => {
      const isVisible = document.visibilityState === 'visible';
      eventsService.triggerAppVisible(isVisible);

      handleChangeBrowserLanguage();

      if (!isVisible) {
        return;
      }

      if (user) {
        dispatch(getSearchStatus.request());
        dispatch(getRecommendations());
      }

      config.load();
    };

    window.addEventListener('languagechange', handleChangeBrowserLanguage);
    document.addEventListener('visibilitychange', handleVisibilityChanged);

    eventsService.addEventListeners();
    eventsService.on(Events.SetBackgroundLocation, handleSetBackgroundLocation);
    eventsService.on(Events.SetUser, handleSetUser);
    eventsService.on(Events.SetPurchaseProducts, handleSetProducts);
    eventsService.on(Events.ValidatePurchase, handleValidatePurchase);
    eventsService.on(Events.SetSearchStatus, setSearchStatus);
    eventsService.on(Events.SetPermissionStatus, handleSetPermissionStatus);
    eventsService.on(Events.UpdateCreditsMessage, handleUpdateCreditsMessage);

    return () => {
      window.removeEventListener('languagechange', handleChangeBrowserLanguage);
      document.removeEventListener('visibilitychange', handleVisibilityChanged);

      eventsService.removeEventListeners();
      eventsService.off(Events.SetSearchStatus, setSearchStatus);
      eventsService.off(Events.SetUser, handleSetUser);
      eventsService.off(Events.SetPurchaseProducts, handleSetProducts);
      eventsService.off(Events.ValidatePurchase, handleValidatePurchase);
      eventsService.off(Events.UpdateCreditsMessage, handleUpdateCreditsMessage);
      eventsService.off(Events.SetBackgroundLocation, handleSetBackgroundLocation);
      eventsService.off(Events.SetPermissionStatus, handleSetPermissionStatus);
    };
  }, []);

  useLayoutEffect(() => {
    if (user && !storageService.has(StorageKeys.isRegistered)) {
      storageService.setItem(StorageKeys.isRegistered, '1');
    }

    nativeMessageService.sendUserMessage(user || '');
  }, [user]);

  return null;
});
