import { fork, put, call, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import API from 'api';
import { actionCreators } from 'store/creditsHistory/actions';

import { messages } from '../../i18n';
import { logout } from '../user/actions';

function* getWeekHistory({ payload }) {
  const { week, year } = payload;
  const response = yield call(API.purchases.list, { week: week < 10 ? `0${week}` : week, year });

  switch (response.code) {
    case 200: {
      yield put(actionCreators.getCreditsHistory.success(response.items));
      break;
    }
    case 401: {
      toast.error(messages.sessionTimeout);
      yield put(logout());
      break;
    }
    default: {
      yield put(actionCreators.getCreditsHistory.failure());
      console.log(response);
    }
  }
}

export function* watchGetWeekHistory() {
  yield takeLatest(actionCreators.getCreditsHistory.REQUEST, getWeekHistory);
}

export default function* flow() {
  yield fork(watchGetWeekHistory);
}
