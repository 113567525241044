import styled from 'styled-components';

import { Loading } from '../../common/styled/form';

export const WizardLink = styled.div`
  padding: 18px 10px;
  opacity: 0.95;
  background: ${(props) => props.theme.bodyBackground};
  text-align: center;

  > span {
    text-decoration: underline;
    text-decoration-color: #e728e0;
    display: inline-flex;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
  }

  img {
    margin-left: 8px;
    max-height: 22px;
  }
`;

export const FilterContainer = styled.div`
  flex: 0 0 auto;
  margin-top: 0;

  svg {
    width: 1.3em;
    height: 1.3em;
    margin-left: 20px;

    path {
      fill: ${(props) => props.theme.settings.info};
    }
  }
`;

export const LoadingProp = styled(Loading)`
  display: block;
`;

export const SelectedPin = styled.span`
  color: ${(props) => props.theme.settings.selected};
  flex: 0 0 47px;
  text-align: center;
`;

export const SettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const SettingsPinsContainer = styled.div`
  flex: 1 0 auto;

  display: flex;
  flex-direction: column;

  @media (min-height: 500px) {
    max-height: calc(100% - 304px);
  }
`;

export const MarkersContainer = styled.div`
  overflow: scroll;
`;

export const ButtonContainer = styled.div`
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  background: ${(props) => props.theme.grid.rowTitle};
`;
