import styled from 'styled-components';

export const PickerModalContainer = styled.div`
  margin: 0;
  padding: 0;
  position: relative;
  bottom: 0;
  z-index: 10000;
`;

export const PickerModalMask = styled.div`
   {
    top: 0;
    right: 0;
    z-index: 10;
    height: 100%;
    background: ${(props) => props.theme.modal.overlay};
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;

    animation-name: animate-select-background;
    animation-duration: 0.4s;

    @keyframes animate-select-background {
      from {
        background: none;
      }
      to {
        background: ${(props) => props.theme.modal.overlay};
      }
    }
  }
`;

export const PickerHeader = styled.header`
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  background-color: ${(props) => props.theme.select.headerBackground};
  justify-content: space-between;
`;

export const PickerModalTitle = styled.span`
  color: ${(props) => props.theme.select.headerTextColor};
  margin-left: 15px;
`;

export const PickerApply = styled.div`
  background: #00b050;
  color: #fff;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
`;

export const PickerModal = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 20;
  background-color: ${(props) => props.theme.select.background};
  -webkit-transform: translateY(100%);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform 0.3s, -webkit-transform 0.3s;
  transform: translate(0);

  animation-name: animate-select;
  animation-duration: 0.4s;

  @keyframes animate-select {
    from {
      opacity: 0;
      bottom: -50%;
    }
    to {
      opacity: 1;
      bottom: 0;
    }
  }

  .picker-container .picker-column .picker-item.picker-item-selected {
    color: ${(props) => props.theme.textColor};
  }
`;
