import { singularSdk, SingularConfig } from 'singular-sdk';

import { createDebug } from '../helpers/debug';

const apiKey = 'skeddy_d11b222b';
const secretKey = '5d3080cb5622af163a9b5496dec433df';
const productId = window.location.hostname.split('.').reverse().join('.');

const debug = createDebug.extend('singularClient');

class SingularClient {
  constructor() {
    const config = new SingularConfig(apiKey, secretKey, productId).withInitFinishedCallback(
      (initParams) => {
        // This callback will be called when the init method has finished

        // You can get Singular Device id here
        const { singularDeviceId } = initParams;

        debug({ singularDeviceId });
      }
    );
    singularSdk.init(config);
  }

  login(userId) {
    try {
      singularSdk.login(userId);
    } catch (e) {
      console.log(e);
    }
  }

  logout() {
    try {
      singularSdk.logout();
    } catch (e) {
      console.log(e);
    }
  }

  sendEvent(eventName: string, params = undefined) {
    try {
      singularSdk.event(eventName, params);
    } catch (e) {
      console.log(e);
    }
  }

  pageVisit() {
    try {
      singularSdk.pageVisit();
    } catch (e) {
      console.log(e);
    }
  }
}

export const singularClient = new SingularClient();
