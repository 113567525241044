import { DefaultTheme } from 'styled-components';

export const lightTheme: DefaultTheme = {
  name: 'light',

  bodyBackground: '#F7B731',
  bodyBackgroundAlt: '#fff',
  background: '#F7B731',
  textColor: '#000',
  pink: '#ff00ed',

  addRide: {
    background: '#fce3b2',
    inputBackground: '#fff',
  },

  grid: {
    rowTitle: '#FCE3B2',
    evenRow: '#ffffff',
    oddRow: '#F2F2F2',
    secondColumn: '#696969',
  },

  rides: {
    showAll: 'rgb(0, 136, 0)',
    new: '#000',
    text: '#3d3d3d',
    money: '#C1362C',
    drop: {
      background: '#C1362C',
      color: '#fff',
    },
    free: '#C1362C',
    emptyImportantTitle: '#C1362C',
    emptyImportantButton: '#C1362C',

    ridesCredits: {
      successBackground: '#C5E0B4',
      dangerBackground: 'red',
      background: '#FCE3B2',
      danger: '#fff',
    },
  },

  filters: {
    value: '#696969',
    danger: '#C1362C',
  },

  footer: {
    link: '#252726',
    activeLink: '#fff',
  },

  sidebar: {
    background: 'rgb(252, 227, 178)',
    link: '#333333',
    creditsBackground: '#F7B731',
  },

  modal: {
    overlay: 'rgba(0, 0, 0, 0.6)',
    background: '#fbf9fe',
  },

  select: {
    headerTextColor: '#888',
    headerBackground: '#fff',
    background: '#fbf9fe',
  },

  tabs: {
    active: '#fce3b2',
    activeText: '#000',
    default: '#F7B731',
    defaultText: '#F2F2F2',
    secondBackground: '#fce3b2',
  },

  creditsHistory: {
    tableHeader: '#fce3b2',
    evenRow: '#ffffff',
    oddRow: '#F2F2F2',
    tableBorder: '#ddd',
    legend: {
      title: '#00',
      description: '#',
    },
  },

  buttonBackground: '#fff',

  status: {
    danger: '#FCAAD0',
    warning: '#FBD68D',
    success: '#C5E0B4',
  },

  map: {
    pinDetails: {
      background: '#f3f7fa',
    },
    routes: {
      default: '#1500FF',
      selected: '#ff0000',
    },
    route: '#1500FF',
    background: '#fff',
    copy: {
      background: '#06AF50',
      color: '#fff',
    },
    skip: {
      background: '#ff0000',
      color: '#fff',
    },
    ride: {
      start: '#06AF50',
      finish: '#e85951',
    },
    closePinDetails: '#e933e5',
    copied: {
      background: '#fff',
      color: '#06AF50',
    },
    pin: {
      apply: {
        background: '#579927',
        color: '#fff',
      },
      background: '#fff',
      color: '#000',
      time: '#579927',
      money: '#C1362C',
    },
    clusters: {
      big: {
        opacity: 0.2,
        color: '#f98262',
      },
      medium: {
        opacity: 0.2,
        color: '#fdc9b2',
      },
      small: {
        opacity: 0.2,
        color: '#fedbaa',
      },
    },
  },

  input: {
    error: 'red',
    background: '#fbe4b2',
    placeholder: '#707176',
  },

  settings: {
    selected: 'rgb(0, 175, 80)',
    delete: '#C1362B',
    info: '#000',
  },

  inputError: 'red',

  inputBackground: '#fbe4b2',

  altText: '#fff',

  backgroundLogo: '/assets/icons/logo.svg',
};

export const darkTheme: DefaultTheme = {
  name: 'dark',

  bodyBackground: '#191919',
  bodyBackgroundAlt: '#191919',
  background: '#191919',
  textColor: '#dcdcdc',
  pink: '#ff00ed',

  addRide: {
    background: '#404040',
    inputBackground: '#dcdcdc',
  },

  grid: {
    rowTitle: '#202020',
    evenRow: '#404040',
    oddRow: '#3B3B3B',
    secondColumn: '#faff00',
  },

  rides: {
    showAll: '#59c538',
    new: '#fff',
    text: '#dcdcdc',
    money: '#faff00',
    drop: {
      background: '#C1362C',
      color: '#dcdcdc',
    },
    free: '#faff00',
    emptyImportantTitle: '#C1362C',
    emptyImportantButton: '#C1362C',

    ridesCredits: {
      successBackground: '#2D5F1E',
      dangerBackground: 'red',
      background: '#404040',
      danger: '#fff',
    },
  },

  filters: {
    value: '#d8d8d8',
    danger: '#C1362C',
  },

  footer: {
    link: '#808184',
    activeLink: '#FEFEFE',
  },

  sidebar: {
    background: '#202020',
    link: '#dcdcdc',
    creditsBackground: '#595959',
  },

  modal: {
    overlay: 'rgba(0, 0, 0, 0.8)',
    background: '#404040',
  },

  select: {
    headerTextColor: '#dcdcdc',
    headerBackground: '#595959',
    background: '#202020',
  },

  tabs: {
    active: '#696969',
    activeText: '#dcdcdc',
    default: '#191919',
    defaultText: '#808184',
    secondBackground: '#343434',
  },

  creditsHistory: {
    tableHeader: '#696969',
    evenRow: '#191919',
    oddRow: '#404040',
    tableBorder: '#696969',
    legend: {
      title: '#404040',
      description: '#191919',
    },
  },

  buttonBackground: '#C09001',

  status: {
    danger: '#ab185c',
    warning: '#9B7930',
    success: '#2D5F1E',
  },

  map: {
    route: '#faff00',
    background: '#191919',
    pinDetails: {
      background: '#202020',
    },
    routes: {
      default: '#faff00',
      selected: '#ff0000',
    },
    copy: {
      background: '#06AF50',
      color: '#dcdcdc',
    },
    skip: {
      background: '#ff0000',
      color: '#fff',
    },
    ride: {
      start: '#06AF50',
      finish: '#dd6059',
    },
    closePinDetails: '#e933e5',
    copied: {
      background: '#dcdcdc',
      color: '#06AF50',
    },
    pin: {
      apply: {
        background: '#579927',
        color: '#dcdcdc',
      },
      background: '#191919',
      color: '#dcdcdc',
      time: '#579927',
      money: '#C1362C',
    },
    clusters: {
      big: {
        opacity: 0.15,
        color: '#f98262',
      },
      medium: {
        opacity: 0.1,
        color: '#fdc9b2',
      },
      small: {
        opacity: 0.15,
        color: '#fedbaa',
      },
    },
  },

  input: {
    error: 'red',
    background: '#808184',
    placeholder: '#202020',
  },

  settings: {
    selected: 'rgb(0 255 117)',
    delete: '#ff0000',
    info: '#fff',
  },

  altText: '#C09001',

  inputError: 'red',

  inputBackground: '#808184',

  backgroundLogo: '/assets/icons/logo_night.svg',
};
