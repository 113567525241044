import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { sendEvent } from '../../../services/reactGa';
import { eventsService } from '../../../services/EventsService';
import { AppState } from '../../../store/interfaces';
import { useTranslation } from '../../../helpers/hooks';
import { CarouselItem } from '../../../common/Carousel';
import { useToggleModalMap } from '../hooks';
import { Text } from '../styled';

export const Search = React.memo(function () {
  const rideCredits = useSelector((app: AppState) => app.app.rideCredits, shallowEqual);
  const toggleModalsMap = useToggleModalMap();
  const { t } = useTranslation();

  return (
    <CarouselItem
      onMount={() => sendEvent('DriverRecommendations_Search_Show')}
      onClose={() => {
        toggleModalsMap({ search: false });
        sendEvent('DriverRecommendations_Search_RemindLater');
      }}
      onApply={() => {
        if (rideCredits === null) {
          return;
        }

        if (rideCredits > 0) {
          eventsService.searchStart();
          sendEvent('DriverRecommendations_Search_Apply');
          toggleModalsMap({
            search: false,
            searchSuccess: true,
          });
          return;
        }

        sendEvent('DriverRecommendations_NoCredits_Show');
        toggleModalsMap({
          search: false,
          noCredits: true,
        });
      }}
      applyText={t('search.stoppedSearchModal.yes')}
      cancelText={t('search.stoppedSearchModal.no')}
    >
      <Text>{t('search.stoppedSearchModal.body1')}</Text>
      <p dangerouslySetInnerHTML={{ __html: t('search.stoppedSearchModal.body2') }} />
    </CarouselItem>
  );
});
