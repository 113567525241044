import React, { useEffect, useState } from 'react';

import storageService, { StorageKeys } from '../../services/StorageService';
import Modal from '../Modal';
import { Events, eventsService } from '../../services/EventsService';

interface AcknowledgeModalProps {
  title?: any;
  applyText: any;
  storageKey?: StorageKeys;
  children: any;
  closeOnMask?: boolean;
  showCancel?: boolean;
  handleOnApply?(): void;
  handleOnCancel?(): void;
  checkIsAllowedToShow?(): boolean;
  cancelText?: string;
  showEventName?: Events;
  onShow?(): void;
  defaultShow?: boolean;
  reverseButtons?: boolean;
}

export const AcknowledgeModal: React.FC<AcknowledgeModalProps> = React.memo(
  ({
    title,
    storageKey,
    children,
    applyText,
    closeOnMask = false,
    showCancel = false,
    handleOnApply,
    handleOnCancel,
    checkIsAllowedToShow,
    cancelText,
    showEventName,
    onShow,
    defaultShow = true,
    reverseButtons = false,
  }) => {
    const [showModal, setShowModal] = useState(defaultShow);

    useEffect(() => {
      if (!showEventName) {
        return;
      }

      const handleShowModal = () => setShowModal(true);

      eventsService.on(showEventName, handleShowModal);

      return () => {
        eventsService.off(showEventName, handleShowModal);
      };
    }, []);

    if (storageKey && storageService.getItem(storageKey)) {
      return null;
    }

    if (!showModal) {
      return null;
    }

    if (checkIsAllowedToShow && !checkIsAllowedToShow()) {
      return null;
    }

    const onClose = () => {
      if (handleOnCancel) {
        handleOnCancel();
      }

      if (onShow) {
        onShow();
      }

      setShowModal(false);
    };

    const onApply = () => {
      if (handleOnApply) {
        handleOnApply();
      }

      if (onShow) {
        onShow();
      }

      if (storageKey) {
        storageService.setItem(storageKey, '1');
      }

      setShowModal(false);
    };

    return (
      <Modal
        title={title}
        applyText={applyText}
        closeOnMask={closeOnMask}
        showCancel={showCancel}
        onApply={onApply}
        onClose={onClose}
        cancelText={cancelText}
        reverseButtons={reverseButtons}
      >
        {children}
      </Modal>
    );
  }
);
