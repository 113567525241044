import { createReducer } from '@reduxjs/toolkit';

import { actionCreators } from 'store/app/acions';
import { AppReducerState } from 'store/interfaces';
import { logout } from 'store/user/actions';

const initialState: Readonly<AppReducerState> = {
  headerTitle: '',
  isSearchOn: null,
  isAgreed: null,
  searchErrorStatus: null,
  isSearchLoading: false,
  isSearchLoadingInBackground: false,
  isFailedToGetSearch: false,
  isHelpScreen: false,
  showSidebar: false,
  rideCreditsAlert: null,
  rideCredits: null,
  isRideCreditsLoading: false,
  products: {},
  theme: false,
  showRideShare: false,
  showAllRides: false,
  wizardStep: 'intro',
};

const appReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(actionCreators.toggleShowAllRides, (state) => {
      state.showAllRides = !state.showAllRides;
    })

    .addCase(actionCreators.updateRideCredits, (state, action) => {
      const { rideCredits } = action.payload;

      state.rideCredits = rideCredits;
    })

    .addCase(actionCreators.setRideCreditsLoading, (state, action) => {
      const { isRideCreditsLoading } = action.payload;

      state.isRideCreditsLoading = isRideCreditsLoading;
    })

    .addCase(actionCreators.toggleTheme, (state) => {
      state.theme = !state.theme;
    })

    .addCase(actionCreators.toggleShowRideShare, (state) => {
      state.showRideShare = !state.showRideShare;
    })

    .addCase(actionCreators.setAppProducts, (state, action) => {
      const { products } = action.payload;

      state.products = products;
    })

    .addCase(actionCreators.toggleHelpScreen, (state) => {
      state.isHelpScreen = !state.isHelpScreen;
    })

    .addCase(actionCreators.toggleSidebar, (state) => {
      state.showSidebar = !state.showSidebar;
    })

    .addCase(actionCreators.setRideCreditsAlert, (state, action) => {
      const { value } = action.payload;

      state.rideCreditsAlert = value;
    })

    .addCase(actionCreators.setSearchStatus.REQUEST, (state) => {
      state.isSearchLoading = true;
    })

    .addCase(actionCreators.setSearchStatus.SUCCESS, (state, action) => {
      const { isSearchOn } = action.payload;

      state.isSearchLoading = false;
      state.isSearchOn = isSearchOn;
    })

    .addCase(actionCreators.setSearchStatus.FAILURE, (state) => {
      state.isSearchLoading = false;
    })

    .addCase(actionCreators.getSearchStatus.REQUEST, (state) => {
      state.isSearchLoadingInBackground = true;
    })

    .addCase(actionCreators.getSearchStatus.SUCCESS, (state, action) => {
      const { isSearchOn, rideCredits, isAgreed, searchErrorStatus } = action.payload;

      state.rideCredits = rideCredits;
      state.searchErrorStatus = searchErrorStatus;
      state.isAgreed = isAgreed;
      state.isSearchLoading = false;
      state.isSearchLoadingInBackground = false;
      state.isFailedToGetSearch = false;
      state.isSearchOn = isSearchOn;
    })

    .addCase(actionCreators.getSearchStatus.FAILURE, (state) => {
      state.isSearchLoading = false;
      state.isSearchLoadingInBackground = false;
      state.isFailedToGetSearch = true;
    })

    .addCase(actionCreators.setHeaderTitle, (state, action) => {
      const { title } = action.payload;

      state.headerTitle = title;
    })

    .addCase(logout, (state) => {
      state.rideCredits = null;
      state.showSidebar = false;
      state.isSearchOn = null;
    })

    .addCase(actionCreators.setWizardStep, (state, action) => {
      const { wizardStep } = action.payload;

      state.wizardStep = wizardStep;
    })
);

export default appReducer;
