import React from 'react';
import ReactDOM from 'react-dom';

import { useTranslation } from 'helpers/hooks';

import {
  PickerApply,
  PickerHeader,
  PickerModal,
  PickerModalContainer,
  PickerModalMask,
  PickerModalTitle,
} from './styled';

interface SettingsModalProps {
  onClose(): void;
  title: string;
  onApply(): void;
  children: any;
}
const modalRoot = document.getElementById('modal-root');

export const SettingsModal: React.FC<SettingsModalProps> = function ({
  title,
  onApply,
  onClose,
  children,
}) {
  const { t } = useTranslation();

  return ReactDOM.createPortal(
    <PickerModalContainer>
      <PickerModalMask onClick={onClose} />
      <PickerModal>
        <PickerHeader>
          <PickerModalTitle>{title}:</PickerModalTitle>
        </PickerHeader>
        {children}
        <PickerApply onClick={onApply}>{t('components.select.apply')}</PickerApply>
      </PickerModal>
    </PickerModalContainer>,
    modalRoot!
  );
};
