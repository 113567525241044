import React from 'react';

import { CarouselItem } from '../../../common/Carousel';
import { useTranslation } from '../../../helpers/hooks';
import { StepProps } from '../interfaces';
import { config } from '../../../services/Config';
import { useSettings } from '../../../core/settings/hooks';
import { SearchFilter } from '../../../core/settings/interfaces';
import FilterSelect from '../../../core/settings/FilterSelect';
import { Body, Hint, StepBody, Value } from '../styled';
import { eventsService } from '../../../services/EventsService';

export const MaxMilesToPickUp: React.FC<StepProps> = function ({ setNextWizardStep }) {
  const { t } = useTranslation();
  const { radiusFilter } = config.getItem('settings');
  const { setFilterValue, isChanging, daySettings } = useSettings();

  return (
    <CarouselItem
      onApply={setNextWizardStep}
      applyText={t('settings.wizard.maxMilesToPickUp.button')}
      bodyWrapper={StepBody}
    >
      <Body dangerouslySetInnerHTML={{ __html: t('settings.wizard.maxMilesToPickUp.body') }} />
      <Value onClick={() => eventsService.toggleSettingsFilter(SearchFilter.radius)}>
        <FilterSelect
          isChanging={isChanging.radius}
          title={t('settings.filters.radius')}
          // @ts-ignore
          handleChange={(value) => setFilterValue(value, 'radius')}
          value={daySettings.radius}
          postfix=" Miles"
          min={radiusFilter.min}
          max={radiusFilter.max}
          step={radiusFilter.step}
          filterName={SearchFilter.radius}
        />
      </Value>
      <Hint
        dangerouslySetInnerHTML={{
          __html: t('settings.wizard.maxMilesToPickUp.hint', { miles: '15' }),
        }}
      />
    </CarouselItem>
  );
};
