import React from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { shallowEqual, useSelector } from 'react-redux';

import { AppState } from '../../store/interfaces';
import { darkTheme, lightTheme } from '../../config/themes';

export const ThemeProvider: React.FC<{ children: any }> = function ({ children }) {
  const theme = useSelector((app: AppState) => app.app.theme, shallowEqual);
  return (
    <StyledThemeProvider theme={theme ? darkTheme : lightTheme}>{children}</StyledThemeProvider>
  );
};
