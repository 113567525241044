import React, { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { CarouselItem } from 'common/Carousel';
import { useTranslation } from 'helpers/hooks';

import { AppState, SettingsRecommendations } from '../../../store/interfaces';
import { useToggleModalMap } from '../hooks';

export const Done = React.memo(function () {
  const { t } = useTranslation();
  const toggleModalsMap = useToggleModalMap();

  const modalsMap = useSelector(
    (app: AppState) => app.settingsRecommendations.recommendations,
    shallowEqual
  );

  return (
    <CarouselItem
      onApply={() => {
        const modalKey = (Object.keys(modalsMap) as Array<keyof SettingsRecommendations>).find(
          (key) => modalsMap[key]
        );
        toggleModalsMap({ [modalKey!]: false });
      }}
      title={t('modals.recommendations.done.title')}
      applyText={t('modals.recommendations.done.applyButton')}
    />
  );
});
