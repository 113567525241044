import { createReducer } from '@reduxjs/toolkit';

import { getRideDestinationTimeIndex } from '../../core/rides/helpers';
import { RidesReducerState } from '../interfaces';

import { actionCreators } from './actions';

const initialState: Readonly<RidesReducerState> = {
  isLoading: false,
  rides: [],
  ridesRequestTimestamp: null,
  allRides: [],
  isLoadingBestRides: false,
  droppingRides: {},
  ridesDestinationTime: {},
  isFailedToGet: false,
  driverDestinationTime: {},
  rideForm: {
    isLoading: false,
    error: null,
    success: false,
  },
};

const ridesReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(actionCreators.addRide.REQUEST, (state) => {
      state.rideForm.isLoading = true;
    })

    .addCase(actionCreators.addMoreRide, (state) => {
      state.rideForm.success = false;
    })

    .addCase(actionCreators.addRide.SUCCESS, (state) => {
      state.rideForm.success = true;
      state.rideForm.error = null;
      state.rideForm.isLoading = false;
    })

    .addCase(actionCreators.addRide.FAILURE, (state, action) => {
      const { error } = action.payload;

      state.rideForm.error = error;
      state.rideForm.isLoading = false;
    })

    .addCase(actionCreators.getRides.REQUEST, (state, action) => {
      const { isSilent, timestamp } = action.payload;
      state.ridesRequestTimestamp = timestamp;
      state.isLoading = !isSilent;
    })

    .addCase(actionCreators.getRides.SUCCESS, (state, action) => {
      const { allRides, rides } = action.payload;

      state.isLoading = false;
      state.isFailedToGet = false;

      state.rides = rides;
      state.allRides = allRides;
    })

    .addCase(actionCreators.getRides.FAILURE, (state) => {
      state.isFailedToGet = true;
      state.isLoading = false;
    })

    .addCase(actionCreators.getRide.SUCCESS, (state, action) => {
      state.rides = state.rides.map((ride) => {
        if (ride.id !== action.payload.ride.id) {
          return ride;
        }

        return action.payload.ride;
      });
    })

    .addCase(actionCreators.getDriverDestinationTime.REQUEST, (state, action) => {
      const { ride } = action.payload;

      state.driverDestinationTime[ride.id] = null;
    })

    .addCase(actionCreators.getDriverDestinationTime.SUCCESS, (state, action) => {
      const { ride, time } = action.payload;

      state.driverDestinationTime[ride.id] = time;
    })

    .addCase(actionCreators.getDriverDestinationTime.FAILURE, (state, action) => {
      const { ride } = action.payload;

      state.driverDestinationTime[ride.id] = -1;
    })

    .addCase(actionCreators.dropRide.REQUEST, (state, action) => {
      const { id } = action.payload;

      state.droppingRides[id] = id;
    })

    .addCase(actionCreators.dropRide.SUCCESS, (state, action) => {
      const { id, rides } = action.payload;

      delete state.droppingRides[id];

      state.rides = rides;
    })

    .addCase(actionCreators.dropRide.FAILURE, (state, action) => {
      const { id, code } = action.payload;

      delete state.droppingRides[id];

      if (code === 404) {
        state.rides = state.rides.filter((ride) => ride.id !== id);
      }
    })

    .addCase(actionCreators.getRideDestinationTime.SUCCESS, (state, action) => {
      const { prevRideId, rideId, time } = action.payload;
      const index = getRideDestinationTimeIndex(prevRideId, rideId);

      state.ridesDestinationTime[index] = time;
    })

    .addCase(actionCreators.getRideDestinationTime.FAILURE, (state, action) => {
      const { prevRideId, rideId } = action.payload;
      const index = getRideDestinationTimeIndex(prevRideId, rideId);

      state.ridesDestinationTime[index] = null;
    })

    .addCase(actionCreators.exitRides, () => ({ ...initialState }))
);

export default ridesReducer;
