export enum EnvParam {
  APP_NAME = 'REACT_APP_NAME',
  SHOW_HELP_MENU = 'REACT_APP_SHOW_HELP_MENU',
  SHOW_SIDEBAR_HELP_LINKS = 'REACT_APP_SHOW_SIDEBAR_HELP_LINKS',
  SHOW_LOGIN_HELP = 'REACT_APP_SHOW_LOGIN_HELP',
  SHOW_INAPP_REVIEW = 'REACT_APP_SHOW_INAPP_REVIEW',
  SHOW_SHARE_RIDES = 'REACT_APP_SHOW_SHARE_RIDES',
}

interface GetParamOptions {
  asNumber?: boolean;
  defaultValue?: any;
}

export class EnvService {
  getParam(paramName: EnvParam, opt: GetParamOptions = {}) {
    const options: GetParamOptions = {
      asNumber: false,
      defaultValue: null,
      ...opt,
    };
    const value = process.env[paramName];

    if (value === undefined) {
      return options.defaultValue;
    }

    return options.asNumber ? !!+value : value;
  }
}

export const envService = new EnvService();
