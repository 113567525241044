export const getPlaceName = (place: any, withType = false): string => {
  // @ts-ignore
  const { name, street, housenumber, city, state, type } = place.properties;
  const placeName: string[] = [];

  if (name) {
    placeName.push(name);
  }

  if (housenumber) {
    placeName.push(housenumber);
  }

  if (street) {
    placeName.push(street);
  }

  if (city) {
    placeName.push(city);
  }

  if (state && state !== name) {
    placeName.push(state);
  }

  if (type && withType) {
    placeName.push(type);
  }

  return placeName.join(', ');
};
