import api from '../api';

export const getAddressByCoords = async ({ lat, lng }): Promise<string | null> => {
  const formData = new FormData();
  formData.append('data[service]', 'nominatim');
  formData.append('data[data][lat]', `${lat}`);
  formData.append('data[data][lng]', `${lng}`);

  const response = await api.getInformation(formData);

  if (response.status !== 200) {
    return null;
  }

  try {
    const addressArray = response.data.data.raw.display_name.split(',');
    return addressArray.slice(0, addressArray.length - 2).join(',');
  } catch (e) {
    return null;
  }
};
