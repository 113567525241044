import React, { useCallback, useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';

import { useTranslation } from 'helpers/hooks';

import { Events, eventsService, ShowInAppModalEvent } from '../../services/EventsService';
import Modal from '../../common/Modal';
import {
  CancelButton,
  ApplyLinkButton,
  ApplyButton,
  ButtonContainer,
  Title,
} from '../../common/Modal/styled';
import { inAppReviewService } from '../../services/InAppReviewService';
import { supportLink } from '../../config/urls';
import { registerInAppReviewEvent } from '../../services/reactGa';

import { Star, StartsContainer, Body } from './styled';

const start = new Array(5).fill(0);

// спросить у сервера показывали ли мы нативный ревью

const InAppReviewModal: React.FC = function () {
  const [show, setIsShow] = useState<boolean>(false);
  const [isSelfClicked, setIsSelfClicked] = useState<boolean>(false);
  const [selectedStars, setSelectedStars] = useState<number>(4);
  const [showSupportModal, setShowSupportModal] = useState<boolean>(false);

  const { t } = useTranslation();

  useEffect(() => {
    const handleShowInAppReview = (event: ShowInAppModalEvent) => {
      const { selfClicked } = event.detail;
      setIsShow(true);
      setIsSelfClicked(selfClicked);
    };

    eventsService.on(Events.ShowInAppReviewModal, handleShowInAppReview);

    return () => {
      eventsService.off(Events.ShowInAppReviewModal, handleShowInAppReview);
    };
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsShow(false);
    setSelectedStars(4);
    setShowSupportModal(false);
    setIsSelfClicked(false);
  }, []);

  const handleRemindLaterClick = useCallback(() => {
    inAppReviewService.remindIn(7);
    handleCloseModal();
  }, []);

  const handleAlreadyRated = useCallback(() => {
    inAppReviewService.remindIn(132);
    handleCloseModal();
  }, []);

  const handleRateClick = useCallback(() => {
    if (selectedStars < 0) {
      return;
    }

    registerInAppReviewEvent(selectedStars);

    if (selectedStars < 3) {
      setShowSupportModal(true);
      inAppReviewService.remindIn(132);
      return;
    }

    inAppReviewService.showInAppReview();
    handleCloseModal();
  }, [handleCloseModal, selectedStars]);

  if (!show) {
    return null;
  }

  if (showSupportModal) {
    return (
      <Modal
        closeOnMask={false}
        footer={
          <>
            <ButtonContainer>
              <CancelButton onClick={handleCloseModal}>{t('components.modal.cancel')}</CancelButton>
            </ButtonContainer>
            <ButtonContainer>
              <ApplyLinkButton onClick={handleCloseModal} href={supportLink}>
                {t('inAppReview.supportModal.applyButton')}
              </ApplyLinkButton>
            </ButtonContainer>
          </>
        }
      >
        <Body style={{ fontSize: 17 }}>
          <Title>{t('inAppReview.supportModal.title')}</Title>
          {t('inAppReview.supportModal.body1')} <b>{t('inAppReview.supportModal.body2')}</b>
          <br />
          {t('inAppReview.supportModal.body3')}
        </Body>
      </Modal>
    );
  }

  return (
    <Modal
      closeOnMask={false}
      footer={
        isSelfClicked ? (
          <>
            <ButtonContainer>
              <CancelButton onClick={handleCloseModal}>{t('components.modal.cancel')}</CancelButton>
            </ButtonContainer>
            <ButtonContainer>
              <ApplyButton onClick={handleRateClick}>{t('inAppReview.askModal.rate')}</ApplyButton>
            </ButtonContainer>
          </>
        ) : (
          <>
            <ButtonContainer>
              <CancelButton onClick={handleRemindLaterClick}>
                {t('inAppReview.askModal.notNow')}
              </CancelButton>
            </ButtonContainer>
            <ButtonContainer>
              <ApplyButton onClick={handleRateClick}>{t('inAppReview.askModal.rate')}</ApplyButton>
            </ButtonContainer>
            <ButtonContainer>
              <CancelButton onClick={handleAlreadyRated}>
                {t('inAppReview.askModal.alreadyRated')}
              </CancelButton>
            </ButtonContainer>
          </>
        )
      }
    >
      <Body>
        <Title>{t('inAppReview.askModal.title')}</Title>
        {t('inAppReview.askModal.body')}
      </Body>
      <StartsContainer>
        {start.map((v, index) => (
          <Star
            key={index}
            selected={index <= selectedStars}
            onClick={setSelectedStars.bind(null, index)}
          >
            <ReactSVG src="/assets/icons/star.svg" />
          </Star>
        ))}
      </StartsContainer>
    </Modal>
  );
};

export default React.memo(InAppReviewModal);
