import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import React from 'react';

import { AppState } from 'store/interfaces';
import { setWizardStep } from 'store/app/acions';

import urls from '../../config/urls';

import { WizardSteps } from './interfaces';

export const useSetNextWizardStep = (wizardSteps: WizardSteps) => {
  const dispatch = useDispatch();
  const currentStep = useSelector((app: AppState) => app.app.wizardStep, shallowEqual);
  const navigate = useNavigate();
  const location = useLocation();

  return React.useCallback(() => {
    const keys = Array.from(wizardSteps.keys());

    const nextStep = keys.reduce((acc, step, index) => {
      if (step === currentStep) {
        return keys[index + 1];
      }

      return acc;
    }, currentStep);

    const next = currentStep === nextStep ? null : nextStep;

    if (!next && location.pathname !== urls.settings) {
      navigate(urls.rides);
    }

    dispatch(setWizardStep(next));
  }, [currentStep]);
};
