import { makeCreateAction, makeCreateRoutine } from '../../helpers/actions';

const createAction = makeCreateAction('CREDITS_HISTORY');
const createRoutine = makeCreateRoutine('CREDITS_HISTORY');

export const getCreditsHistory = createRoutine('GET_CREDITS_HISTORY', {
  request: (year: number, week: number) => ({ year, week }),
  success: (credits) => ({ credits }),
});

export const exitCreditsHistory = createAction('EXIT_CREDITS_HISTORY');

export const actionCreators = {
  getCreditsHistory,
  exitCreditsHistory,
} as const;
