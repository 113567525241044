import React from 'react';

import { useTranslation } from 'helpers/hooks';
import { CarouselItem } from 'common/Carousel';

import { useToggleModalMap } from '../hooks';

export const Intro = React.memo(function () {
  const { t } = useTranslation();
  const toggleModalsMap = useToggleModalMap();

  return (
    <CarouselItem
      onApply={() => {
        toggleModalsMap({ intro: false });
      }}
      title={t('modals.recommendations.intro.title')}
      applyText={t('modals.recommendations.intro.applyButton')}
    >
      <div dangerouslySetInnerHTML={{ __html: t('modals.recommendations.intro.body') }} />
    </CarouselItem>
  );
});
