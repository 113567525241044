import styled, { css } from 'styled-components';

import { Link } from '../Link';

export const ModalContainer = styled.div`
  margin: 0;
  padding: 0;
  position: relative;
  bottom: 0;
  z-index: 13100;
`;

interface ModalMaskProps {
  showModal: boolean;
  animationTime: string;
}

export const ModalMask = styled.div<ModalMaskProps>`
  top: 0;
  right: 0;
  z-index: 250;
  height: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;

  background: ${(props) => (props.showModal ? props.theme.modal.overlay : 'none')};
  animation-name: ${(props) =>
    props.showModal ? 'animate-background-show' : 'animate-background-hide'};

  animation-duration: 0.4s;

  @keyframes animate-background-show {
    from {
      background: none;
    }
    to {
      background: ${(props) => props.theme.modal.overlay};
    }
  }

  @keyframes animate-background-hide {
    from {
      background: ${(props) => props.theme.modal.overlay};
    }
    to {
      background: none;
    }
  }
`;

export const ModalHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  align-content: center;
  padding: 10px 15px;
  text-align: center;
  flex: 0;

  svg {
    width: 1.2em;
    height: 1.2em;

    path {
      fill: ${(props) => props.theme.settings.info};
    }
  }
`;

export const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #e728e0;
  flex: 1;
`;

export const ApplyButton = styled.span`
  background: #e728e0;
  color: #fff;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-align: center;
  padding: 10px 12px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
`;

export const CancelButton = styled.span`
  color: #5abd83;
  a {
    color: #5abd83;
  }
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-align: center;
  padding: 10px 5px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ApplyLinkButton = styled(Link)`
  background: #e728e0;
  color: #fff;
  padding: 10px 12px;
  font-weight: bold;
  border-radius: 12px;
`;

export const LinkButton = styled(Link)`
  color: #5abd83;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-align: center;
  padding: 10px 5px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.div`
  color: #e728e0;
  margin-bottom: 10px;
  font-weight: bold;
  line-height: 130%;
  font-size: 18px;
`;

type Position = 'center' | 'bottom';

interface ModalBodyProps {
  showModal: boolean;
  animationTime: string;
  position: Position;
}

export const ModalBody = styled.div<ModalBodyProps>`
  position: fixed;

  ${(props: ModalBodyProps) => {
    if (props.position === 'bottom') {
      // eslint-disable-next-line sonarjs/no-nested-template-literals
      return css`
        width: 100%;
        bottom: 0;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        animation-name: ${props.showModal
          ? 'animate-bottom-modal-show'
          : 'animate-bottom-modal-hide'};
      `;
    }

    // eslint-disable-next-line sonarjs/no-nested-template-literals
    return css`
      width: 300px;

      @media (min-width: 380px) {
        width: 340px;
      }

      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 10px;
      animation-name: ${props.showModal ? 'animate-modal-show' : 'animate-modal-hide'};
    `;
  }};

  margin-left: auto;
  margin-right: auto;
  z-index: 300;
  background-color: ${(props) => props.theme.modal.background};
  opacity: ${(props) => (props.showModal ? 1 : 0)};
  animation-duration: ${(props) => props.animationTime};
  max-height: 80vh;
  display: flex;
  flex-direction: column;

  @keyframes animate-modal-show {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes animate-modal-hide {
    from {
      opacity: 1;
      width: 300px;
    }
    to {
      opacity: 0;
      width: 320px;
    }
  }
  @keyframes animate-bottom-modal-show {
    from {
      opacity: 0;
      bottom: -20%;
    }
    to {
      opacity: 1;
      bottom: 0;
    }
  }

  @keyframes animate-bottom-modal-hide {
    from {
      opacity: 1;
      bottom: 0;
    }
    to {
      opacity: 0;
      bottom: -20%;
    }
  }
`;

interface ModalDescriptionProps {
  position: Position;
}

export const ModalDescription = styled.div<ModalDescriptionProps>`
  padding: 14px 15px 14px 15px;
  text-align: ${(props) => (props.position === 'center' ? 'center' : 'left')}
  line-height: 130%;
  font-size: 18px;
  letter-spacing: -0.3px;
  flex: 1;
  overflow: auto;
  
  p {
    margin-top: 0;
    line-height: 150%;
    
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  border-top: 1px solid #edd2d2;
  justify-content: space-around;
  padding: 8px 0;
  align-items: center;
  flex: 0;
`;
