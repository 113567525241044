import styled from 'styled-components';

export const StartsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  margin: 16px 0 6px;
`;

interface StarInterface {
  selected?: boolean;
}

export const Star = styled.span<StarInterface>`
  ${({ selected }) => {
    if (selected) {
      return `svg polygon {fill: #FFC002}`;
    }
  }}
`;

export const Body = styled.div`
  font-size: 20px;
`;
