import styled, { css } from 'styled-components';
import React from 'react';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  justify-content: start;
  height: inherit;

  margin: 0 auto;

  h1 {
    font-weight: normal;
    text-align: center;
    margin: 24px 0 18px 0;
    font-size: 52px;
    color: ${(props) => props.theme.altText};
  }

  h2 {
    font-weight: normal;
    text-align: center;
    margin: 0 0 18px 0;
    color: ${(props) => props.theme.textColor};
  }
`;

interface InputProps {
  error?: string;
  prefix?: string;
}

export const Input = styled.input<InputProps>`
  padding: ${(props) =>
    props.prefix ? `8px 10px 8px ${8 + props.prefix.length * 8}px` : '8px 10px'};
  font-size: 18px;
  background: ${(props) => props.theme.input.background};
  color: ${(props) => props.theme.textColor};
  outline: none;
  border: none;

  :focus {
    outline: none;
  }

  :focus ~ label {
    top: -6px;
    font-size: 13px;
  }

  ::placeholder {
    color: ${(props) => props.theme.input.placeholder};
  }

  ${({ value }) =>
    value !== undefined &&
    value !== '' &&
    value !== null &&
    css`
      ~ label {
        top: -6px;
        font-size: 13px;
      }
    `}

  ${({ error }) =>
    error &&
    `
      border-bottom: 2px solid ${(props) => props.theme.input.error};
      
      ~ label {
        color: ${(props) => props.theme.input.error};
      }
      
  `}
`;

export const InputError = styled.div`
  color: ${(props) => props.theme.input.error};
  margin-top: 4px;
  margin-left: 8px;
  font-size: 18px;
  letter-spacing: -0.3px;
`;

interface ButtonStyledProps {
  isLoading?: boolean;
  isDisabled: boolean;
}

export const ButtonStyled = styled.button<ButtonStyledProps>`
  position: relative;
  color: ${({ theme }) => theme.textColor};
  font-size: 18px;
  box-shadow: 1px 2px 1px 0 rgba(0, 0, 0, 0.2);
  background: ${(props) => (props.isDisabled ? '#656565' : props.theme.buttonBackground)};
  border-radius: 40px;
  padding: 10px 30px;
  display: inline-flex;
  user-select: none;
  appearance: none !important;

  @media (max-width: 380px) {
    font-size: 16px;
  }

  border: none;

  &:disabled {
    opacity: 0.5;
  }

  transition: opacity 200ms cubic-bezier(0.45, 0, 0.15, 1) 0s;

  @media (max-width: 370px) {
    font-size: 16px;
    padding: 8px 30px;
  }

  ${({ isLoading }) => {
    if (isLoading) {
      return '';
    }

    return 'cursor: pointer;';
  }}
`;

export const Loading = styled.span`
  border: 4px solid #f3f3f3;
  border-top: 4px solid ${({ theme }) => theme.background};
  border-radius: 50%;
  width: 12px;
  height: 12px;
  animation: spin 1s linear infinite;
  margin-left: 8px;
  display: inline-block;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

interface ButtonProps {
  isLoading?: boolean;
  children: React.ReactNode;
  onClick?: any;
  style?: object;
  isDisabled?: boolean;
  className?: string;
  type?: 'button' | 'reset' | 'submit';
}

export const Button: React.FC<ButtonProps> = React.memo(function ({
  isLoading = false,
  children,
  onClick,
  style,
  isDisabled = false,
  className,
  type,
}) {
  return (
    <ButtonStyled
      style={style}
      type={type}
      className={className}
      isDisabled={isDisabled}
      disabled={isLoading}
      onClick={isDisabled ? null : onClick}
    >
      {children}
    </ButtonStyled>
  );
});

const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  .input-prefix {
    position: absolute;
    z-index: 2;
    top: 8px;
    color: #000;
    left: 6px;
  }
`;

interface FormInputProps {
  value?: string | number;
  type?: string;
  placeholder?: string;
  name: string;
  onChange(e: React.ChangeEvent<HTMLInputElement>): void;
  onPaste?(e: React.ClipboardEvent<HTMLInputElement>): void;
  error?: string;
  labelText?: any;
  inputStyle?: object;
  ref?: any;
  prefix?: string;
}

const FormInputComponent = React.forwardRef(function (
  {
    value,
    name,
    placeholder = '',
    labelText = '',
    prefix = '',
    onChange,
    onPaste,
    error,
    type = 'text',
    inputStyle = {},
    ...props
  }: FormInputProps,
  ref
) {
  if (type === 'checkbox') {
    return (
      <InputContainer>
        <label htmlFor={`${name}-checkbox`}>
          <div style={{ display: 'flex' }}>
            <Input
              style={{ marginRight: 5, ...inputStyle }}
              id={`${name}-checkbox`}
              type={type}
              name={name}
              onPaste={onPaste}
              placeholder={placeholder}
              value={value}
              error={error}
              ref={ref}
              onChange={onChange}
              {...props}
            />
            {labelText}
          </div>
        </label>
        {error && <InputError>{error}</InputError>}
      </InputContainer>
    );
  }

  return (
    <InputContainer>
      {prefix && <span className="input-prefix">{prefix}</span>}
      <Input
        style={inputStyle}
        type={type}
        prefix={prefix}
        name={name}
        value={value}
        error={error}
        onPaste={onPaste}
        ref={ref}
        onChange={onChange}
        placeholder={placeholder}
        {...props}
      />
      {error && <InputError>{error}</InputError>}
    </InputContainer>
  );
});

export const FormInput = React.memo(FormInputComponent);
