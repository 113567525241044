import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';

import { Button } from '../../common/styled/form';
import { sendLog } from '../../store/app/acions';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

interface ErrorBoundaryState {
  hasError: boolean;
}

interface ErrorBoundaryProps {
  children: React.ReactNode;
  sendLog(data: object): void;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps & WithTranslation,
  ErrorBoundaryState
> {
  constructor(props) {
    super(props);

    this.state = { hasError: false };
  }

  componentDidCatch(error: object, errorInfo: { componentStack: string }) {
    this.setState({
      hasError: true,
    });

    this.props.sendLog({
      error: error.toString(),
    });
  }

  onClick = () => document.location.reload();

  render() {
    const { t } = this.props;
    if (this.state.hasError) {
      return (
        <Container>
          <h1>{t('components.errorBoundary.title')}</h1>
          <p>{t('components.errorBoundary.body')}</p>
          <div>
            <Button style={{ background: '#F7B731' }} onClick={this.onClick}>
              {t('components.errorBoundary.button')}
            </Button>
          </div>
        </Container>
      );
    }

    // @ts-ignore
    return this.props.children;
  }
}

const mapDispatchToProps = {
  sendLog,
};

export default withTranslation()(connect(null, mapDispatchToProps)(ErrorBoundary));
