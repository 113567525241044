import ReactGA from 'react-ga4';
import moment from 'moment';

import { isAndroid } from '../helpers/os';
import { MarkerStatus } from '../types/marker';
import { createDebug } from '../helpers/debug';

const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
const debug = createDebug.extend('analytics');

export function initGa() {
  if (isDev) {
    return;
  }
  ReactGA.initialize('G-BMDWNK8JSE');
}

export function sendEvent(name: string, payload: object = {}) {
  debug(name, {
    ...payload,
    platform: isAndroid ? 'Android' : 'iOs',
  });

  if (isDev) {
    return;
  }

  ReactGA.event(name, {
    ...payload,
    platform: isAndroid ? 'Android' : 'iOs',
  });
}

export function sendPageview(page) {
  ReactGA.send({ hitType: 'pageview', page });
}

export const registerShowInAppReviewEvent = () => {
  sendEvent('inAppReview_Show');
};

export const registerInAppReviewEvent = (mark: number) => {
  sendEvent('inAppReview_Send', {
    mark,
  });
};

export const registerSettingsCreateEvent = () => {
  sendEvent('Settings_CreateForNewUser', {
    time: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
  });
};

export const registerThemeEvent = (isDark: boolean) => {
  sendEvent('ThemeSet', {
    theme: isDark ? 'Dark' : 'Light',
  });
};

export const registerRidesPullToRefreshEvent = () => {
  sendEvent('Rides_PullToRefresh', {});
};

export const registerRideSkipEvent = () => {
  sendEvent('Rides_RideSkip', {});
};

export const registerRideCreditsRulesClickEvent = () => {
  sendEvent('Rides_CreditsRulesClick', {});
};

export const registerRideClickEvent = () => {
  sendEvent('Rides_RideClick', {});
};

export const registerDayRidesClickEvent = () => {
  sendEvent('Rides_DayRidesClick', {});
};

export const registerLogoutEvent = () => {
  sendEvent('UserLogout');
};

export const registerLoginEvent = () => {
  sendEvent('UserLogin');
};

export const registerPurchaseEvent = (productId: string) => {
  sendEvent('purchase', {
    productId,
  });
};

export const registerCopyPickupAddress = () => {
  sendEvent('Map_CopyPickupAddress');
};

export const registerSearchPlaceClickEvent = () => {
  sendEvent('Map_SearchPlaceClick');
};

export const registerSearchPlaceClearEvent = () => {
  sendEvent('Map_SearchPlaceClearClick');
};

export const registerRidesSliderClickEvent = () => {
  sendEvent('Map_RidesSliderClick');
};

export const registerChangePinStatusEvent = (status: MarkerStatus) => {
  if (status === MarkerStatus.active) {
    sendEvent('Map_ActivePin');
    return;
  }

  sendEvent('Map_InactivatePin');
};

export const registerCreatePinEvent = () => {
  sendEvent('Map_CreatePin');
};

export const registerMapCloseTopTipEvent = () => {
  sendEvent('Map_CloseTopTip');
};

export const registerDeletePinEvent = () => {
  sendEvent('Map_DeletePin');
};

export const registerGeoLocationClickEvent = () => {
  sendEvent('Map_GeoLocationClick');
};

export const registerShowLayersClickEvent = () => {
  sendEvent('Map_ShowLayersClick');
};

export const registerLayersInfoClickEvent = () => {
  sendEvent('Map_LayersInfoClick');
};

export const registerCreditsHistorySliderClickEvent = () => {
  sendEvent('CreditsHistory_SliderClick');
};

export const registerSearchClickEvent = (isSearchOn: boolean) => {
  sendEvent('Settings_SearchClick', {
    isSearchOn,
  });
};

export const registerSidebarFBClickEvent = () => {
  sendEvent('Sidebar_FBClick');
};

export const registerSidebarTutorialClickEvent = () => {
  sendEvent('Sidebar_TutorialClick');
};

export const registerSidebarFaqClickEvent = () => {
  sendEvent('Sidebar_FaqClick');
};

export const registerSidebarContactSupportClickEvent = () => {
  sendEvent('Sidebar_ContactSupportClick');
};

export const registerSidebarStepByStepClickEvent = () => {
  sendEvent('Sidebar_StepByStepClick');
};

export const registerSidebarGeneralGuidanceClickEvent = () => {
  sendEvent('Sidebar_GeneralGuidanceClick');
};

export const registerSidebarHelpClickEvent = () => {
  sendEvent('Sidebar_HelpClick');
};

export const registerHelpCreateRequestClickEvent = () => {
  sendEvent('Help_CreateRequestClick', {
    time: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
  });
};

export const registerGuidanceClickEvent = () => {
  sendEvent('Help_ GuidanceClick');
};

export const registerHelpTutorialClickEvent = () => {
  sendEvent('Help_TutorialClick');
};

export const registerHelpStepByStepGuideClickEvent = () => {
  sendEvent('Help_StepByStepGuideClick');
};

export const registerHelpFBClickEvent = () => {
  sendEvent('Help_FBClick');
};

export const registerSettingsAddNewPinClickEvent = () => {
  sendEvent('Settings_AddNewPinClick');
};

export const registerSettingsShowInfoEvent = (filterName) => {
  sendEvent('Settings_ShowInfoClick', {
    filterName,
  });
};

export const registerInstructionsClickEvent = (isNewUser: boolean) => {
  sendEvent(isNewUser ? 'Registration_InstructionsClick' : 'Login_InstructionsClick');
};

export const registerContactSupportClickEvent = (isNewUser: boolean) => {
  sendEvent(isNewUser ? 'Registration_ContactSupportClick' : 'Login_ContactSupportClick');
};

export const registerVerificationLyftLinkClickEvent = () => {
  sendEvent('Verification_LyftLinkClick');
};

export const registerGreetingSkeddySiteClickEvent = () => {
  sendEvent('Greeting_SkeddySiteClick');
};

export const registerGreetingFbGroupClickEvent = () => {
  sendEvent('Greeting_FbGroupClick');
};

export const registerStepByStepFbGroupClickEvent = () => {
  sendEvent('StepByStep_FbGroupClick');
};

export const registerNewCreditsShowAcceptModalEvent = () => {
  sendEvent('NewCredits_ShowAcceptModal');
};

export const registerNewCreditsAcceptEvent = () => {
  sendEvent('NewCredits_AcceptClick');
};

export const registerNewCreditsRemindLaterEvent = () => {
  sendEvent('NewCredits_RemindLaterClick');
};
