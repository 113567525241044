import { getAppReviewStoreUrl } from '../config/urls';
import { isAndroid, isIos } from '../helpers/os';

import storageService, { StorageKeys, StorageService } from './StorageService';
import { NativeMessageService, nativeMessageService } from './NativeMessageService';

export class InAppReviewService {
  private nativeMessageService: NativeMessageService;

  private storageService: StorageService;

  private static readonly IS_IN_APP_REVIEW_ALLOWED = false;

  constructor({ nativeMessageService, storageService }) {
    this.nativeMessageService = nativeMessageService;
    this.storageService = storageService;
  }

  checkIfShouldShowInAppReview = (): boolean => {
    const nextDate = this.storageService.getItem(StorageKeys.inAppReviewNextDate);

    if (!nextDate) {
      return true;
    }

    const nextDateNumber = +nextDate;

    if (Number.isNaN(nextDateNumber)) {
      return true;
    }

    const currentDate = +new Date();

    return currentDate > nextDateNumber;
  };

  showInAppReview = () => {
    const url = getAppReviewStoreUrl();

    // #TODO remove isAndroid when native inAppReview will be implemented in iOs app
    if (isIos) {
      const link = document.createElement('a');

      link.href = url;
      link.target = '_blank';
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      link.remove();

      this.remindIn(132);
      return;
    }

    // #TODO remove IS_IN_APP_REVIEW_ALLOWED when all apps will be updated
    if (
      !this.storageService.getItem(StorageKeys.inAppNativeReviewShown) &&
      InAppReviewService.IS_IN_APP_REVIEW_ALLOWED
    ) {
      this.nativeMessageService.sendShowInAppReviewMessage();
      this.storageService.setItem(StorageKeys.inAppNativeReviewShown, '1');
      this.remindIn(7);
      return;
    }

    if (isAndroid) {
      this.nativeMessageService.sendClickLinkMessage(url);
    } else {
      window.open(url);
    }

    this.remindIn(132);
  };

  remindIn = (days: number) => {
    this.storageService.setItem(
      StorageKeys.inAppReviewNextDate,
      `${+new Date() + days * 1000 * 60 * 60 * 24}`
    );
  };
}

export const inAppReviewService = new InAppReviewService({
  nativeMessageService,
  storageService,
});
