import React from 'react';
import styled, { css } from 'styled-components';

type Position = 'absolute' | 'static' | 'relative';

interface ContainerProps {
  size?: 'small';
  top: number;
  right: number;
  position: Position;
}

const Container = styled.span<ContainerProps>`
  position: ${(props) => props.position};
  right: ${(props) => props.right}px;
  top: ${(props) => props.top}px;
  z-index: 50;

  ${(props) => {
    if (props.size === 'small') {
      return css`
        width: 24px;
        height: 24px;
      `;
    }

    return css`
      width: 32px;
      height: 32px;
    `;
  }}

  &::before,
  &::after {
    position: absolute;
    left: 50%;
    content: ' ';
    height: 35px;
    ${(props) => {
      if (props.size === 'small') {
        return css`
          height: 24px;
        `;
      }

      return css`
        height: 32px;
      `;
    }}
    transform: translate(-50%);
    width: 2px;
    background-color: ${(props) => props.theme.pink};
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
`;

interface CloseButtonProps {
  onClick(): void;
  size?: 'small';
  top?: number;
  right?: number;
  position?: Position;
}

export const CloseButton: React.FC<CloseButtonProps> = function ({
  onClick,
  size,
  top = 16,
  right = 20,
  position = 'absolute',
}) {
  return <Container position={position} size={size} top={top} right={right} onClick={onClick} />;
};
