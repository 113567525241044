import React, { useCallback } from 'react';

import { isAndroid } from 'helpers/os';
import { nativeMessageService } from 'services/NativeMessageService';

interface LinkProps {
  href?: string;
  target?: string;
  children: any;
  className?: string;
  color?: string;
  style?: object;
  onClick?(e?: React.MouseEvent<HTMLElement>): void;
}

export const Link: React.FC<LinkProps> = function ({
  href = '',
  target = '_blank',
  children,
  onClick,
  className,
  color,
  style,
}) {
  const rel = target === '_blank' ? 'noopener noreferrer' : '';

  let linkStyle: any = {};

  if (style) {
    linkStyle = { ...style };
  }

  if (color) {
    linkStyle.color = color;
  }

  const handleClick = useCallback(
    (e) => {
      if (onClick) {
        onClick(e);
      }

      if (!href) {
        e.preventDefault();
        return;
      }

      if (isAndroid) {
        e.preventDefault();
        nativeMessageService.sendClickLinkMessage(href);
      }
    },
    [nativeMessageService, onClick, href, isAndroid]
  );

  return (
    <a
      style={linkStyle}
      className={className}
      onClick={handleClick}
      href={href}
      target={target}
      rel={rel}
    >
      {children}
    </a>
  );
};
