import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import { PersistConfig } from 'redux-persist/es/types';
import { all } from 'redux-saga/effects';

import appReducer from 'store/app';
import authReducer from 'store/user/auth';
import verificationReducer from 'store/user/verificationReducer';
import settingsReducer from 'store/settings';
import ridesReducer from 'store/rides';
import mapReducer from 'store/map';
import creditsHistoryReducer from 'store/creditsHistory';
import settingsRecommendationsReducer from 'store/settingsRecommendations';

import { tokenMiddleware } from './tokenMiddleware';
import mapSagas from './map/sagas';
import userSagas from './user/sagas';
import appSagas from './app/sagas';
import ridesSagas from './rides/sagas';
import creditsHistorySagas from './creditsHistory/sagas';
import settingsSagas from './settings/sagas';
import settingsRecommendationsSagas from './settingsRecommendations/sagas';

function* rootSaga() {
  yield all([
    userSagas(),
    appSagas(),
    settingsSagas(),
    ridesSagas(),
    creditsHistorySagas(),
    mapSagas(),
    settingsRecommendationsSagas(),
  ]);
}

const reducers = combineReducers({
  app: appReducer,
  auth: authReducer,
  userForms: combineReducers({
    verification: verificationReducer,
  }),
  settings: settingsReducer,
  rides: ridesReducer,
  map: mapReducer,
  settingsRecommendations: settingsRecommendationsReducer,
  creditsHistory: creditsHistoryReducer,
});

const persistConfig: PersistConfig<object> = {
  key: 'root',
  whitelist: ['auth', 'app', 'settingsRecommendations'],
  storage,
};
// @ts-ignore
export const persistedReducer = persistReducer(persistConfig, reducers);
const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [tokenMiddleware, sagaMiddleware],
  devTools: process.env.NODE_ENV !== 'production',
});

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);
