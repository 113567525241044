import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'helpers/hooks';

import { Checkbox } from '../../../common/Checkbox';
import Modal from '../../../common/Modal';
import { SettingsModal } from '../../../common/SettingsModal';
import { setSearchStatus } from '../../../store/app/acions';
import { AppState } from '../../../store/interfaces';
import { sendEvent } from '../../../services/reactGa';

import { convertDayOff, Week, WeekValues } from './helper';

const DaysContainer = styled.div`
  display: flex;
  padding: 12px 6px;

  > div {
    flex: 1;
    text-align: center;
    margin: 12px 0;

    span {
      margin-top: 12px;
    }
  }
`;

interface DaySelectProps {
  value: any;
  handleChange(values: any): void;
  toggleModal(): void;
}

const weekDays = Object.keys(Week).reverse();

export const DaySelect: React.FC<DaySelectProps> = function ({ value, handleChange, toggleModal }) {
  const [values, setValues] = useState<string>(convertDayOff(value));
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [modal, setModal] = useState<any>(null);
  const isSearchOn = useSelector((app: AppState) => app.app.isSearchOn);

  const handleCheck = (day: WeekValues, value) => {
    const newValue = !value;
    const newValues = Object.keys(Week).reduce((acc: string, weekDay, index) => {
      if (Week[weekDay] === undefined) {
        return acc;
      }

      if (day === index) {
        return `${acc}${+newValue}`;
      }

      if (values[index]) {
        return `${acc}${values[index]}`;
      }

      return `${acc}0`;
    }, '');

    setValues(`${newValues}`);
  };

  const apply = () => {
    if (values === convertDayOff(value)) {
      toggleModal();
      return;
    }

    if (values === '1111111') {
      sendEvent('Settings_DaysStopSearchModal_Show');
      setModal(
        <Modal
          onApply={() => {
            sendEvent('Settings_DaysStopSearchModal_Apply');
            if (isSearchOn) {
              dispatch(setSearchStatus.request(false));
            }

            toggleModal();
          }}
          applyText={t('settings.daysOffModal.applyButton')}
          title={t('settings.daysOffModal.title')}
          onClose={() => {
            sendEvent('Settings_DaysStopSearchModal_Cancel');
            setModal(null);
          }}
          reverseButtons
        >
          {t('settings.daysOffModal.body')}
        </Modal>
      );
      return;
    }

    handleChange(values);
    toggleModal();
  };

  const isChecked = (day: WeekValues) => {
    if (values[day] === undefined) {
      return false;
    }

    return !+values[day];
  };

  return (
    <SettingsModal title={t('settings.myWorkingDays')} onClose={toggleModal} onApply={apply}>
      <DaysContainer>
        {weekDays.map((day) => (
          <div key={day}>
            <div>{t(`settings.week.${day}`)}</div>
            <Checkbox
              value={isChecked(Week[day])}
              onChange={(value) => handleCheck(Week[day], value)}
            />
          </div>
        ))}
      </DaysContainer>
      {modal}
    </SettingsModal>
  );
};
