import styled from 'styled-components';

import { vh100 } from '../../../config/css';

export const MainContainer = styled.div`
  height: calc(${vh100});
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  position: relative;
  box-shadow: inset 0 0 1px rgb(0 0 0 / 80%);
  justify-content: center;
  display: flex;

  h2 {
    color: ${(props) => props.theme.altText};
  }
`;

export const SlidesContainer = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const CirclesContainer = styled.div`
  flex: 0 0 50px;
  background: ${({ theme }) => theme.background};
  justify-content: center;
  display: flex;
`;

interface CircleProps {
  selected: boolean;
}

export const Circle = styled.span<CircleProps>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 10px;
  background: ${({ selected }) => (selected ? '#FF00ED' : '#A6A6A6')};
  cursor: pointer;
`;

export const CirclesWrap = styled.div`
  display: inline-flex;
  flex-direction: row;
`;

export const SlidesWrap = styled.div`
  flex: 1;
  overflow: auto;
`;

export const MainText = styled.div`
  text-align: center;
  line-height: 140%;
  font-size: 20px;

  @media (max-width: 400px) {
    font-size: 18px;
  }

  .fb-group-image {
    margin-top: 20px;
    max-width: 200px;

    @media (max-width: 400px) {
      max-width: 120px;
    }
  }
`;

export const SkipButton = styled.span`
  position: absolute;
  right: 36px;
  font-size: 20px;
  color: ${(props) => props.theme.pink};
  font-weight: bold;
`;
