import { createReducer } from '@reduxjs/toolkit';

import { actionCreators } from 'store/settingsRecommendations/acions';
import { PermissionStatus, SettingsRecommendationsReducerState } from 'store/interfaces';

import { logout } from '../user/actions';
import { isOneOf } from '../../helpers/actions';

const initialState: Readonly<SettingsRecommendationsReducerState> = {
  recommendations: {
    intro: false,
    search: false,
    searchSuccess: false,
    noCredits: false,
    autoAdjustSearchPins: false,
    autoAdjustSearchPinsSuccess: false,
    minimumRidePayout: false,
    minimumRidePayoutSuccess: false,
    maxMilesToPickup: false,
    maxMilesToPickupSuccess: false,
    locationPermission: false,
    pushPermission: false,
    allDone: false,
  },
  permissions: {
    push: PermissionStatus.Undefined,
    location: PermissionStatus.Undefined,
    battery: PermissionStatus.Undefined,
  },
};

const settingsRecommendationsReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(actionCreators.setModalsMap, (state, action) => {
      const { data, setToInitial } = action.payload;

      if (setToInitial) {
        state.recommendations = {
          ...initialState.recommendations,
          ...data,
        };
      } else {
        Object.keys(data).forEach((key) => {
          state.recommendations[key] = data[key];
        });
      }
    })
    .addCase(actionCreators.setPermissionStatus, (state, action) => {
      const { permission, status } = action.payload;

      state.permissions[permission] = status;
    })
    .addMatcher(isOneOf([actionCreators.setInitialState.type, logout.type]), (state) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key];
      });
    })
);

export default settingsRecommendationsReducer;
