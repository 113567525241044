import React from 'react';

import { useTranslation } from '../../../helpers/hooks';
import { CarouselItem } from '../../../common/Carousel';
import { SearchFilter } from '../../../core/settings/interfaces';
import { useSettings } from '../../../core/settings/hooks';
import { DaySetting } from '../../../core/settings/DaySetting';
import { StepProps } from '../interfaces';
import { Body, StepBody, Value } from '../styled';
import { eventsService } from '../../../services/EventsService';

export const WorkingDays: React.FC<StepProps> = function ({ setNextWizardStep }) {
  const { t } = useTranslation();
  const { setDayOff, isChanging, daySettings } = useSettings();
  return (
    <CarouselItem
      bodyWrapper={StepBody}
      onApply={setNextWizardStep}
      applyText={t('settings.wizard.workingDays.button')}
    >
      <Body dangerouslySetInnerHTML={{ __html: t('settings.wizard.workingDays.body') }} />
      <Value onClick={() => eventsService.toggleSettingsFilter(SearchFilter.dayOff)}>
        <DaySetting
          filterName={SearchFilter.dayOff}
          isChanging={isChanging.day_off}
          handleChange={setDayOff}
          value={daySettings.day_off}
        />
      </Value>
    </CarouselItem>
  );
};
