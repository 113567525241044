import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useTranslation } from 'helpers/hooks';

import { LoadingProp } from '../styled';
import { SearchFilter } from '../interfaces';
import { Events, eventsService, ToggleSettingsFilterEvent } from '../../../services/EventsService';
import i18n from '../../../i18n';
import { checkSearchAndRunCallback } from '../../../store/app/acions';

import { DaySelect } from './DaySelect';
import { convertDayOff, Week } from './helper';

const getPrintValue = (value: string, t) => {
  if (+value === 127) {
    return i18n.t('settings.no');
  }

  if (+value === 0) {
    return i18n.t('settings.wholeWeek');
  }

  const dayArr = convertDayOff(value)
    .split('')
    .map((el) => (el === '1' ? '0' : '1'));

  return Object.keys(Week)
    .reduce((acc: string[], dayKey, index) => {
      if (dayArr[index] && dayArr[index] === '1') {
        acc.push(t(`settings.week.${dayKey}`));
      }

      return acc;
    }, [])
    .reverse()
    .join(', ');
};

interface DaySettingProps {
  value: any;
  isChanging: boolean;
  handleChange(values: any): void;
  filterName: SearchFilter;
}

export const DaySetting: React.FC<DaySettingProps> = React.memo(function ({
  value,
  isChanging,
  handleChange,
  filterName,
}) {
  const { t } = useTranslation();
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const toggleModal = () => setShowEdit((showEdit) => !showEdit);
  const dispatch = useDispatch();

  useEffect(() => {
    const toggle = (e: ToggleSettingsFilterEvent) => {
      if (filterName !== e.detail.filterName) {
        return;
      }

      if (!showEdit) {
        dispatch(checkSearchAndRunCallback(() => toggleModal()));
      }
    };

    eventsService.on(Events.ToggleSettingsFilter, toggle);

    return () => {
      eventsService.off(Events.ToggleSettingsFilter, toggle);
    };
  }, [filterName, showEdit]);

  if (isChanging) {
    return <LoadingProp />;
  }

  return (
    <>
      <span style={{ fontWeight: 'bold' }}>{getPrintValue(value, t)}</span>
      {showEdit && (
        <DaySelect toggleModal={toggleModal} value={value} handleChange={handleChange} />
      )}
    </>
  );
});
