import { useCallback, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { getSettings, setSettings } from 'store/settings/actions';

import { AppVisibilityChangeEvent, Events, eventsService } from '../../services/EventsService';
import { Settings as SettingsProps } from '../../types/settings';
import { AppState } from '../../store/interfaces';

export const useUpdateSettings = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const handleVisibilityChange = (event: AppVisibilityChangeEvent) => {
      if (!event.detail.isVisible) {
        return;
      }
      dispatch(getSettings.request({ isSilent: true }));
    };

    eventsService.on(Events.AppVisibilityChange, handleVisibilityChange);

    return () => {
      eventsService.off(Events.AppVisibilityChange, handleVisibilityChange);
    };
  }, []);

  return null;
};

export const useSettings = () => {
  const dispatch = useDispatch();

  const { daySettings, isSettingsLoading, isChanging } = useSelector(
    (app: AppState) => ({
      daySettings: app.settings.daySettings,
      isSettingsLoading: app.settings.isSettingsLoading,
      isChanging: app.settings.isChanging,
    }),
    shallowEqual
  );

  const setFilterValue = useCallback(
    (value: { filterValue: string }, property: keyof SettingsProps) => {
      const miles = value.filterValue.match(/\d+/g)!.pop();
      dispatch(setSettings.request({ value: miles, property }));
    },
    []
  );

  const setStartWorkTime = useCallback((value) => {
    const property = 'begin_work';
    dispatch(setSettings.request({ value, property }));
  }, []);

  const setFilterBoolean = useCallback((value: boolean, property: keyof SettingsProps) => {
    dispatch(setSettings.request({ value: +value, property }));
  }, []);

  const setWorkHours = useCallback((values: { hours: string }) => {
    const { hours } = values;
    const property = 'end_work';
    const value = parseInt(hours, 10) * 3600;

    dispatch(setSettings.request({ value, property }));
  }, []);

  const setDayOff = useCallback((values: string) => {
    const value = `${values}`.split('').reduceRight((acc, value, index) => {
      return acc + +value * 2 ** (6 - index);
    }, 0);
    const property = 'day_off';
    dispatch(setSettings.request({ value, property }));
  }, []);

  return {
    daySettings,
    isSettingsLoading,
    isChanging,
    setFilterValue,
    setDayOff,
    setWorkHours,
    setFilterBoolean,
    setStartWorkTime,
  };
};
