import styled from 'styled-components';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import React from 'react';

export const Hint = styled.div`
  margin-top: 20px;
  color: ${(props) => props.theme.textColor};
  font-size: 18px;
  font-weight: normal;

  @media (max-height: 670px) {
    margin-top: 17px;
  }
`;

export const AltBody = styled(Hint)`
  font-size: 24px;

  @media (max-height: 670px) {
    margin-top: 21px;
  }
`;

const ValueContainer = styled.div`
  margin-top: 20px;
  display: inline-flex;
  padding: 10px;
  min-width: 180px;
  color: ${(props) => props.theme.textColor};
  font-weight: bold;
  background: ${({ theme }) => theme.grid.oddRow};
  align-items: center;
  justify-content: space-between;
  min-height: 28px;

  svg:first-child {
    margin-right: 12px;
  }

  svg:last-child {
    margin-left: 12px;
  }
`;

export const Value: React.FC<{
  onClick(): void;
  children: React.ReactNode;
}> = function ({ onClick, children }) {
  return (
    <ValueContainer onClick={onClick}>
      <ArrowDownward />
      {children}
      <ArrowUpward />
    </ValueContainer>
  );
};

export const Body = styled.div`
  font-weight: bold;
`;
export const SwitchContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  padding: 10px;

  > div {
    margin: 0 20px;
  }
`;

export const StepBody = styled.div`
  min-height: 310px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;

  @media (max-height: 670px) {
    min-height: 250px;
    font-size: 21px;
  }
`;

export const No = styled.span`
  color: rgb(116 115 115) !important;
  font-weight: bold;
`;

export const Yes = styled.span`
  color: #05af50 !important;
  font-weight: bold;
`;
