import React, { useCallback } from 'react';

import Select from '../../../common/Select';
import { LoadingProp } from '../styled';
import { SearchFilter } from '../interfaces';

interface FilterSelectProps {
  title: string;
  value: any;
  isChanging: boolean;
  handleChange(value: object): void;
  min: number;
  max: number;
  step: number;
  prefix?: string;
  postfix?: string;
  hasZero?: boolean;
  filterName: SearchFilter;
  printValueMap?: {
    [key: number]: string;
  } | null;
}

const getPrintValue = (printValueMap, value) => {
  if (!printValueMap) {
    return value;
  }

  if (printValueMap[value]) {
    return printValueMap[value];
  }

  return value;
};

const FilterSelect = function ({
  title,
  value,
  handleChange,
  isChanging,
  prefix = '',
  postfix = '',
  min,
  max,
  step,
  printValueMap = null,
  hasZero = false,
  filterName,
}: FilterSelectProps) {
  const handleChangeValue = useCallback(
    (value) => {
      const { filterValue } = value;

      if (!printValueMap) {
        handleChange(value);
        return;
      }

      const selectedOption = Object.keys(printValueMap).reduce((acc, key) => {
        if (printValueMap[key] === filterValue) {
          return key;
        }

        return acc;
      }, '');

      if (!selectedOption) {
        handleChange(value);
        return;
      }

      handleChange({ filterValue: selectedOption });
    },
    [printValueMap]
  );

  if (isChanging) {
    return <LoadingProp />;
  }

  const options: string[] = [];
  const printedSelectedValue = getPrintValue(printValueMap, value);
  const selected = {
    filterValue:
      value !== null
        ? printedSelectedValue === value
          ? `${prefix}${value}${postfix}`
          : printedSelectedValue
        : '>',
  };

  if (hasZero) {
    options.push(`${getPrintValue(printValueMap, 0)}`);
  }

  for (let value = min; value <= max; value += step) {
    const printedValue = getPrintValue(printValueMap, value);
    options.push(printedValue === value ? `${prefix}${value}${postfix}` : printedValue);
  }

  return (
    <Select
      title={title}
      handleChange={handleChangeValue}
      optionGroups={{ filterValue: options }}
      valueGroups={selected}
      filterName={filterName}
    />
  );
};

export default React.memo(FilterSelect);
