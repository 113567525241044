import styled from 'styled-components';

import { vh100 } from 'config/css';

export const Container = styled.div`
  margin: 0;
  padding: 0;
  position: relative;
  bottom: 0;
  z-index: 1500;
`;

interface MaskProps {
  showSidebar: boolean;
  animationTime: string;
}

export const Mask = styled.div<MaskProps>`
  top: 0;
  right: 0;
  z-index: 150;
  height: calc(${vh100});
  background: ${({ showSidebar, theme }) => (showSidebar ? theme.modal.overlay : 'none')};
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;

  animation-name: ${({ showSidebar }) =>
    showSidebar ? 'animate-background-show' : 'animate-background-hide'};
  animation-duration: ${({ animationTime }) => animationTime};

  @keyframes animate-background-show {
    from {
      background: none;
    }
    to {
      background: ${(props) => props.theme.modal.overlay};
    }
  }

  @keyframes animate-background-hide {
    from {
      background: ${(props) => props.theme.modal.overlay};
    }
    to {
      background: none;
    }
  }
`;

interface BodyProps {
  showSidebar: boolean;
  animationTime: string;
}

export const Body = styled.div<BodyProps>`
  position: absolute;
  top: 0;
  left: ${({ showSidebar }) => (showSidebar ? '0' : '-220px')};
  width: 220px;
  z-index: 200;
  background: ${({ theme }) => theme.sidebar.background};
  height: calc(${vh100});

  overflow: hidden;
  animation-name: ${({ showSidebar }) =>
    showSidebar ? 'animate-sidebar-show' : 'animate-sidebar-hide'};
  animation-duration: ${({ animationTime }) => animationTime};

  @keyframes animate-sidebar-show {
    from {
      left: -220px;
    }
    to {
      left: 0;
    }
  }
  @keyframes animate-sidebar-hide {
    from {
      left: 0;
    }
    to {
      left: -220px;
    }
  }
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: calc(${vh100});
`;

export const UserPhotoWrap = styled.div<UserPhotoProps>`
  position: relative;
`;

interface UserPhotoProps {
  photo?: string;
}

export const UserPhoto = styled.div<UserPhotoProps>`
  height: 48px;
  cursor: pointer;
  background: url('${({ photo }) => photo}') no-repeat center center;
  background-size: cover;
  width: 48px;
  border-radius: 50%;
  margin: 12px auto;
`;

export const UserId = styled.div`
  text-align: center;
  margin-bottom: 6px;
  font-weight: bold;
`;

export const MenuItemsHeader = styled.span`
  padding: 10px;
  text-align: left;
  font-size: 18px;
  font-weight: bold;
  display: flex;
`;

export const MainItem = styled.div`
  background: ${({ theme }) => theme.sidebar.creditsBackground};

  a {
    text-decoration: none;
    color: ${(props) => props.theme.altText} !important;
    font-size: 20px;
    font-weight: bold;
    padding: 16px 10px;
    display: flex;
    align-items: center;
  }

  a:visited {
    color: #fff;
  }

  span {
    margin-right: 6px;
  }

  svg {
    max-width: 32px;
    max-height: 32px;
  }

  margin-bottom: 12px;
`;

export const MenuItem = styled.div`
  text-align: left;

  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 10px;
    flex: 1;
    font-size: 16px;
    font-weight: bold;
  }

  a,
  a:visited {
    color: ${({ theme }) => theme.sidebar.link};
  }

  span:first-child {
    margin-right: 4px;
    position: relative;
    bottom: -1px;
  }

  svg {
    max-width: 22px;
    max-height: 22px;
  }

  svg path {
    fill: ${({ theme }) => theme.sidebar.link};
  }
`;

export const RateItem = styled.div`
  background: ${({ theme }) => theme.sidebar.creditsBackground};
  margin-top: auto;
  margin-bottom: 12px;
  font-size: 18px;
  font-weight: bold;
  padding: 10px;

  a {
    color: ${(props) => props.theme.altText} !important;
  }

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: center;

    span:last-child {
      flex: 1;
    }
  }

  svg {
    max-height: 24px;
    max-width: 24px;

    .fil0 {
      fill: #fff;
    }
  }
`;

export const RideCredits = styled.div`
  text-align: center;
  margin-bottom: 12px;

  span {
    font-size: 18px;
    margin-left: 4px;
  }

  span span {
    position: relative;
    top: 2px;
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-left: 0;
  }
`;
