import React, { useCallback, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Menu from '@material-ui/icons/Menu';

import { useTranslation } from 'helpers/hooks';
import { actionCreators } from 'store/app/acions';
import { AppState } from 'store/interfaces';

import { BackButton, HeaderContainerStyled, TitleStyled } from '../styled';
import { LoadingProp } from '../../../../core/settings/styled';
import { ThemeSwitcher } from '../ThemeSwitcher';

import { HeaderProps } from './interfaces';
import { TopHeader, HeaderTitle, DriverPhotoWrap, DriverContainer, DriverCredits } from './styled';

const LoadingPropsStyle = {
  display: 'inline-block',
  width: 6,
  height: 6,
  marginLeft: 0,
  top: 3,
};

const Header: React.FC<HeaderProps> = function ({ title, subTitle, onPressBack }) {
  const dispatch = useDispatch();
  const { user, rideCredits } = useSelector(
    (app: AppState) => ({
      user: app.auth.user,
      rideCredits: app.app.rideCredits,
    }),
    shallowEqual
  );

  const { t } = useTranslation();

  const toggleSidebar = useCallback(
    () => dispatch(actionCreators.toggleSidebar()),
    [dispatch, actionCreators.toggleSidebar]
  );

  useEffect(() => {
    if (subTitle) {
      document.title = subTitle;
      return;
    }

    document.title = title;
  }, [title, subTitle]);

  if (subTitle) {
    return (
      <HeaderContainerStyled>
        <TitleStyled>
          <BackButton onClick={onPressBack}>
            <img style={{ height: 24 }} alt="left" src="/assets/images/left.png" />
          </BackButton>
          <span>{subTitle}</span>
        </TitleStyled>
      </HeaderContainerStyled>
    );
  }

  return (
    <TopHeader>
      <DriverContainer onClick={toggleSidebar}>
        <DriverPhotoWrap userPhoto={user && user.user_photo ? user.user_photo : ''}>
          <div className="driver-icon" />
          <span className="menu-icon">
            <Menu fontSize="large" />
          </span>
        </DriverPhotoWrap>
        <DriverCredits>
          {rideCredits === null || Number.isNaN(rideCredits) ? (
            <LoadingProp style={LoadingPropsStyle} />
          ) : (
            <>
              {rideCredits} {t('header.credits')}
            </>
          )}
        </DriverCredits>
      </DriverContainer>
      <HeaderTitle style={{ marginBottom: 20 }}>{title}</HeaderTitle>
      <ThemeSwitcher />
    </TopHeader>
  );
};

export default React.memo(Header);
