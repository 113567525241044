import styled from 'styled-components';

import { vh100 } from '../../../config/css';

interface ContainerProps {
  showLogo: boolean;
  style?: object;
  hide?: boolean;
}

export const Container = styled.div<ContainerProps>`
  height: calc(${vh100});
  background: ${({ theme }) => theme.background}
    ${({ showLogo, theme }) => showLogo && `url("${theme.backgroundLogo}") 100% 100% no-repeat`};
  background-size: 40vh auto;
  overflow: auto;
  margin: 0 auto;
  max-width: 600px;

  display: ${({ hide }) => (hide ? 'none' : 'block')};

  @media (max-height: 600px) {
    background: ${({ theme }) => theme.background} !important;
  }
`;
