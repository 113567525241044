import { call, fork, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { logout } from '../user/actions';
import API from '../../api';
import { messages } from '../../i18n';
import { setSearchStatus } from '../app/acions';
import { Settings } from '../../types/settings';
import { nativeMessageService } from '../../services/NativeMessageService';

import { actionCreators } from './actions';

const getSettingsFromResponse = (response: Settings): Settings => {
  const {
    begin_work,
    end_work,
    radius,
    min_dist,
    ride_replacement,
    dropoff_state,
    pickup_state,
    day_off,
    ride_types,
  } = response;

  return {
    begin_work,
    end_work,
    radius,
    min_dist,
    ride_replacement,
    dropoff_state,
    pickup_state,
    day_off,
    ride_types,
  };
};

function* getSettings() {
  const response = yield call(API.settings.get);

  switch (response.code) {
    case 200: {
      const settings = getSettingsFromResponse(response);
      nativeMessageService.sendFilterMessage(JSON.stringify(settings));
      yield put(
        actionCreators.getSettings.success({
          ...settings,
        })
      );

      break;
    }
    case 401: {
      toast.error(messages.sessionTimeout);
      yield put(logout());
      break;
    }
    case 422: {
      yield put(actionCreators.getSettings.failure(response.errors));
      break;
    }
    default: {
      // toast.error(messages.serverError, { type: 'default' });
      yield put(actionCreators.getSettings.failure({ errors: {} }));
      break;
    }
  }
}

function* setSettings({ payload: { value, property, startSearch } }) {
  const formData = new FormData();

  formData.set(property, `${value}`);

  const response = yield call(API.settings.set, formData);

  switch (response.code) {
    case 200: {
      // eslint-disable-next-line no-shadow
      const settings = getSettingsFromResponse(response);
      nativeMessageService.sendFilterMessage(JSON.stringify(settings));
      yield put(
        actionCreators.setSettings.success({
          settings,
        })
      );

      if (startSearch) {
        yield put(setSearchStatus.request(true));
      }

      break;
    }
    case 401: {
      toast.error(messages.sessionTimeout);
      yield put(logout());
      break;
    }
    case 422: {
      const message = Object.keys(response.errors).reduce((acc, property) => {
        // @ts-ignore
        acc.push(response.errors[property]);
        return acc;
      }, []);
      toast.error(message.join(''));
      yield put(actionCreators.setSettings.failure({ errors: response.errors }));
      break;
    }
    default: {
      // toast.error(messages.serverError, { type: 'default' });
      yield put(actionCreators.setSettings.failure({ property, errors: {} }));
      break;
    }
  }
}

export function* watchGetSettings() {
  yield takeLatest(actionCreators.getSettings.REQUEST, getSettings);
}

export function* watchSetSettings() {
  yield takeLatest(actionCreators.setSettings.REQUEST, setSettings);
}

export default function* flow() {
  yield fork(watchGetSettings);
  yield fork(watchSetSettings);
}
