import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Picker from '../ReactMobilePicker';
import { SettingsModal } from '../SettingsModal';
import { SearchFilter } from '../../core/settings/interfaces';
import { Events, eventsService, ToggleSettingsFilterEvent } from '../../services/EventsService';
import { checkSearchAndRunCallback } from '../../store/app/acions';

interface PickerOptions {
  [key: string]: Array<string>;
}
export interface PickerValues {
  [key: string]: string;
}

interface SelectProps {
  optionGroups: PickerOptions;
  valueGroups: PickerValues;
  title: string;
  handleChange: (value: object) => void;
  filterName: SearchFilter;
}

interface SelectState {
  isShowed: boolean;
  optionGroups: PickerOptions;
  valueGroups: PickerValues;
  savedValue: object;
}

export class Select extends React.PureComponent<SelectProps & WithTranslation, SelectState> {
  constructor(props: SelectProps & WithTranslation) {
    super(props);

    this.state = {
      isShowed: false,
      valueGroups: {
        ...props.valueGroups,
      },
      optionGroups: {
        ...props.optionGroups,
      },
      savedValue: {
        ...props.valueGroups,
      },
    };
  }

  componentDidMount() {
    eventsService.on(Events.ToggleSettingsFilter, this.toggleSearchPicker, true);
  }

  componentWillUnmount() {
    eventsService.off(Events.ToggleSettingsFilter, this.toggleSearchPicker, true);
  }

  toggleSearchPicker = (event: ToggleSettingsFilterEvent) => {
    const { filterName } = event.detail;

    if (this.props.filterName !== filterName) {
      return;
    }

    if (this.state.isShowed) {
      return;
    }

    // @ts-ignore
    this.props?.dispatch(checkSearchAndRunCallback(() => this.togglePicker()));
  };

  handleChange = (name: string, value: string) => {
    this.setState(({ valueGroups }) => ({
      valueGroups: {
        ...valueGroups,
        [name]: value,
      },
    }));
  };

  togglePicker = () => {
    this.setState(({ isShowed }: SelectState) => ({ isShowed: !isShowed }));
  };

  applyPicker = () => {
    this.setState(({ isShowed, valueGroups }: SelectState) => ({
      isShowed: !isShowed,
      savedValue: { ...valueGroups },
    }));
    this.props.handleChange(this.state.valueGroups);
  };

  render() {
    const { optionGroups, valueGroups, savedValue, isShowed } = this.state;
    const savedValueKeys = Object.keys(this.props.valueGroups);
    const isTime =
      savedValueKeys.length === 3 &&
      savedValueKeys.filter((key) => savedValue[key] !== '').length === 3;

    return (
      <>
        <span style={{ cursor: 'pointer', fontWeight: 'bold' }}>
          {savedValueKeys.map((key, index) => (
            <span
              key={`${index}-${key}-${this.props.valueGroups[key]}`}
              dangerouslySetInnerHTML={{
                __html: `${this.props.valueGroups[key]}${index === 0 && isTime ? ':' : ' '}`,
              }}
            />
          ))}
        </span>
        {isShowed && (
          <SettingsModal
            title={this.props.title}
            onClose={this.togglePicker}
            onApply={this.applyPicker}
          >
            <Picker
              optionGroups={optionGroups}
              valueGroups={valueGroups}
              onChange={this.handleChange}
            />
          </SettingsModal>
        )}
      </>
    );
  }
}

export default withTranslation()(connect()(Select));
