import React from 'react';
import { useDispatch } from 'react-redux';

import { sendEvent } from '../../../services/reactGa';
import { setSettings } from '../../../store/settings/actions';
import { settingsRecommendationsService } from '../../../services/SettingsRecommendationsService';
import { useTranslation } from '../../../helpers/hooks';
import { CarouselItem } from '../../../common/Carousel';
import { useToggleModalMap } from '../hooks';
import { Text } from '../styled';

export const MaxMilesToPickup = React.memo(() => {
  const toggleModalsMap = useToggleModalMap();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <CarouselItem
      onMount={() => sendEvent('DriverRecommendations_MaxMilesToPickup_Show')}
      onClose={() => {
        sendEvent('DriverRecommendations_MaxMilesToPickup_RemindLater');
        toggleModalsMap({ maxMilesToPickup: false });
      }}
      onApply={() => {
        sendEvent('DriverRecommendations_MaxMilesToPickup_Apply');
        dispatch(
          setSettings.request({
            value: settingsRecommendationsService.getRecommendedRadius(),
            property: 'radius',
          })
        );
        toggleModalsMap({
          maxMilesToPickup: false,
          maxMilesToPickupSuccess: true,
        });
      }}
      applyText={t('modals.recommendations.maxMilesToPickup.applyButton')}
      cancelText={t('modals.recommendations.maxMilesToPickup.cancelButton')}
    >
      <Text>{t('modals.recommendations.maxMilesToPickup.body1')}</Text>
      <p
        dangerouslySetInnerHTML={{
          __html: t('modals.recommendations.maxMilesToPickup.body2', {
            miles: `${settingsRecommendationsService.getRecommendedRadius()}`,
          }),
        }}
      />
    </CarouselItem>
  );
});
