export function apiCallDecorator(apiCall: { call: (arg0: any, arg1: any) => any }) {
  return async function (...args: any) {
    try {
      // @ts-ignore
      const response = await apiCall.call(this, ...args);
      return response.data.data;
    } catch (e) {
      if (!e.response) {
        return { code: 502 };
      }
      return e.response.data.data;
    }
  };
}

export const generateAndroidIdentifiers = (xAccountIdentifierToken: string) => {
  return [
    {
      type: 'android_backup_token',
      source: 'lyft_android_app',
      name: xAccountIdentifierToken,
    },
  ];
};
