import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { useTranslation } from 'helpers/hooks';
import { logout } from 'store/user/actions';
import Modal from 'common/Modal';

import { registerLogoutEvent } from '../../../../../services/reactGa';

export const Logout: React.FC = React.memo(() => {
  const [modal, setModal] = useState<React.ReactNode | null>(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleLogout = useCallback(
    (e) => {
      e.preventDefault();

      const closeModal = () => setModal(null);

      const applyLogout = () => {
        registerLogoutEvent();
        dispatch(logout());
        closeModal();
      };

      setModal(
        <Modal
          onApply={applyLogout}
          applyText={t('header.logoutModal.apply')}
          cancelText={t('header.logoutModal.cancel')}
          onClose={closeModal}
        >
          <div style={{ textAlign: 'center' }}>{t('header.logoutModal.content')}</div>
        </Modal>
      );
    },
    [setModal, t, dispatch]
  );

  return (
    <>
      {modal}
      <NavLink to="" onClick={handleLogout}>
        {t('header.logout')}
      </NavLink>
    </>
  );
});
