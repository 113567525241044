import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import API from '../../api';
// import { messages } from '../i18n';
import i18n from '../../i18n';
import storageService, { StorageKeys } from '../../services/StorageService';
import { registerLoginEvent } from '../../services/reactGa';
import { AppState, VerificationStep } from '../interfaces';
import { singularClient } from '../../services/SingularClient';
import { setWizardStep } from '../app/acions';
import { isAndroid } from '../../helpers/os';
import { getMarkers } from '../map/actions';
import { nativeMessageService } from '../../services/NativeMessageService';
import { generateAndroidIdentifiers } from '../../api/helpers';
import { isDebugEnabled } from '../../common/isDebugEnables';

import { actionCreators } from './actions';

const makeResponseFulfill = (response) => ({
  data: response?.data,
  status: response?.status,
});

function* handleGetAuthParams() {
  try {
    const response = yield call(API.authV2.getParams);
    const {
      access_token_license: accessTokenLicense,
      access_token_params: accessTokenParams,
      access_token_phone: accessTokenPhone,
      account_identifiers: accountIdentifiers,
      phone_auth: phoneAuth,
    } = response;

    yield put(
      actionCreators.getAuthParams.success({
        accessTokenLicense,
        accessTokenParams,
        accessTokenPhone,
        accountIdentifiers,
        phoneAuth,
      })
    );
    yield put(actionCreators.getLyftParams.request());
  } catch (e) {
    yield put(actionCreators.getLyftParams.failure());
  }
}
function* handleGetLyftParams() {
  const { authHeaders } = yield select((app: AppState) => app.userForms.verification);
  try {
    const response = yield call(API.lyftApi.access_token, {
      headers: authHeaders.accessTokenParams,
      type: 'getLyftParams',
    });

    if (isAndroid) {
      return;
    }

    yield put(actionCreators.getLyftParams.fulfill(makeResponseFulfill(response)));
  } catch (e) {
    yield put(actionCreators.getLyftParams.fulfill(makeResponseFulfill(e.response)));
  }
}

function* handleGetLyftParamsFulfill(action) {
  const { response } = action.payload;

  if (response.status < 300) {
    const { token_type, access_token } = response.data;
    yield put(actionCreators.getLyftParams.success({ token_type, access_token }));
    return;
  }

  yield put(actionCreators.getLyftParams.failure());
}

function* verifyPhoneNumber({ payload }) {
  const { value } = payload;
  const { token_type, access_token, authHeaders } = yield select(
    (app: AppState) => app.userForms.verification
  );
  try {
    const response = yield call(API.lyftApi.phoneAuth, {
      token_type,
      access_token,
      headers: authHeaders.phoneAuth,
      phone: value,
      type: 'verifyPhoneNumber',
    });

    if (isAndroid) {
      return;
    }

    yield put(actionCreators.verifyPhoneNumber.fulfill(makeResponseFulfill(response)));
  } catch (e) {
    console.log(e);
    yield put(actionCreators.verifyPhoneNumber.fulfill(makeResponseFulfill(e.response)));
  }
}

function* verifyPhoneNumberFulfill(action) {
  const { response } = action.payload;
  const { data, status } = response;

  const { step } = yield select((app: AppState) => app.userForms.verification);

  if (step !== VerificationStep.phoneNumber) {
    return;
  }

  if (status < 300) {
    yield put(actionCreators.setStep(VerificationStep.verificationCode));
    return;
  }

  yield put(
    actionCreators.verifyPhoneNumber.failure(
      data?.error_description || i18n.t('verification.phoneNumber.error')
    )
  );
}

function* verifyVerificationCode({ payload }) {
  const { value } = payload;

  const { phone_number, authHeaders } = yield select((app: AppState) => app.userForms.verification);

  try {
    const response = yield call(API.lyftApi.token, {
      phone: phone_number,
      code: value,
      headers: authHeaders.accessTokenPhone,
      type: 'verifyVerificationCode',
    });

    if (isAndroid) {
      return;
    }

    yield put(actionCreators.verifyVerificationCode.fulfill(makeResponseFulfill(response)));
  } catch (e) {
    yield put(actionCreators.verifyVerificationCode.fulfill(makeResponseFulfill(e.response)));
  }
}

function* verifyVerificationCodeFulfill(action) {
  const { response } = action.payload;
  const { data, status } = response;

  const { step } = yield select((app: AppState) => app.userForms.verification);

  if (step !== VerificationStep.verificationCode && !isDebugEnabled()) {
    return;
  }

  if (status === 401 && data?.challenges[0]?.identifier === 'drivers_license_number') {
    yield put(actionCreators.setStep(VerificationStep.licenseNumber));
    return;
  }

  if (status === 200 && response?.data?.access_token) {
    yield put(actionCreators.verifyLicenceNumber.fulfill(makeResponseFulfill(response)));
  }
}

function* verifyLicenceNumber({ payload }) {
  const { value } = payload;

  const { phone_number, authHeaders, verification_code } = yield select(
    (app: AppState) => app.userForms.verification
  );

  try {
    const response = yield call(API.lyftApi.token, {
      phone: phone_number,
      code: verification_code,
      headers: authHeaders.accessTokenLicense,
      drivers_license_number: value,
      type: 'verifyLicenceNumber',
    });

    if (isAndroid) {
      return;
    }

    yield put(actionCreators.verifyLicenceNumber.fulfill(makeResponseFulfill(response)));
  } catch (e) {
    yield put(actionCreators.verifyLicenceNumber.fulfill(makeResponseFulfill(e.response)));
  }
}

function* verifyLicenceNumberFulfill(action) {
  const { response } = action.payload;
  const { authHeaders, step } = yield select((app: AppState) => app.userForms.verification);

  if (step !== VerificationStep.licenseNumber && !response?.data?.access_token) {
    return;
  }

  if (response.status < 300) {
    const { extension_code, expires_in, access_token, token_type, user_id, refresh_token } =
      response.data;

    try {
      const { phone_number, license_number } = yield select(
        (app: AppState) => app.userForms.verification
      );

      const response1 = yield call(API.authV2.auth, {
        phone_number,
        drivers_license_number: license_number,
        token_type,
        access_token,
        expires_in,
        refresh_token,
        user_id,
        extension_code,
      });

      const { auth_key, user_photo, user_id: skeddyUserId } = response1;

      if (auth_key && skeddyUserId) {
        singularClient.login(skeddyUserId);

        registerLoginEvent();

        storageService.setRegistrationData({
          phone_number,
          license_number,
        });

        const isRegistered = storageService.getItem(StorageKeys.isRegistered);

        yield put(actionCreators.verifyLicenceNumber.success());
        yield put(actionCreators.setUser({ user_id: skeddyUserId, auth_key, user_photo }));
        yield put(getMarkers.request());

        if (isAndroid) {
          const xAccountIdentifierToken = storageService.getXAccountIdentifierToken();

          if (xAccountIdentifierToken) {
            const identifiersHeaders = {
              ...authHeaders.accountIdentifiers,
            };
            identifiersHeaders.authorization = `${token_type} ${access_token}`;

            nativeMessageService.sendNetworkRequestMessage({
              url: 'https://api.lyft.com/v1/accountidentifiers',
              headers: identifiersHeaders,
              params: {
                identifiers: generateAndroidIdentifiers(xAccountIdentifierToken),
              },
              method: 'post',
              type: 'accountidentifiers',
            });
          }
        }

        if (!isRegistered) {
          yield put(setWizardStep('intro'));
        }
      }
    } catch (e) {
      // #TODO add show error
      console.log(e);
    }

    return;
  }

  yield put(
    actionCreators.verifyLicenceNumber.failure(
      'The DL number you entered does not match Lyft records. Please, enter the correct DL number.'
    )
  );
}

function* handleLogout() {
  singularClient.logout();
  yield call(storageService.clear);
}

export function* watchUserLogout() {
  yield takeLatest(actionCreators.logout.type, handleLogout);
}

export function* watchGetAuthParams() {
  yield takeLatest(actionCreators.getAuthParams.REQUEST, handleGetAuthParams);
}

export function* watchGetLyftParams() {
  yield takeLatest(actionCreators.getLyftParams.REQUEST, handleGetLyftParams);
}
export function* watchGetLyftParamsFulfill() {
  yield takeLatest(actionCreators.getLyftParams.FULFILL, handleGetLyftParamsFulfill);
}

export function* watchVerifyPhoneNumber() {
  yield takeLatest(actionCreators.verifyPhoneNumber.REQUEST, verifyPhoneNumber);
}

export function* watchVerifyPhoneNumberFulfill() {
  yield takeLatest(actionCreators.verifyPhoneNumber.FULFILL, verifyPhoneNumberFulfill);
}

export function* watchVerifyVerificationCode() {
  yield takeLatest(actionCreators.verifyVerificationCode.REQUEST, verifyVerificationCode);
}

export function* watchVerifyVerificationCodeFulfill() {
  yield takeLatest(actionCreators.verifyVerificationCode.FULFILL, verifyVerificationCodeFulfill);
}

export function* watchVerifyLicenceNumber() {
  yield takeLatest(actionCreators.verifyLicenceNumber.REQUEST, verifyLicenceNumber);
}

export function* watchVerifyLicenceNumberFulfill() {
  yield takeLatest(actionCreators.verifyLicenceNumber.FULFILL, verifyLicenceNumberFulfill);
}

export default function* flow() {
  yield fork(watchGetAuthParams);
  yield fork(watchGetLyftParams);
  yield fork(watchGetLyftParamsFulfill);
  yield fork(watchVerifyVerificationCode);
  yield fork(watchVerifyVerificationCodeFulfill);
  yield fork(watchVerifyLicenceNumber);
  yield fork(watchVerifyLicenceNumberFulfill);
  yield fork(watchVerifyPhoneNumber);
  yield fork(watchVerifyPhoneNumberFulfill);
  yield fork(watchUserLogout);
}
