import { call, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import API from 'api';
import { defaultPin } from 'config/settings';
import { Marker, MarkerStatus } from 'types/marker';
import { messages } from 'i18n';
import { getAddressByCoords } from 'common/getAddressByCoords';
import { getBestRides as loadBestRides } from 'common/getBestRides';

import { logout } from '../user/actions';
import { AppState } from '../interfaces';
import { getPlaces } from '../../common/getPlaces';

import { actionCreators } from './actions';

interface BuildMarkerFormProps {
  lat: number | string;
  lng: number | string;
  status: MarkerStatus;
  address: string;
}

const buildMarkerForm = ({ lat, lng, status, address }: BuildMarkerFormProps) => {
  const formData = new FormData();

  formData.append('lat', `${lat}`);
  formData.append('lng', `${lng}`);
  formData.append('status', `${status}`);
  formData.append('address', `${address}`);

  return formData;
};

function* getMarkers() {
  const response = yield call(API.markers.list);

  switch (response.code) {
    case 200: {
      const { items } = response;
      const formParams: number[] = [];

      if (items.length === 0) {
        try {
          const { coords } = yield call(async () => {
            // @ts-ignore
            return await new Promise((resolve, reject) => {
              navigator.geolocation.getCurrentPosition(resolve, reject, {
                enableHighAccuracy: true,
                timeout: 10000,
                maximumAge: 5000,
              });
            });
          });
          const { latitude, longitude } = coords;
          formParams.push(latitude, longitude);
        } catch (e) {
          formParams.push(+defaultPin.lat, +defaultPin.lng);
        }

        const [lat, lng] = formParams;
        const address = yield getAddressByCoords({ lat, lng });
        yield put(
          actionCreators.createMarker.request(
            buildMarkerForm({ lat, lng, status: MarkerStatus.active, address })
          )
        );
      }

      yield put(actionCreators.getMarkers.success(items as Array<Marker>));
      break;
    }
    case 401: {
      toast.error(messages.sessionTimeout);
      yield put(logout());
      break;
    }
    case 404: {
      yield put(actionCreators.getMarkers.success([]));
      break;
    }
    case 422: {
      yield put(actionCreators.getMarkers.failure());
      break;
    }
    default: {
      // toast.error(messages.serverError, { type: 'default' });
      yield put(actionCreators.getMarkers.failure());
      break;
    }
  }
}

function* deleteMarker({ payload }) {
  const { id } = payload;
  const response = yield call(API.markers.delete, id);

  switch (response.code) {
    case 200: {
      const { items } = response;
      yield put(actionCreators.deleteMarker.success(id, items));
      break;
    }
    case 401: {
      toast.error(messages.sessionTimeout);
      yield put(logout());
      break;
    }
    case 404: {
      yield put(actionCreators.deleteMarker.failure(id, response.code));
      break;
    }
    default: {
      // toast.error(messages.serverError, { type: 'default' });
      yield put(actionCreators.deleteMarker.failure(id, response.code));
      break;
    }
  }
}

function* updateMarker({ payload }) {
  const { id, formData } = payload;

  const { markerForReplace } = yield select((state: AppState) => ({
    markerForReplace: state.map.markerForReplace,
  }));

  if (markerForReplace) {
    const formData = new URLSearchParams();

    formData.append('lat', markerForReplace.lat);
    formData.append('lng', markerForReplace.lng);
    formData.append('address', markerForReplace.address);
    formData.append('status', `${MarkerStatus.inactive}`);

    const response = yield call(API.markers.update, markerForReplace.id, formData);
    console.log({ response });
  }

  const response = yield call(API.markers.update, id, formData);

  switch (response.code) {
    case 200: {
      const { items } = response;
      yield put(actionCreators.updateMarker.success(id, items));
      break;
    }
    case 401: {
      toast.error(messages.sessionTimeout);
      yield put(logout());
      break;
    }
    case 404: {
      yield put(actionCreators.updateMarker.failure(id, response.code));
      break;
    }
    default: {
      // toast.error(messages.serverError, { type: 'default' });
      yield put(actionCreators.updateMarker.failure(id, response.code));
      break;
    }
  }
}

function* createMarker({ payload }) {
  const { formData } = payload;
  const address = formData.get('address');

  const { markers, markerForReplace } = yield select((state: AppState) => ({
    markers: state.map.markers,
    markerForReplace: state.map.markerForReplace,
  }));

  if (markerForReplace) {
    console.log('create', { markerForReplace });
    const formData = new URLSearchParams();

    formData.append('lat', markerForReplace.lat);
    formData.append('lng', markerForReplace.lng);
    formData.append('address', markerForReplace.address);
    formData.append('status', `${MarkerStatus.inactive}`);

    const response = yield call(API.markers.update, markerForReplace.id, formData);
    console.log({ response });
  }

  if (!address) {
    if (markers.length === 0) {
      const address = yield getAddressByCoords({ lat: defaultPin.lat, lng: defaultPin.lng });
      yield put(
        actionCreators.createMarker.request(
          buildMarkerForm({
            lat: defaultPin.lat,
            lng: defaultPin.lng,
            status: formData.get('status'),
            address,
          })
        )
      );
    }
    yield put(actionCreators.createMarker.failure());
    return;
  }

  formData.append('address', address);

  const response = yield call(API.markers.create, formData);

  switch (response.code) {
    case 200: {
      const { items } = response;
      yield put(actionCreators.createMarker.success(items));
      break;
    }
    case 401: {
      toast.error(messages.sessionTimeout);
      yield put(logout());
      break;
    }
    case 422: {
      // suppose PIN is not IN USA, let's create one for user if he has none
      if (markers.length === 0) {
        const address = yield getAddressByCoords({ lat: defaultPin.lat, lng: defaultPin.lng });
        yield put(
          actionCreators.createMarker.request(
            buildMarkerForm({
              lat: defaultPin.lat,
              lng: defaultPin.lng,
              status: formData.get('status'),
              address,
            })
          )
        );
      }
      break;
    }
    default: {
      // toast.error(messages.serverError, { type: 'default' });
      yield put(actionCreators.createMarker.failure());
      break;
    }
  }
}

function* getClusters({ payload }) {
  const { lat, lng } = payload;
  const response = yield call(API.markers.clustersList, lat, lng);

  switch (response.code) {
    case 200: {
      const { clusters } = response;
      yield put(actionCreators.getClusters.success(clusters || []));
      break;
    }
    case 401: {
      toast.error(messages.sessionTimeout);
      yield put(logout());
      break;
    }
    default: {
      yield put(actionCreators.updateMarker.failure());
      break;
    }
  }
}

function* getRadius() {
  const response = yield call(API.settings.get);

  switch (response.code) {
    case 200: {
      const { radius } = response;
      yield put(actionCreators.getRadius.success(radius));
      break;
    }
    case 401: {
      toast.error(messages.sessionTimeout);
      yield put(logout());
      break;
    }
    default: {
      yield put(actionCreators.getRadius.failure());
      break;
    }
  }
}

function* getBestRides() {
  const response = yield call(loadBestRides);

  switch (response.code) {
    case 200: {
      yield put(actionCreators.getBestRides.success(response.rides));
      break;
    }
    case 401: {
      toast.error(messages.sessionTimeout);
      yield put(logout());
      break;
    }
    case 422: {
      yield put(actionCreators.getBestRides.failure());
      break;
    }
    default: {
      // toast.error(messages.serverError, { type: 'default' });
      yield put(actionCreators.getBestRides.failure());
      break;
    }
  }
}

function* getPlacesBySearch({ payload }) {
  const { search } = payload;

  const formData = new FormData();
  formData.append('data[service]', 'photon');
  formData.append('data[data][q]', search);

  const response = yield call(API.getInformation, formData);

  switch (response.status) {
    case 200: {
      const places = getPlaces(response);
      yield put(actionCreators.getPlacesBySearch.success(Object.values(places)));
      break;
    }
    case 401: {
      toast.error(messages.sessionTimeout);
      yield put(logout());
      break;
    }
    case 422: {
      yield put(actionCreators.getPlacesBySearch.failure());
      break;
    }
    default: {
      // toast.error(messages.serverError, { type: 'default' });
      yield put(actionCreators.getPlacesBySearch.failure());
      break;
    }
  }
}

export function* watchGetMarkers() {
  yield takeLatest(actionCreators.getMarkers.REQUEST, getMarkers);
}

export function* watchDeleteMarker() {
  yield takeEvery(actionCreators.deleteMarker.REQUEST, deleteMarker);
}

export function* watchUpdateMarker() {
  yield takeEvery(actionCreators.updateMarker.REQUEST, updateMarker);
}

export function* watchCreateMarker() {
  yield takeEvery(actionCreators.createMarker.REQUEST, createMarker);
}
export function* watchGetRadius() {
  yield takeEvery(actionCreators.getRadius.REQUEST, getRadius);
}

export function* watchGetClusters() {
  yield takeLatest(actionCreators.getClusters.REQUEST, getClusters);
}

export function* watchGetBestRides() {
  yield takeLatest(actionCreators.getBestRides.REQUEST, getBestRides);
}
export function* watchGetPlacesBySearch() {
  yield takeLatest(actionCreators.getPlacesBySearch.REQUEST, getPlacesBySearch);
}

export default function* flow() {
  yield fork(watchDeleteMarker);
  yield fork(watchGetClusters);
  yield fork(watchGetRadius);
  yield fork(watchCreateMarker);
  yield fork(watchGetBestRides);
  yield fork(watchUpdateMarker);
  yield fork(watchGetMarkers);
  yield fork(watchGetPlacesBySearch);
}
